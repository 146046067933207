import {
  getRootFolder$,
  setRootFolder$,
} from '../Observables/RootFolder.observable';
import { setBreadCrumbs } from './BreadCrumbs.service';
import { authorizeActions } from './SelectedFolder.service';

export const getRootFolder = () => {
  return getRootFolder$();
};

export const setRootFolder = async (
  selectedFolderId: string,
  rootFolderName: string,
) => {
  if (selectedFolderId !== '') {
    try {
      let authorizedActions: any = [];
      const authorizeActionsResponse: any = await authorizeActions(
        { libraryItems: [{ id: selectedFolderId }] },
        undefined,
      );
      if (
        authorizeActionsResponse &&
        authorizeActionsResponse.libraryItems &&
        authorizeActionsResponse.libraryItems.length > 0
      ) {
        authorizedActions =
          authorizeActionsResponse.libraryItems[0].authorizeActions;
      }
      const pricingPlansIds: any[] = [];
      const noPlansAvailable: boolean = false;

      const tempFolderInfo = {
        id: selectedFolderId,
        value: rootFolderName,
        authorizeActions: authorizedActions,
        pricingPlansIds,
        noPlansAvailable,
      };
      setBreadCrumbs([tempFolderInfo]);
      setRootFolder$(tempFolderInfo);
    } catch (e) {
      const error: any = e;
      return error.message;
    }
  } else {
    setRootFolder$(undefined);
    setBreadCrumbs(undefined);
  }
};
