import {
  DELETE_GLITCH,
  DELETE_TYPE,
} from '../../Constants/DeleteToasts.Constants';
import { TOAST } from '../../Constants/Toast.Constants';
import { setToast } from '../Services/Toast.service';

export const sendNotification = (
  t: any,
  type: string,
  filesAmount: number,
  foldersAmount: number,
  fileName?: string,
  folderName?: string,
) => {
  let text = '';
  switch (type) {
    case DELETE_TYPE.SINGLE_FILE:
      text = t('app.widget.notification.delete.SINGLE_FILE_OR_FOLDER', {
        0: fileName,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case DELETE_TYPE.MULTIPLE_FILES:
      text = t('app.widget.notification.delete.MULTIPLE_FILES', {
        NumberofFiles: filesAmount,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case DELETE_TYPE.SINGLE_FOLDER:
      text = t('app.widget.notification.delete.SINGLE_FILE_OR_FOLDER', {
        0: folderName,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case DELETE_TYPE.MULTIPLE_FOLDERS:
      text = t('app.widget.notification.delete.MULTIPLE_FOLDERS', {
        NumberofFolders: foldersAmount,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case DELETE_TYPE.SINGLE_FILE_MULTIPLE_FOLDERS:
      text = t('app.widget.notification.delete.SINGLE_FILE_MULTIPLE_FOLDERS', {
        NumberofFolders: foldersAmount,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case DELETE_TYPE.SINGLE_FOLDER_MULTIPLE_FILES:
      text = t('app.widget.notification.delete.SINGLE_FOLDER_MULTIPLE_FILES', {
        NumberofFiles: filesAmount,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case DELETE_TYPE.SINGLE_FILE_SINGLE_FOLDER:
      text = t('app.widget.notification.delete.SINGLE_FILE_SINGLE_FOLDER');
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case DELETE_TYPE.MULTIPLE_FOLDERS_MULTIPLE_FILES:
      text = t(
        'app.widget.notification.delete.MULTIPLE_FOLDERS_MULTIPLE_FILES',
        {
          NumberofFolders: foldersAmount,
          NumberofFiles: filesAmount,
        },
      );
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;

    case DELETE_GLITCH.SINGLE_FOLDER_GLITCH:
      text = t('app.widget.notification.delete.SINGLE_FOLDER_GLITCH');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case DELETE_GLITCH.SINGLE_FILE_GLITCH:
      text = t('app.widget.notification.delete.SINGLE_FILE_GLITCH');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case DELETE_GLITCH.MULTIPLE_FOLDERS_GLITCH:
      text = t('app.widget.notification.delete.MULTIPLE_FOLDERS_GLITCH');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case DELETE_GLITCH.MULTIPLE_FILES_GLITCH:
      text = t('app.widget.notification.delete.MULTIPLE_FILES_GLITCH', {
        NumberofFolders: foldersAmount,
        NumberofFiles: filesAmount,
      });
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case DELETE_GLITCH.ITEMS_GLITCH:
      text = t('app.widget.notification.delete.ITEMS_GLITCH');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case DELETE_GLITCH.MULTI_GLITCH:
      text = t('app.widget.notification.delete.MULTI_GLITCH', {
        NumberofItems: foldersAmount + filesAmount,
      });
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;

    default:
      return;
  }
};
