import { FileSharingService } from '../Api/Api';
import {
  addMoreFolderItems$,
  getSelectedFolder$,
  getSelectedFolderId$,
  setSelectedFolder$,
  setSelectedFolderId$,
} from '../Observables/SelectedFolder.observable';
import { setIsLoading } from './Loading.service';
import { setIsServerError } from './ServerError.service';
import { getSortLastValue } from './Sort.service';
import { setSelectedItems } from './SelectedItems.service';
import { lastValueFrom, take } from 'rxjs';
import { QueryLibraryItemsResponse } from '@wix/ambassador-file-sharing-v1-library-item/types';
import { getAppSettings } from './AppSettings.service';
import { getBreadCrumbs } from './BreadCrumbs.service';
import { getCurrentTab } from './MemberTab.service';
import { MemberTab } from '../Observables/MemberTab.observable';

const API = new FileSharingService();

export const getSelectedFolderId = () => {
  return getSelectedFolderId$();
};
export const setSelectedFolderId = async (selectedFolderId: string) => {
  if (selectedFolderId && selectedFolderId !== '') {
    setSelectedFolderId$(selectedFolderId);
    addFolderView(selectedFolderId);
    setSelectedItems([]);
    if (selectedFolderId !== history.state?.itemId) {
      const appSettings = await lastValueFrom(
        getAppSettings().asObservable().pipe(take(1)),
      );
      if (!appSettings?.isMembersArea) {
        const navigation =
          '.' + appSettings?.appUrls?.pageUrl + '/' + selectedFolderId;
        const oldURL = new URL(window.location.href);
        const leftPart = oldURL.searchParams.size
          ? [navigation, oldURL.searchParams.toString()].join('?')
          : navigation;
        const newURL = new URL(leftPart, appSettings?.appUrls?.siteUrl + '/');
        const breadCrumbs = await lastValueFrom(
          getBreadCrumbs().asObservable().pipe(take(1)),
        );
        if (!appSettings?.isEditor) {
          history.pushState(
            { itemId: selectedFolderId, breadCrumbs: [...breadCrumbs] },
            '',
            newURL,
          );
        }
      }
    }
  } else {
    setSelectedFolderId$(undefined);
    setIsLoading(false);
  }
};

export const getSelectedFolder = () => {
  return getSelectedFolder$();
};

const getSelectedFolderData = async ({
  selectedFolder,
  idsToRemove,
  rootFolderId,
}: {
  selectedFolder?: string;
  idsToRemove?: any;
  rootFolderId?: any;
}) => {
  if (selectedFolder !== '' && selectedFolder !== undefined) {
    const sortBy = await getSortLastValue();
    const appSettings = await lastValueFrom(
      getAppSettings().asObservable().pipe(take(1)),
    );
    let viewedUserId = null;
    let additionalFilterParams;
    if (appSettings.isMembersArea) {
      viewedUserId = appSettings.viewedUserId;
      const currentTab = await lastValueFrom(
        getCurrentTab().asObservable().pipe(take(1)),
      );
      if (currentTab === MemberTab.Uploads) {
        additionalFilterParams = {
          createdBy: [viewedUserId],
          parentLibraryItemIds: [],
          libraryItemTypes: ['FILE'],
        };
      } else {
        additionalFilterParams = {
          filterFavoritedByCallingUser: true,
          parentLibraryItemIds: [],
        };
      }
    }
    try {
      setIsLoading(true);

      const folder = await API.getFolderById(
        selectedFolder,
        sortBy,
        additionalFilterParams,
      );
      if (!folder.data) {
        const tempError: any = folder;
        setIsLoading(false);
        setIsServerError({
          isError: true,
          message: tempError.message,
        });
      } else {
        setIsServerError({
          isError: false,
          message: '',
        });
        if (folder?.data?.libraryItems) {
          let folderData = folder.data;
          let parentFolder = selectedFolder;
          if (rootFolderId) {
            parentFolder = rootFolderId;
            folderData = { libraryItems: [{ id: selectedFolder }] };
          }
          const authorizedFolders = await authorizeActions(
            folderData,
            parentFolder,
          );
          if (
            authorizedFolders !== undefined &&
            authorizedFolders?.libraryItems &&
            authorizedFolders?.folderAuthorizedActions
          ) {
            const data = {
              libraryItems: authorizedFolders.libraryItems,
              folderAuthorizedActions:
                authorizedFolders.folderAuthorizedActions,

              metaData: folder.data.metaData,
            };
            if (idsToRemove) {
              setSelectedFolderFiltered(data, idsToRemove);
            } else {
              setSelectedFolder$(data);
            }
          }
          setIsLoading(false);
        } else {
          if (idsToRemove) {
            setSelectedFolderFiltered(folder.data, idsToRemove);
          } else {
            setSelectedFolder$(folder.data);
          }
          setIsLoading(false);
        }
      }
    } catch (e) {
      const error: any = e;
      setIsServerError({
        isError: true,
        message: error.message,
      });
      return error.message;
    }
  } else {
    setIsServerError({
      isError: false,
      message: '',
    });
    setSelectedFolder$(undefined);
    setIsLoading(false);
  }
};

export const setSelectedFolder = async (
  selectedFolder: string | undefined,
  idsToRemove?: any,
  rootFolderId?: string,
) => {
  return getSelectedFolderData({ selectedFolder, idsToRemove, rootFolderId });
};

export const loadMoreFolderItems = async (
  folderId: string,
  nextCursor: string,
) => {
  try {
    const response = await API.loadMoreItemsApi(folderId, nextCursor);
    if (!response.data) {
      const tempError: any = response;
      setIsServerError({
        isError: true,
        message: tempError.message,
      });
    } else {
      setIsServerError({
        isError: false,
        message: '',
      });
      if (response.data.libraryItems && response.data.libraryItems.length > 0) {
        const authorizedFolders = await authorizeActions(
          response.data,
          response.data.libraryItems[0].parentFolderId,
        );
        if (authorizedFolders) {
          const data = {
            libraryItems: authorizedFolders.libraryItems,
            folderAuthorizedActions: authorizedFolders.folderAuthorizedActions,
            metaData: response.data.metaData,
          };
          addMoreFolderItems$(data);
        }

        setIsLoading(false);
      } else {
        addMoreFolderItems$(response.data);
      }
    }
  } catch (e) {
    setIsLoading(false);
    const error: any = e;
    setIsServerError({
      isError: true,
      message: error.message,
    });
    return error.message;
  }
};

export const addFolderView = async (id: string) => {
  try {
    await API.addViewFolderAPI({
      actions: [
        {
          libraryItemId: id,
        },
      ],
    });
  } catch (e) {
    const error: any = e;
    throw error;
  }
};

export const authorizeActions = async (folders: any, parentFolderId: any) => {
  const libraryItemIds = folders.libraryItems.map((item: any) => item.id);
  const query = { libraryItemIds, parentFolderId };
  const res = await API.authorizeActions(query);
  if (!res.data) {
    const tempError: any = res;
    setIsLoading(false);
    setIsServerError({
      isError: true,
      message: tempError.message,
    });
    return;
  }
  const authorizedLibraryItems = folders.libraryItems.map((folder: any) => {
    if (res?.data?.authorizedActions) {
      folder.authorizeActions = res.data.authorizedActions.filter(
        (data: any) => {
          return data.itemId === folder.id;
        },
      );
    }
    return folder;
  });

  return {
    libraryItems: authorizedLibraryItems,
    folderAuthorizedActions: res?.data?.folderAuthorizedActions || {},
  };
};

export const viewFileService = async (id: string) => {
  try {
    const response = await API.viewFileApi({
      actions: [
        {
          libraryItemId: id,
        },
      ],
    });
    if (response && response?.data && response.data?.urls) {
      const urls = response.data.urls;
      if (urls.length > 0) {
        const url = urls[0]?.url;
        const libraryItemId = urls[0]?.libraryItemId;
        if (url) {
          setTimeout(() => window.open(urls[0].url));
        }
        if (libraryItemId) {
          const tempResult = getSelectedFolder$().asObservable();
          const lastTempResult = tempResult.pipe(take(1));
          const previousResult = await lastValueFrom(lastTempResult);
          const newResult = JSON.parse(JSON.stringify(previousResult));
          newResult.libraryItems.forEach((item: any) => {
            if (item.id === libraryItemId && !item.isViewed) {
              item.isViewed = true;
              item.uniqueViews += 1;
            }
          });
          setSelectedFolder$(newResult);
        }
      }
      setIsLoading(false);
    }
  } catch (e) {
    setIsLoading(false);
    const error: any = e;
    throw error;
  }
};
const setSelectedFolderFiltered = async (data: any, idsToRemove: any) => {
  const newData: any = { ...data };
  const filteredItems = data?.libraryItems?.filter(
    (item: any) => !idsToRemove?.includes(item?.id),
  );
  newData.libraryItems = filteredItems;
  setSelectedItems([]);
  setSelectedFolder$(newData);
  setIsLoading(false);
};

export const getLibraryDataById = async (
  libraryItemId: string,
): Promise<QueryLibraryItemsResponse> => {
  return API.getFolderInfoById(libraryItemId);
};
