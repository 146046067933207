import { BehaviorSubject } from 'rxjs';

export const UserSubject$ = new BehaviorSubject(undefined as unknown as any);
export const RequestLogin$ = new BehaviorSubject(undefined as unknown as any);
export const navigateToPricingPlans$ = new BehaviorSubject(
  undefined as unknown as any,
);
export const navigateToLibrary$ = new BehaviorSubject(undefined);

export const getUser$ = () => UserSubject$;

export const setUser$ = (User: any) => UserSubject$.next(User);

export const getRequestLogin$ = () => RequestLogin$;

export const setRequestLogin$ = (requestLogin: any) =>
  RequestLogin$.next(requestLogin);

export const getNavigateToPricingPlans$ = () => navigateToPricingPlans$;

export const setNavigateToPricingPlans$ = (navigateToPricingPlans: any) =>
  navigateToPricingPlans$.next(navigateToPricingPlans);

export const getNavigateToLibrary$ = () => navigateToLibrary$;

export const setNavigateToLibrary$ = (navigateToLibrary: any) =>
  navigateToLibrary$.next(navigateToLibrary);
