import { TFunction } from '@wix/yoshi-flow-editor';

export const getLabels = (t: TFunction) => {
  return {
    title: t('widget.permissions.modal.title'),
    secondTitle: t('widget.permissions.modal.second.title'),
    secondSubtitle: t('widget.permissions.modal.second.subtitle'),
    cancel: t('cancel'),
    save: t('widget.permissions.modal.button.save'),
    add: t('add'),
    allOptionsSelected: t(
      'widget.permissions.modal.all_options_selected.message',
    ),
    searchPlaceHolder: t('widget.permissions.modal.search_place_holder'),
    noOptionsMessage: t('widget.permissions.modal.no_options_message'),
    backButtonLabel: t('widget.permissions.modal.button.back'),
    checkBoxNotifyLabel: t('widget.permissions.modal.checkBox.notify.label'),
    notifyMessagePlaceholder: t(
      'widget.permissions.modal.notify.message.placeholder',
    ),
    notifyLabelHint: t('widget.permissions.modal.checkBox.notify.label.hint'),
    closeAriaLabel: t('widget.permissions.modal.button.close'),
    backAriaLabel: t('widget.permissions.modal.button.back'),
    loadingMessage: t('widget.permissions.modal.loading'),
    emptyStateTitle: t('widget.permissions.modal.empty.state.title'),
    emptyStateDescription: t(
      'widget.permissions.modal.empty.state.description',
    ),
    buttonAddNotify: t('widget.permissions.modal.button.add_notify'),
    step1SaveError: t('widget.permissions.modal.error.saving.firstView'),
    step2SaveError: t('widget.permissions.modal.error.saving.secondView'),
    rolesListError: t('widget.permissions.modal.error.rolesNotLoaded'),
    numberOfResultsMessage: t('widget.permissions.modal.num_results'),
    pressDeleteToRemoveMessage: t('widget.permissions.modal.press_delete'),
    notifyMessageLabel: t('widget.permissions.modal.notify.message.label'),
    saveSuccessMessage: t('widget.permissions.modal.button.success.message'),
    selectPermissionLabel: t('widget.permissions.modal.selectpermission.label'),
    permissionRemovedMessage: t('widget.permissions.modal.remove.success'),
    doneLabel: t('widget.permissions.modal.button.done'),
    checkboxLabel_hiddenFolders: t(
      'widget.permissions.modal.hideFolder.checkbox.label',
    ),
    learnMore_hiddenFolders: t(
      'widget.permissions.modal.hideFolder.learn.more',
    ),
    hideFolderAndSiteVisitorErrorMessage: t(
      'widget.permissions.modal.hideFolder.siteVisitors.error.message',
    ),
  };
};
