import React from 'react';
import { Divider, Spinner, Text } from 'wix-ui-tpa';
import { st, classes } from './SkeletonLoader.st.css';
import {
  st as stSharedDivider,
  classes as sharedDividerClasses,
} from '../../../../../../Shared/SharedDivider.st.css';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import {
  getAppSettings,
  getSettingsData,
} from '../../../../../../Services/AppSettings.service';

export const SkeletonLoader = ({ folderName }: any) => {
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const dividerWidth =
    settingsData?.settings?.designSettings?.dividerWidth ?? 1;
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  return (
    <>
      {isMobile !== undefined && !isMobile && (
        <Divider
          className={stSharedDivider(
            sharedDividerClasses.dividerStyle,
            {
              isEditorX,
              isMobile,
            },
            sharedDividerClasses[`dividerStyle${dividerWidth}`],
          )}
        />
      )}
      <div
        className={st(
          classes.root,
          {},
          classes.skeletonLoaderContainer +
            ' ' +
            (isMobile ? classes.skeletonLoaderContainerMobile : ''),
        )}
      >
        <Spinner className={classes.spinner} diameter={30} />
        <Text className={st(classes.textName, { isMobile })}>{folderName}</Text>
      </div>
      {isMobile !== undefined && isMobile && (
        <Divider
          className={stSharedDivider(
            sharedDividerClasses.dividerStyle,
            {
              isEditorX,
              isMobile,
            },
            sharedDividerClasses[`dividerStyle${dividerWidth}`],
          )}
        />
      )}
    </>
  );
};
