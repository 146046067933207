import {
  getRequestLogin$,
  getUser$,
  setRequestLogin$,
  setUser$,
  setNavigateToPricingPlans$,
  getNavigateToPricingPlans$,
  getNavigateToLibrary$,
  setNavigateToLibrary$,
} from '../Observables/User.observable';

export const setUser = (User: any) => {
  setUser$(User);
};

export const getUser = () => {
  return getUser$();
};

export const getRequestLogin = () => getRequestLogin$();

export const setRequestLogin = (requestLogin: any) =>
  setRequestLogin$(requestLogin);

export const getNavigateToPricingPlans = () => getNavigateToPricingPlans$();

export const setNavigateToPricingPlans = (navigateToPricingPlans: any) =>
  setNavigateToPricingPlans$(navigateToPricingPlans);

export const getNavigateToLibrary = () => getNavigateToLibrary$();

export const setNavigateToLibrary = (navigateToLibrary: any) =>
  setNavigateToLibrary$(navigateToLibrary);
