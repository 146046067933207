import {
  UPLOAD_SUCCESS,
  UPLOAD_GLITCH,
} from '../../../Constants/UploadTosts.Constants';
import { FileSharingService } from '../../Api/Api';
import {
  addFilesToSelectedFolder,
  setUploadingFileStatus,
} from './Upload.service';
import { UploadGlitchErrorNotifications } from './UploadGlitchErrorNotifications';
import { UploadSuccessNotification } from './UploadSuccessNotifications';
import biService from '../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED } from '../../BiEvents/Constants/BiConstants';
import { authorizeActions } from '../SelectedFolder.service';

let API: any = null;
API = new FileSharingService();

export const completeFileUpload = async (
  t: any,
  listOfCompletion: any,
  success: any,
) => {
  try {
    const response = await API.completeFileUpload({
      actions: listOfCompletion,
    });
    if (
      response?.data &&
      response.data.libraryItems &&
      response.data.libraryItems.length > 0
    ) {
      const authorizedFolders = await authorizeActions(
        response.data,
        response.data.libraryItems[0].parentFolderId,
      );
      if (authorizedFolders !== undefined && authorizedFolders?.libraryItems) {
        addFilesToSelectedFolder(authorizedFolders.libraryItems);
        response?.data?.libraryItems?.map((item: any) => {
          biService(
            {
              description: 'create',
              file_id: item.id,
              file_name: item.name,
              file_owner: item.createdByName,
              file_size: item?.fileFields?.size,
              file_type: item?.fileFields?.extension,
              is_camera: false,
              is_first: false,
              level: 1,
              parent_folder_id: item.parentFolderId,
            },
            BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED,
          );
        });
        const type =
          response?.data?.libraryItems.length === 1
            ? UPLOAD_SUCCESS.FILEUPLOAD_SINGLE_SUCCESS
            : UPLOAD_SUCCESS.FILEUPLOAD_MULTIPLE_SUCCESS;
        UploadSuccessNotification(
          t,
          type,
          success.length,
          response?.data?.libraryItems[0].name,
          response?.data?.libraryItems.length,
        );
        setUploadingFileStatus({
          status: 'CLOSED',
          libraryItems: [],
        });
      }
    } else {
      UploadGlitchErrorNotifications(
        t,
        UPLOAD_GLITCH.FILEUPLOAD_GENERAL_GLITCH,
        response?.libraryItems?.length,
      );
      setUploadingFileStatus({
        status: 'CLOSED',
        libraryItems: [],
      });
    }
  } catch (e) {
    UploadGlitchErrorNotifications(
      t,
      UPLOAD_GLITCH.FILEUPLOAD_GENERAL_GLITCH,
      success.length,
    );
    setUploadingFileStatus({
      status: 'CLOSED',
      libraryItems: [],
    });
  }
};
