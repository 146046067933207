import { Availability } from '@wix/ambassador-file-sharing-v1-role/types';
import { FileSharingService } from '../Api/Api';
import {
  getMenuSelectedItem$,
  setMenuSelectedItem$,
} from '../Observables/MenuSelectedItem';

const API = new FileSharingService();

export const setMenuSelectedItem = async (currentSelectedItem: any) => {
  let pricingPlansIds: any[] = [];
  const { availability = Availability.PUBLIC, id } = currentSelectedItem;
  if (availability === Availability.PAID) {
    const listRoles = await API.getListRoles({
      ofCurrentUser: false,
      libraryItemId: id,
      siteVisitors: false,
    });

    if (listRoles?.data && listRoles.data?.roles) {
      pricingPlansIds = listRoles.data.roles
        .filter(
          (role: any) =>
            role.roleType === 'PLAN' &&
            role.permissions &&
            role.permissions?.length,
        )
        .map((role: any) => role.id);
    }
  }

  setMenuSelectedItem$({ ...currentSelectedItem, pricingPlansIds });
};

export const getMenuSelectedItem = () => {
  return getMenuSelectedItem$();
};
