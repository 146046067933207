import { TOAST } from '../../../Constants/Toast.Constants';
import { UPLOAD_SUCCESS } from '../../../Constants/UploadTosts.Constants';
import { setToast } from '../Toast.service';

export const UploadSuccessNotification = (
  t: any,
  type: string,
  filesAmount: number,
  fileName: string,
  filesSucceeded: string,
) => {
  let text = '';
  switch (type) {
    case UPLOAD_SUCCESS.FILEUPLOAD_SINGLE_SUCCESS:
      text = t('app.widget.notification.FILEUPLOAD_SINGLE_SUCCESS', {
        fileName,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    case UPLOAD_SUCCESS.FILEUPLOAD_MULTIPLE_SUCCESS:
      text = t('app.widget.notification.FILEUPLOAD_MULTIPLE_SUCCESS', {
        SucceededFiles: filesSucceeded,
        totalFilesAmount: filesAmount,
      });
      setToast({
        skin: TOAST.success,
        content: text,
      });
      break;
    default:
      return;
  }
};
