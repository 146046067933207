import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { isDownload } from '../Services/ItemsDownload.service';

export const selectedItemsSubject$ = new BehaviorSubject(
  // [
  // 'a',
  // 'b',
  // 'c',
  // 'd',
  // 'e',
  // ]
  [],
);

export const getSelectedItems$ = () => selectedItemsSubject$;

export const setSelectedItems$ = async (selectedItems: any) => {
  selectedItemsSubject$.next(selectedItems);
  await isDownload();
};

export const removeItemFromSelectedItems$ = async (id: string) => {
  const tempResult = selectedItemsSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = previousResult.filter((item: any) => item !== id);
  selectedItemsSubject$.next(newResult);
  await isDownload();
};

export const addItemToSelectedItems$ = async (id: string) => {
  const tempResult = selectedItemsSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  let newResult: any = [...previousResult];
  newResult = [...newResult, id];
  selectedItemsSubject$.next(newResult);
  await isDownload();
};
