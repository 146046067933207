import { fileSharePermissionsSavedStep1 } from '@wix/bi-logger-wixlabs-ugc/v2';
import { fileSharePermissionsSavedStep1Params } from '@wix/bi-logger-wixlabs-ugc/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsSaveStep1 = (
  props: PermissionType<fileSharePermissionsSavedStep1Params>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsSavedStep1({ ...params }));
};
