export const demoFile = {
  id: -1,
  name: '',
  uniqueViews: 0,
  timesFavorited: 0,
  createdByProfile: {
    imageUrl: '',
  },
  fileFields: {
    category: '',
    extension: '',
    previewable: true,
  },
  createdAt: {},
  parentFolderId: '',
};
