import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

export const breadCrumbsSubject$ = new BehaviorSubject(
  (undefined as unknown) as any,
);

// export const addToBreadCrumbs$ = async (breadCrumb: any) => {
//   const tempResult = breadCrumbsSubject$.asObservable();
//   const lastTempResult = tempResult.pipe(take(1));
//   const previousResult = await lastValueFrom(lastTempResult);
//   let newResult = [...previousResult];
//   newResult = newResult.filter(x => x.id !== breadCrumb.id);
//   breadCrumbsSubject$.next(newResult);
// };

export const addToBreadCrumbs$ = async (breadCrumb: any) => {
  const tempResult = breadCrumbsSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = [...previousResult];
  newResult.push(breadCrumb);
  breadCrumbsSubject$.next(newResult);
  return newResult;
};

export const getBreadCrumbs$ = () => breadCrumbsSubject$;

export const setBreadCrumbs$ = (rootBreadCrumb: any) =>
  breadCrumbsSubject$.next(rootBreadCrumb);
