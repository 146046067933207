import React from 'react';
import { classes } from './iconStyle.st.css';

export const PptTag = (props: any) => {
  const { isMobile, fileIconsColoredByType } = props;
  const className = fileIconsColoredByType ? '' : classes._fileIconColorLabeled;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 22H4.5C4.225 22 4 21.775 4 21.5V2.5C4 2.225 4.225 2 4.5 2H12V6.5C12 7.327 12.673 8 13.5 8H18V21.5C18 21.775 17.775 22 17.5 22ZM13 2.706L17.294 7H13.5C13.225 7 13 6.775 13 6.5V2.706ZM18.561 6.853L13.147 1.439C12.868 1.16 12.481 1 12.087 1H4.5C3.673 1 3 1.673 3 2.5V21.5C3 22.327 3.673 23 4.5 23H17.5C18.327 23 19 22.327 19 21.5V7.913C19 7.513 18.844 7.136 18.561 6.853Z"
        fill="black"
        className={classes._fileIconColor2Labeled}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 19H21V11H7V19Z"
        fill="#FE6C02"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5752 13.7772H9.92018V14.7712H10.5752C10.7252 14.7712 10.8452 14.7262 10.9332 14.6362C11.0212 14.5452 11.0662 14.4252 11.0662 14.2752C11.0662 14.1212 11.0212 13.9982 10.9332 13.9102C10.8452 13.8222 10.7252 13.7772 10.5752 13.7772ZM9.92018 17.0192H8.98218V12.9802H10.5752C10.8572 12.9802 11.1062 13.0352 11.3232 13.1442C11.5402 13.2542 11.7082 13.4062 11.8282 13.5992C11.9492 13.7932 12.0102 14.0192 12.0102 14.2752C12.0102 14.5312 11.9492 14.7562 11.8282 14.9532C11.7082 15.1482 11.5402 15.2982 11.3232 15.4062C11.1062 15.5132 10.8572 15.5682 10.5752 15.5682H9.92018V17.0192ZM14.2734 13.7772H13.6184V14.7712H14.2734C14.4234 14.7712 14.5444 14.7262 14.6314 14.6362C14.7204 14.5452 14.7644 14.4252 14.7644 14.2752C14.7644 14.1212 14.7204 13.9982 14.6314 13.9102C14.5444 13.8222 14.4234 13.7772 14.2734 13.7772ZM13.6184 17.0192H12.6814V12.9802H14.2734C14.5554 12.9802 14.8054 13.0352 15.0214 13.1442C15.2384 13.2542 15.4074 13.4062 15.5274 13.5992C15.6474 13.7932 15.7084 14.0192 15.7084 14.2752C15.7084 14.5312 15.6474 14.7562 15.5274 14.9532C15.4074 15.1482 15.2384 15.2982 15.0214 15.4062C14.8054 15.5132 14.5554 15.5682 14.2734 15.5682H13.6184V17.0192ZM18.1641 17.0194H17.2321V13.7894H16.0981V12.9804H19.3001V13.7894H18.1641V17.0194Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 41H2.5C2.225 41 2 40.775 2 40.5V3.5C2 3.225 2.225 3 2.5 3H20V11.5C20 12.327 20.673 13 21.5 13H30V40.5C30 40.775 29.775 41 29.5 41ZM21 3.708L29.293 12H21.5C21.225 12 21 11.775 21 11.5V3.708ZM30.561 11.854L21.146 2.439C20.862 2.156 20.485 2 20.085 2H2.5C1.673 2 1 2.673 1 3.5V40.5C1 41.327 1.673 42 2.5 42H29.5C30.327 42 31 41.327 31 40.5V12.914C31 12.52 30.84 12.133 30.561 11.854Z"
        fill="black"
        className={classes._fileIconColor2Labeled}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99902 35H35.999V18H6.99902V35Z"
        fill="#FE6C02"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0869 23.5459H13.2829V26.1759H15.0869C15.5199 26.1759 15.8599 26.0589 16.1089 25.8229C16.3589 25.5889 16.4829 25.2669 16.4829 24.8559C16.4829 24.4439 16.3589 24.1239 16.1089 23.8929C15.8599 23.6619 15.5199 23.5459 15.0869 23.5459ZM13.2829 30.4329H12.1489V22.5669H15.0869C15.5929 22.5669 16.0359 22.6629 16.4179 22.8539C16.7989 23.0439 17.0959 23.3099 17.3089 23.6499C17.5219 23.9919 17.6279 24.3939 17.6279 24.8559C17.6279 25.3109 17.5219 25.7119 17.3089 26.0599C17.0959 26.4079 16.7989 26.6779 16.4179 26.8679C16.0359 27.0599 15.5929 27.1539 15.0869 27.1539H13.2829V30.4329ZM22.1924 23.5459H20.3884V26.1759H22.1924C22.6254 26.1759 22.9664 26.0589 23.2154 25.8229C23.4644 25.5889 23.5894 25.2669 23.5894 24.8559C23.5894 24.4439 23.4644 24.1239 23.2154 23.8929C22.9664 23.6619 22.6254 23.5459 22.1924 23.5459ZM20.3884 30.4329H19.2554V22.5669H22.1924C22.6994 22.5669 23.1424 22.6629 23.5234 22.8539C23.9054 23.0439 24.2024 23.3099 24.4154 23.6499C24.6274 23.9919 24.7334 24.3939 24.7334 24.8559C24.7334 25.3109 24.6274 25.7119 24.4154 26.0599C24.2024 26.4079 23.9054 26.6779 23.5234 26.8679C23.1424 27.0599 22.6994 27.1539 22.1924 27.1539H20.3884V30.4329ZM29.1777 30.4326H28.0447V23.5576H25.6687V22.5676H31.5537V23.5576H29.1777V30.4326Z"
        fill="white"
      />
    </svg>
  );
};
