import React, { useEffect } from 'react';
import { Availability, Reason, Role } from '../../../../Constants/Permissions';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getUser } from '../../../Services/User.service';
import { requestAccessService } from '../../../Services/AccessModals.service';
import { useTranslation } from '@wix/yoshi-flow-editor';
import withMemberAuthorization from '../../../../Shared/HOC/withMemberAuthorization';
import EmptyStates from '../EmptyStates';
import { getMenuSelectedItem } from '../../../Services/MenuSelectedItem';
import biService from '../../../BiEvents/BiService';
import {
  BI_FILE_SHARE_PERMISSIONS_ACCESS_WINDOW_OPENED,
  BI_FILE_SHARE_PERMISSIONS_GET_ACCESS_CLICK,
} from '../../../BiEvents/Constants/BiConstants';

const AccessModalsWrapper = ({
  viewPermissions,
  availability,
  memberAuthorization,
  navigateToPricingPlansApp,
  folderName,
  handleOnClose = () => {},
  showInModal = false,
}: any) => {
  const { t } = useTranslation();
  const [, currentItem]: any = useObservable(getMenuSelectedItem());
  const [, user]: any = useObservable(getUser());

  useEffect(() => {
    if (currentItem !== undefined && user !== undefined) {
      if (viewPermissions?.reason === Reason.MISSING_PERMISSION) {
        const params = {
          is_folder: true,
          file_id: currentItem.id,
          type: availability === Availability.PAID ? 'dollar' : 'lock',
          owner: user.role,
        };
        biService(params, BI_FILE_SHARE_PERMISSIONS_ACCESS_WINDOW_OPENED);
      }
    }
  }, [availability, viewPermissions?.reason, currentItem, user]);

  const selectedFolderId = currentItem?.id;

  const triggerBiPermissionsAccessClicked = (actionClicked: string) => {
    const params = {
      type: availability === Availability.PAID ? 'dollar' : 'lock',
      is_folder: true,
      file_id: currentItem.id,
      button_description: actionClicked,
      owner: user.role,
    };
    biService(params, BI_FILE_SHARE_PERMISSIONS_GET_ACCESS_CLICK);
  };
  const openLoginModal = () => {
    const isUserLoggedIn = user && user?.isLoggedIn;

    memberAuthorization &&
      memberAuthorization({
        args: [],
        callBack: () => {},
        isAccessModal: true,
      });
    triggerBiPermissionsAccessClicked(
      isUserLoggedIn ? 'switch_account' : 'login',
    );
    handleOnClose();
  };

  const requestAccessFn = () => {
    if (selectedFolderId) {
      requestAccessService(selectedFolderId, t);
      triggerBiPermissionsAccessClicked('send_request');
    }
  };
  const choosePlanFn = async () => {
    if (navigateToPricingPlansApp) {
      triggerBiPermissionsAccessClicked('see_plans');
      await navigateToPricingPlansApp();
    }
  };

  const renderAccessModals = () => {
    const isUserLoggedIn = user && user?.isLoggedIn;
    const isAdmin = user && user?.role === Role.ADMIN;
    let title = '';
    let subTitle = '';
    let primaryText = '';
    let secondaryText = '';
    let primaryButtonOnClick = () => {};
    let secondaryButtonOnClick = () => {};

    switch (viewPermissions?.reason) {
      case Reason.MISSING_PERMISSION: {
        if (availability !== undefined) {
          if (availability === Availability.PAID) {
            if (currentItem !== undefined && currentItem?.noPlansAvailable) {
              title = t(
                'app.widget.pricingPlansEmptyState.NoPlansAvailable.title',
              );
              subTitle = t(
                'app.widget.pricingPlansEmptyState.NoPlansAvailable.subTitle',
              );
              secondaryText = t(
                'app.widget.pricingPlansEmptyState.NoPlansAvailable.primaryButtonText',
              );
            } else {
              title = t('app.widget.accessModals.paid.title');
              subTitle = isUserLoggedIn
                ? t('app.widget.accessModals.paid.member.description')
                : t('app.widget.accessModals.paid.description');
              primaryText = isUserLoggedIn
                ? t('app.widget.accessModals.paid.SwitchAccount.button')
                : t('app.widget.accessModals.paid.login.button');
              secondaryText = t(
                'app.widget.accessModals.paid.ChoosePlan.button',
              );
              primaryButtonOnClick = openLoginModal;
              secondaryButtonOnClick = choosePlanFn;
            }
          } else {
            title = isUserLoggedIn
              ? t('app.widget.accessModals.private.title')
              : t('app.widget.accessModals.private.title.notLogin');
            subTitle = isUserLoggedIn
              ? t('app.widget.accessModals.private.description', { folderName })
              : t('app.widget.accessModals.private.description.notLogin', {
                  folderName,
                });
            primaryText = isUserLoggedIn
              ? t('app.widget.accessModals.private.switchAccount.button')
              : '';
            secondaryText = isUserLoggedIn
              ? t('app.widget.accessModals.private.sendRequest.button')
              : t('app.widget.accessModals.private.login.button');
            primaryButtonOnClick = openLoginModal;
            secondaryButtonOnClick = isUserLoggedIn
              ? requestAccessFn
              : openLoginModal;
          }
        }
        break;
      }
      case Reason.NOT_FOUND:
        title = t('app.widget.emptyState.item.not.found.title');
        subTitle = t('app.widget.emptyState.item.not.found.subTitle');
        break;

      // TODO: not working when site vistors can view, and logged out
      case Reason.MUST_BE_A_MEMBER: {
        secondaryButtonOnClick = openLoginModal;
        title = t('app.widget.accessModals.private.title.notLogin');
        subTitle = t('app.widget.accessModals.private.description.notLogin', {
          folderName,
        });
        primaryText = '';
        secondaryText = t('app.widget.accessModals.private.login.button');
        break;
      }
      default:
        break;
    }
    return (
      <EmptyStates
        showInModal={showInModal}
        title={title}
        subTitle={subTitle}
        primaryText={primaryText}
        secondaryText={secondaryText}
        primaryButtonOnClick={primaryButtonOnClick}
        secondaryButtonOnClick={secondaryButtonOnClick}
      />
    );
  };
  return <div>{renderAccessModals()}</div>;
};
export default withMemberAuthorization(AccessModalsWrapper);
