import React, { useEffect, useMemo, useState } from 'react';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import {
  getIsLoadingSubjects,
  getIsSiteRolesRetrieved,
  getMembers,
  getRoles,
  getSiteRoles,
  resetRolesData,
  setRoles,
  setSavePermissionsLevel,
  setSiteRoles,
  updateAvailabilityAfterSavePermissions,
} from '../../../Services/advancedPermissions/advancedPermissions.service';
import {
  getMappedMembers,
  getMappedRoles,
  getMappedSiteRoles,
  getPermissionBiStep1Params,
  getPermissionBiStep2Params,
  MEMBERS_PAGE_LIMIT,
  permissions,
} from './advancedPermissions.helper';
import {
  CloseArgument,
  CloseReason,
  EntityAdvancedPermissions,
  Loading,
  ResponseStatus,
  SaveData,
  ViewRowProps,
} from '@wix/entity-advanced-permissions';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import {
  PermissionsLevelEnumPermissionsLevel,
  RoleType,
} from '@wix/ambassador-file-sharing-v1-role/types';
import {
  AdvancedPermissionsProps,
  GetPermissionBiStep1ParamsProps,
} from './advancedPermissions.types';
import { getAppSettings } from '../../../Services/AppSettings.service';
import { setToast } from '../../../Services/Toast.service';
import { TOAST } from '../../../../Constants/Toast.Constants';
import { getLabels } from './advancedPrmissions.labels';
import biService from '../../../BiEvents/BiService';
import {
  BI_FILE_SHARE_PERMISSIONS_SAVE_STEP1,
  BI_FILE_SHARE_PERMISSIONS_SAVE_STEP2,
  BI_FILE_SHARE_PERMISSIONS_SIGN_ADDED,
  BI_FILE_SHARE_PERMISSIONS_SIGN_REMOVED,
} from '../../../BiEvents/Constants/BiConstants';
import { getUser } from '../../../Services/User.service';
import { ADMIN } from '../../../../Constants/Modals.Constants';
import { Availability } from '../../../../Constants/Permissions';

export const AdvancedPermissions = (props: AdvancedPermissionsProps) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { currentItem, handleOnClose } = props;
  const [, roles] = useObservable(getRoles());
  const [, siteRoles] = useObservable(getSiteRoles());
  const [, isSiteRolesRetrieved] = useObservable(getIsSiteRolesRetrieved());
  const [, isLoading] = useObservable(getIsLoadingSubjects());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, user]: any = useObservable(getUser());
  const isAdmin: boolean = user?.role === ADMIN;
  const isMobile = appSettings?.isMobile ?? false;
  const folderNameTitle = t('widget.permissions.modal.title.folderName', {
    '0': currentItem.name,
  });
  const [showHiddenFolder_app, setShowHiddenFolder_app] = useState(false);

  useEffect(() => {
    experiments.load('labs-file-share').then(() => {
      setShowHiddenFolder_app(
        experiments.enabled('specs.wixlabs.hiddenFoldersFileShareApp'),
      );
    });
  }, [experiments]);

  useEffect(() => {
    if (currentItem.id) {
      setRoles(currentItem.id);
    }
    return () => {
      resetRolesData();
    };
  }, [currentItem.id]);

  const queryFunction = async (
    search: string,
    currentCursor: string | null,
    memberIds: string,
  ) => {
    try {
      const nextCursor = {
        limit: MEMBERS_PAGE_LIMIT,
        offset: 0,
      };
      const tmpCursor = currentCursor && JSON.parse(currentCursor);
      if (tmpCursor && tmpCursor.total > tmpCursor.count + tmpCursor.offset) {
        nextCursor.offset = tmpCursor.offset + tmpCursor.count;
      }
      const currentSiteRoles = await getSiteRolesData();
      const members = await getMembers({
        search,
        nextCursor,
        memberIds,
      });
      const searchedMembers = getMappedMembers(members, t) || [];
      const lcSearch = `${search}`.toLowerCase();
      const searchedRoles =
        getMappedSiteRoles(currentSiteRoles, lcSearch, t) || [];
      const metaData: any = members.metaData;
      const isMembersList = memberIds && memberIds.length;
      const isFullList = metaData.offset === 0 && !isMembersList;
      const fullList = isFullList
        ? searchedRoles.concat(searchedMembers)
        : searchedMembers;
      const totalRecs = metaData.total || 0;
      const currentMetadata =
        totalRecs > metaData.count + metaData.offset ? metaData : null;
      return { items: fullList, nextCursor: currentMetadata };
    } catch (e) {
      console.error('error in queryMembers', e);
      return {};
    }
  };

  const filteredRoles = useMemo(() => {
    if (roles) {
      return getMappedRoles(roles, t);
    }
  }, [roles]);

  const getSiteRolesData = async () => {
    let currentSiteRoles;
    if (isSiteRolesRetrieved) {
      currentSiteRoles = siteRoles;
    } else {
      currentSiteRoles = await setSiteRoles();
    }
    return currentSiteRoles;
  };

  const triggerPermissionBiStep1 = async (saveData: SaveData) => {
    const currentSiteRoles = await getSiteRolesData();
    const params: GetPermissionBiStep1ParamsProps = {
      folderId: currentItem.id,
      siteRoles: currentSiteRoles?.roles,
      roles: filteredRoles ?? [],
      rolesChanged: saveData.rolesChanged,
      isAdmin,
      modalAlertVisible: saveData.modalAlertVisible,
      isHidden: saveData.isHidden,
      availability: currentItem.availability,
    };
    const eventParams = getPermissionBiStep1Params(params);
    biService(eventParams, BI_FILE_SHARE_PERMISSIONS_SAVE_STEP1);
  };

  const triggerPermissionBiStep2 = async (saveData: SaveData) => {
    const eventParams = getPermissionBiStep2Params(saveData, currentItem.id);
    biService(eventParams, BI_FILE_SHARE_PERMISSIONS_SAVE_STEP2);
  };

  const triggerAvailabilityChange = async (
    oldAvailability: Availability,
    newAvailability: string | undefined,
  ) => {
    let biAvailability = '';
    let isRemovedBi = false;
    if (newAvailability !== undefined) {
      if (oldAvailability !== newAvailability) {
        if (newAvailability === Availability.PUBLIC) {
          biAvailability = oldAvailability;
          isRemovedBi = true;
        } else if (oldAvailability === Availability.PUBLIC) {
          biAvailability = newAvailability;
          isRemovedBi = false;
        }
      }
      const eventParams = {
        isFolder: true,
        type: biAvailability,
        file_id: currentItem.id,
      };
      biService(
        eventParams,
        isRemovedBi
          ? BI_FILE_SHARE_PERMISSIONS_SIGN_REMOVED
          : BI_FILE_SHARE_PERMISSIONS_SIGN_ADDED,
      );
    }
  };

  const saveClicked = async (saveData: SaveData) => {
    const saveResponse = await setSavePermissionsLevel(saveData);
    const { availabilityStatus } = saveResponse;
    if (saveResponse.responseStatus === ResponseStatus.Success) {
      if (saveData.notify !== undefined) {
        triggerPermissionBiStep2(saveData);
      } else {
        triggerPermissionBiStep1(saveData);
      }
      updateAvailabilityAfterSavePermissions(
        currentItem.id,
        availabilityStatus,
      );
      triggerAvailabilityChange(
        currentItem.availability,
        availabilityStatus?.availability,
      );
    }
    return saveResponse;
  };

  const onClose = (closeArgument: CloseArgument) => {
    if (closeArgument.closeReason === CloseReason.Cancel) {
      handleOnClose();
    } else if (closeArgument.closeReason === CloseReason.SaveSucceed) {
      handleOnClose();
      setToast({
        skin: TOAST.success,
        content: t('notification.success.advanced_permissions_updated', {
          0: currentItem.name,
        }),
      });
    }
  };

  const validateChanges = (shownItems: ViewRowProps[]) => {
    let message = '';
    const allMembersNotices = [
      t('widget.permissions.modal.public.folder.message'),
      t('widget.permissions.modal.allMembersAndSiteVisitors'),
    ];

    const hasSiteVisitorsAccess = shownItems.filter(
      (item) =>
        item.roleType === RoleType.SITE_VISITORS &&
        item.selectedPermission !== PermissionsLevelEnumPermissionsLevel.NONE,
    );
    const allMemebersShown = shownItems.filter(
      (item) =>
        item.roleType === RoleType.ALL_MEMBERS &&
        item.selectedPermission !== PermissionsLevelEnumPermissionsLevel.NONE,
    );

    const hasGenericGroups =
      hasSiteVisitorsAccess.length + allMemebersShown.length;
    const hasMoreRows =
      shownItems.filter(
        (item) =>
          item.selectedPermission !== PermissionsLevelEnumPermissionsLevel.NONE,
      ).length > hasGenericGroups;

    if (hasGenericGroups && hasMoreRows) {
      if (hasSiteVisitorsAccess.length + allMemebersShown.length > 1) {
        message = allMembersNotices[1];
      } else if (hasSiteVisitorsAccess.length) {
        message = t(allMembersNotices[0], {
          0: hasSiteVisitorsAccess[0].role,
        });
      } else {
        message = t(allMembersNotices[0], { 0: allMemebersShown[0].role });
      }
    }
    return message;
  };

  return (
    <div>
      <EntityAdvancedPermissions
        entityId={currentItem.id}
        entityTitle={folderNameTitle}
        items={filteredRoles ? filteredRoles : []}
        queryFunction={queryFunction}
        onSave={saveClicked}
        removePermissionCode={PermissionsLevelEnumPermissionsLevel.NONE}
        labels={getLabels(t)}
        loading={isLoading ? isLoading : Loading.InProgress}
        validateChanges={validateChanges}
        availablePermissions={permissions(t)}
        onClose={onClose}
        mobile={isMobile}
        availability={currentItem.availability}
        showHiddenFolder_app={showHiddenFolder_app}
      />
    </div>
  );
};
