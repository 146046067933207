import React from 'react';
import { Button, ButtonPriority } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from '../MainToolBar.st.css';
import { AddFolderStatus } from '../../../../../../Observables/AddNewFolder.observable';
import { setAddFolderStatus } from '../../../../../../Services/AddNewFolder.service';
import withMemberAuthorization from '../../../../../../../Shared/HOC/withMemberAuthorization';
import biService from '../../../../../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK } from '../../../../../../BiEvents/Constants/BiConstants';
import { handleMemberAuth } from '../../../../../../Shared/memberAuthorizationHelper';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getSettingsData } from '../../../../../../Services/AppSettings.service';

const FolderButton = (props: any) => {
  const {
    memberAuthorization,
    folderPermissions,
    openPrivacyModal,
    isCreateFolderDisabled,
    isMobile,
  } = props;
  const { t } = useTranslation();
  const [, settingsData]: any = useObservable(getSettingsData());
  const borderRadius =
    settingsData?.settings?.designSettings?.buttonCornerRadius ?? 0;
  const title = t('app.widget.header.folder.button');

  const handleFolderClick = () => {
    handleMemberAuth({
      permissions: folderPermissions,
      callBack: setAddFolderStatus,
      openPrivacyModal,
      memberAuthorization,
      memberAuthorizationArgs: [AddFolderStatus.OPENED],
    });
    biService({ is_first: false }, BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK);
  };
  return (
    <>
      <Button
        className={st(classes.folderButton, { isMobile })}
        style={{ borderRadius }}
        disabled={isCreateFolderDisabled}
        onClick={handleFolderClick}
        priority={ButtonPriority.secondary}
      >
        {title}
      </Button>
    </>
  );
};

export default withMemberAuthorization(FolderButton);
