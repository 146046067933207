import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Status } from '../../../../Constants/Permissions';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedFolder } from '../../../Services/SelectedFolder.service';
import EmptyStates from '../EmptyStates';
import { getRootFolder } from '../../../Services/RootFolder.service';

const EmptyFolder = () => {
  const { t } = useTranslation();
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const showFileButton =
    selectedFolder !== undefined &&
    selectedFolder.folderAuthorizedActions?.[0]?.status === Status.ALLOWED;
  const uploadFilePermissions =
    selectedFolder?.folderAuthorizedActions?.[0] || {};
  const isUploadFileDisabled =
    rootFolder === undefined && selectedFolder === undefined;

  const text1 = showFileButton
    ? t('app.widget.emptyState.empty.text1')
    : t('app.widget.emptyState.noPermissions.title');
  const text2 = showFileButton
    ? t('app.widget.emptyState.empty.text2')
    : t('app.widget.emptyState.noPermissions.subTitle');
  return (
    <EmptyStates
      title={text1}
      subTitle={text2}
      showFileButton={showFileButton}
      uploadFilePermissions={uploadFilePermissions}
      isUploadFileDisabled={isUploadFileDisabled}
    />
  );
};

export default EmptyFolder;
