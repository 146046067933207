import { BehaviorSubject, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { searchService } from '../Services/Search.service';

const searchSubject$ = new BehaviorSubject({ callApi: false, value: '' });
const searchStatus$ = new BehaviorSubject(false);
const disableButtonsStatus$ = new BehaviorSubject(false);

export const getSearchSubject$ = () => searchSubject$;

export const setSearchSubject$ = (searchSubject: any) =>
  searchSubject$.next(searchSubject);

export const getSearchStatus$ = () => searchStatus$;

export const setSearchStatus$ = (searchStatus: any) =>
  searchStatus$.next(searchStatus);

export const getDisableButtonsStatus$ = () => disableButtonsStatus$;

export const setDisableButtonsStatus$ = (disableButtonsStatus: any) =>
  disableButtonsStatus$.next(disableButtonsStatus);

export const searchObservable$ = searchSubject$.pipe(
  debounceTime(1750),
  distinctUntilChanged(
    (oldObj, newObj) => JSON.stringify(oldObj) === JSON.stringify(newObj),
  ),
  mergeMap(() => from(searchService())),
);
