import { TOAST } from '../../../Constants/Toast.Constants';
import { UPLOAD_GLITCH } from '../../../Constants/UploadTosts.Constants';
import { setToast } from '../Toast.service';

export const UploadGlitchErrorNotifications = (
  t: any,
  type: string,
  filesAmount: number,
  exceedAmount?: number,
) => {
  let text = '';
  switch (type) {
    case UPLOAD_GLITCH.FILEUPLOAD_SINGLE_GLITCH:
      text = t('app.widget.notification.FILEUPLOAD_SINGLE_GLITCH');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case UPLOAD_GLITCH.FILEUPLOAD_MULTIPLE_GLITCH:
      text = t('app.widget.notification.FILEUPLOAD_MULTIPLE_GLITCH', {
        totalFilesAmount: filesAmount,
        totalFilesAmount2: filesAmount,
      });
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case UPLOAD_GLITCH.FILEUPLOAD_GENERAL_GLITCH:
      text = t('app.widget.notification.FILEUPLOAD_GENERAL_GLITCH');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case UPLOAD_GLITCH.FILEUPLOAD_SINGLE_EXCEED:
      text = t('app.widget.notification.FILEUPLOAD_SINGLE_EXCEED');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case UPLOAD_GLITCH.FILEUPLOAD_MULTIPLE_EXCEED:
      text = t('app.widget.notification.FILEUPLOAD_MULTIPLE_EXCEED', {
        exceedAmount,
        totalAmount: filesAmount,
      });
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case UPLOAD_GLITCH.FILEUPLOAD_MULTIPLE_LOT:
      text = t('app.widget.notification.FILEUPLOAD_MULTIPLE_LOT');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    default:
      return;
  }
};
