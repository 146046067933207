import React from 'react';
import { classes, st } from './iconStyle.st.css';

export const PptFill = (props: any) => {
  const { isMobile, fileIconsColoredByType, isMembersArea } = props;
  const className = fileIconsColoredByType
    ? ''
    : st(classes._fileIconColor, { isMembersArea });
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 23H5.5C4.672 23 4 22.328 4 21.5V2.5C4 1.672 4.672 1 5.5 1H13L15 6L20 8V21.5C20 22.328 19.328 23 18.5 23Z"
        fill="#FE6C02"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="#FE6C02"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 17H12.5H11.5H9V13H15V17ZM17 12H7V13H8V17C8 17.553 8.448 18 9 18H11.5V19H10V20H14V19H12.5V18H15C15.553 18 16 17.553 16 17V13H17V12Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.639 10.2372C27.234 10.0852 26.914 9.76525 26.762 9.36125L24.001 2.00025H6.501C5.672 1.99925 5 2.67125 5 3.50025V40.5002C5 41.3283 5.672 42.0002 6.5 42.0002H33.5C34.329 42.0002 35.0003 41.3282 35.0003 40.4992V12.9982L27.639 10.2372Z"
        fill="#FE6C02"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="#FE6C02"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0003 20V21H27.0003V29.5C27.0003 30.327 26.3273 31 25.5003 31H20.5003V33H22.0003V34H18.0003V33H19.5003V31H14.5003C13.6733 31 13.0003 30.327 13.0003 29.5V21H12.0003V20H28.0003ZM26.0003 21H14.0003V29.5C14.0003 29.775 14.2253 30 14.5003 30H25.5003C25.7753 30 26.0003 29.775 26.0003 29.5V21ZM22.0003 24V28H21.0003V24H22.0003ZM24.0003 23V28H23.0003V23H24.0003ZM20.0003 26V28H19.0003V26H20.0003Z"
        fill="white"
      />
    </svg>
  );
};
