import { fileShareReport } from '@wix/bi-logger-wixlabs-ugc/v2';

export const biFileShareWidgetReport = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    visitor_id,
    comp_id,
    owner,
    file_id,
    file_name,
    file_size,
    file_type,
    is_folder,
    report_reason,
  } = props;
  logger?.report(
    fileShareReport({
      instance_id,
      app_id,
      visitor_id,
      comp_id,
      owner,
      file_id,
      file_name,
      file_size,
      file_type,
      is_folder,
      report_reason,
    }),
  );
};
