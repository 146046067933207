import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  membersTabTitle: SettingsParamType.Text;
  membersTabSubtitle: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  membersTabTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('widget.members.title'),
  },
  membersTabSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('widget.members.description.uploads'),
  },
});
