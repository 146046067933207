import { Status } from '../../Constants/Permissions';

export const isDisableTableHeader = ({
  rootFolder,
  selectedFolder,
  serverError,
}: any) => {
  const isServerError = serverError && serverError.isError === true
  const viewPermissions =
    rootFolder &&
    rootFolder.authorizeActions?.find(
      (itemData: any) => itemData.action === 'VIEW',
    );
  const disabledCheckBox =
    (viewPermissions !== undefined &&
      viewPermissions.status === Status.FORBIDDEN) ||
    (selectedFolder !== undefined &&
      selectedFolder?.libraryItems?.length === 0) ||
    isServerError;
  return disabledCheckBox;
};
