import { fileSharePermissionsGetAccessClick } from '@wix/bi-logger-wixlabs-ugc/v2';
import { fileSharePermissionsGetAccessClickParams } from '@wix/bi-logger-wixlabs-ugc/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsGetAccessClick = (
  props: PermissionType<fileSharePermissionsGetAccessClickParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsGetAccessClick({ ...params }));
};
