export const ITEM_MENU_OPTIONS = {
  SELECT: 'SELECT',
  DOWNLOAD: 'DOWNLOAD',
  SHARE: 'SHARE',
  RENAME: 'RENAME',
  MANAGE_PERMISSIONS: 'MANAGE_PERMISSIONS',
  MOVE: 'MOVE',
  DELETE: 'DELETE',
  REPORT: 'REPORT',
  DIVIDER: 'DIVIDER',
  OPEN_IN_LIBRARY: 'OPEN_IN_LIBRARY',
};

export const STATUS = {
  FORBIDDEN: 'FORBIDDEN',
  ALLOWED: 'ALLOWED',
};
