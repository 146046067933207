import { fileSharePermissionsSignAdded } from '@wix/bi-logger-wixlabs-ugc/v2';
import { PermissionType } from '../biService.types';
import { fileSharePermissionsSignAddedParams } from '@wix/bi-logger-wixlabs-ugc/v2/types';

export const biFileSharePermissionsSignAdded = (
  props: PermissionType<fileSharePermissionsSignAddedParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsSignAdded(params));
};
