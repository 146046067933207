import React from 'react';
import { st, classes } from '../../../../../../Shared/SharedText.st.css';
import { classes as itemViewClasses } from './ItemViews.st.css';
import { Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';

const ItemViews = (props: any) => {
  const { item } = props;
  const { t } = useTranslation();
  const { uniqueViews } = item;
  const [error1, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const isMembersArea = AppSettings?.isMembersArea ?? false;
  return (
    <div
      className={
        isMobile === true
          ? itemViewClasses.itemViewsContainerMobile
          : itemViewClasses.itemViewsContainer
      }
      title={item.uniqueViews}
    >
      <Text
        className={`${st(classes._textColor, { isMobile, isMembersArea })} ${
          classes.contentEllipsis
        }`}
      >
        {isMobile !== undefined && isMobile
          ? t('app.widget.table.items.views_counter', { views: uniqueViews })
          : uniqueViews}
      </Text>
    </div>
  );
};

export default ItemViews;
