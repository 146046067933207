import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import {
  RequestLogin$,
  navigateToPricingPlans$,
  UserSubject$,
  navigateToLibrary$,
} from '../Observables/User.observable';
import { Reason, Status } from '../../Constants/Permissions';
import { rootFolderSubject$ } from '../Observables/RootFolder.observable';

export const memberAuthorizationHelper = async () => {
  const requestLoginTempResult = RequestLogin$.asObservable();
  const requestLoginLastTempResult = requestLoginTempResult.pipe(take(1));
  const requestLogin = await lastValueFrom(requestLoginLastTempResult);
  return requestLogin;
};

export const getUserHelper = async () => {
  const userTempResult = UserSubject$.asObservable();
  const userTempResult2 = userTempResult.pipe(take(1));
  const userResult = await lastValueFrom(userTempResult2);
  return userResult;
};

export const navigateToPricingPlansAppHelper = async () => {
  const navigateToPricingPlansAppTempResult =
    navigateToPricingPlans$.asObservable();
  const navigateToPricingPlansAppLastTempResult =
    navigateToPricingPlansAppTempResult.pipe(take(1));
  const navigateToPricingPlansApp = await lastValueFrom(
    navigateToPricingPlansAppLastTempResult,
  );
  return navigateToPricingPlansApp;
};
export const navigateToLibraryHelper = async () => {
  const navigateToLibraryTempResult = navigateToLibrary$.asObservable();
  const navigateToLibraryLastTempResult = navigateToLibraryTempResult.pipe(
    take(1),
  );
  const navigateToLibrary = await lastValueFrom(
    navigateToLibraryLastTempResult,
  );
  return navigateToLibrary;
};

export const handleMemberAuth = async ({
  permissions,
  callBack,
  openPrivacyModal,
  memberAuthorization,
  memberAuthorizationArgs = [],
}: any) => {
  if (permissions.status === Status.FORBIDDEN) {
    if (permissions.reason === Reason.MUST_BE_PUBLIC) {
      openPrivacyModal(callBack);
    } else if (permissions.reason === Reason.MUST_BE_A_MEMBER) {
      memberAuthorization({ memberAuthorizationArgs, callBack });
    }
  } else {
    callBack(...memberAuthorizationArgs);
  }
};
export const getRootFolderHelper = async () => {
  const rootFolderTempResult = rootFolderSubject$.asObservable();
  const rootFolderLastTempResult = rootFolderTempResult.pipe(take(1));
  const rootFolder = await lastValueFrom(rootFolderLastTempResult);
  return rootFolder;
};
