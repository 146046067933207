import { AppSettingsInterface } from '../Interfaces/AppSettings.Interface';
import {
  getAppSettings$,
  setAppSettings$,
  getSettingsData$,
  setSettingsData$,
} from '../Observables/AppSettings.observable';
import { GetAppSettingsResponse } from '@wix/ambassador-file-sharing-v1-settings/types';
import { FileSharingService } from '../Api/Api';
import { BehaviorSubject } from 'rxjs';

let API = null;
export const setAppSettings = (appSettings: AppSettingsInterface) => {
  setAppSettings$(appSettings);
};

export const getAppSettings = () => {
  return getAppSettings$();
};

export const getSettingsData = (): BehaviorSubject<GetAppSettingsResponse> => {
  return getSettingsData$();
};

export const setSettingsData = (settings: GetAppSettingsResponse) => {
  setSettingsData$(settings);
};

export const setSettingsDataAPI = async () => {
  try {
    API ??= new FileSharingService();
    if (API) {
      const response = await API.getSettings();
      if (response?.data) {
        setSettingsData({ ...response.data });
      } else {
        setSettingsData({});
      }
    }
  } catch (e) {
    console.log('error:', e);
  }
};
