import React from 'react';
import { IconButton, Text } from 'wix-ui-tpa';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeft';
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall';
import { st, classes } from './HeaderWrapper.st.css';

const HeaderWrapper = ({ name, onClick, isMobile, onClose, disabled }: any) => {
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerButton}>
        <IconButton
          className={classes.iconStyle}
          icon={disabled ? <ChevronLeft /> : <div />}
          onClick={onClick}
        />
        <Text className={st(classes.textStyle, { isMobile })}>{name}</Text>
      </div>
      {isMobile && (
        <div>
          <CloseSmall onClick={() => onClose && onClose()} />
        </div>
      )}
    </div>
  );
};

export default HeaderWrapper;
