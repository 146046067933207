import { fileShareUploadButtonAddOnAdded } from '@wix/bi-logger-wixlabs-users/v2';
import { fileShareUploadButtonAddOnAddedParams } from '@wix/bi-logger-wixlabs-users/v2/types';
import { PermissionType } from '../biService.types';

export const biFileShareUploadButtonAddOnAdded = (
  props: PermissionType<fileShareUploadButtonAddOnAddedParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileShareUploadButtonAddOnAdded({ ...params }));
};
