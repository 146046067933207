import { fileShareMoveFileClick } from '@wix/bi-logger-wixlabs-ugc/v2';

export const biFileShareWidgetMoveFileClick = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    visitor_id,
    comp_id,
    owner,
    file_id,
    file_name,
    file_owner,
    file_size,
    file_type,
    description,
    favorites_count,
    is_folder,
    num_of_files_selected,
    views_count,
  } = props;
  logger?.report(
    fileShareMoveFileClick({
      instance_id,
      app_id,
      visitor_id,
      comp_id,
      owner,
      file_id,
      file_name,
      file_owner,
      file_size,
      file_type,
      favorites_count,
      is_folder,
      num_of_files_selected,
      views_count,
      description,
    }),
  );
};
