import { take, lastValueFrom } from 'rxjs';
import { FileSharingService } from '../../Api/Api';
import {
  moveObservable$,
  addNewFolderMoveObservable$,
} from '../../Observables/Move.observable';
import { setMoveLoader } from '../ItemsMove.service';
import {
  setAddNewFolderMoveObservable,
  setMoveObservable,
} from './Move.service';
import biService from '../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED } from '../../BiEvents/Constants/BiConstants';
import { moveUpdateTable } from './MoveUpdateTable';

let API: any = null;
API = new FileSharingService();
const mapData = new Map();

export const startMoving = async (
  movingToFolderId?: string,
  movingToFolderName?: string,
) => {
  setMoveLoader(true);
  if (movingToFolderId !== undefined && movingToFolderName !== undefined) {
    cameFromRow(movingToFolderId, movingToFolderName);
  } else {
    cameFromOutSide();
  }
};

const cameFromOutSide = async () => {
  let createdFolderId = '';
  try {
    const moveObservable = await moveObservableHelper();
    const addMoveObservable = await addMoveObservableHelper();
    if (
      addMoveObservable.folderName !== '' &&
      moveObservable.currentFolderId !== ''
    ) {
      const query = {
        action: {
          parentFolderId: moveObservable.currentFolderId,
          name: addMoveObservable.folderName,
        },
      };

      const creatingFolder = await API.createFolder(query);
      if (creatingFolder.data) {
        createdFolderId = creatingFolder?.data?.folderId;
        const newFolderAdded = {
          folderName: addMoveObservable.folderName,
          folderId: createdFolderId,
        };
        updateMoveObservable(newFolderAdded, moveObservable.currentFolderId);
        resetAddMoveObservable();
        biService(
          {
            is_first: false,
            folder_name: addMoveObservable.folderName,
            is_camera: false,
            level: 1,
            origin: 'move',
            parent_folder_id: moveObservable.currentFolderId,
          },
          BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED,
        );
        moveUpdateTable(newFolderAdded);
      } else {
        console.log('error creating folder');
      }
    }
    setMoveLoader(false);
  } catch (e) {
    console.log(e);
    setMoveLoader(false);
  }
};

const cameFromRow = async (
  folderIdToUpdate: string,
  folderNameToUpdate: string,
) => {
  let createdFolderId = '';

  try {
    const addMoveObservable = await addMoveObservableHelper();
    const moveObservable = await moveObservableHelper();
    if (addMoveObservable.folderName === '') {
      setMoveObservable({
        ...moveObservable,
        currentSubFolders:
          moveObservable.FolderTreeMap.get(folderIdToUpdate).subfolders,
        parentFolderId: moveObservable.currentFolderId,
        parentFolderName: moveObservable.currentFolderName,
        currentFolderId: folderIdToUpdate,
        currentFolderName: folderNameToUpdate,
      });
      setMoveLoader(false);
      return;
    }

    if (folderIdToUpdate !== '' && addMoveObservable.folderName !== '') {
      const query = {
        action: {
          parentFolderId: folderIdToUpdate,
          name: addMoveObservable.folderName,
        },
      };

      const creatingFolder = await API.createFolder(query);
      if (creatingFolder.data) {
        createdFolderId = creatingFolder?.data?.folderId;
        const newFolderAdded = {
          folderName: addMoveObservable.folderName,
          folderId: createdFolderId,
        };
        updateMoveObservable(
          newFolderAdded,
          folderIdToUpdate,
          folderNameToUpdate,
        );
        biService(
          {
            is_first: false,
            folder_name: addMoveObservable.folderName,
            is_camera: false,
            level: 1,
            origin: 'move',
            parent_folder_id: moveObservable.currentFolderId,
          },
          BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED,
        );
        moveUpdateTable(newFolderAdded);
      } else {
        console.log('error creating folder');
      }
      resetAddMoveObservable();
    }
    setMoveLoader(false);
  } catch (e) {
    console.log(e);
    setMoveLoader(false);
  }
};

const updateMoveObservable = async (
  newFolderAdded: any,
  folderIdToUpdate: string, // folder inside map to update  = MUST BE THE SAME currentFolderId
  folderNameToUpdate?: string,
  newParentFolderId?: string,
  newParentFolderName?: string,
) => {
  const moveObservable = await moveObservableHelper();

  const newResult = newFolderGenerator(
    newFolderAdded,
    folderIdToUpdate,
    folderNameToUpdate || moveObservable.currentFolderName,
  );
  const updatedMap =
    moveObservable.FolderTreeMap.get(folderIdToUpdate).subfolders.unshift(
      newResult,
    );
  moveObservable.FolderTreeMap.set(newResult.folderId, newResult);

  if (folderNameToUpdate !== undefined) {
    setMoveObservable({
      ...moveObservable,
      currentSubFolders:
        moveObservable.FolderTreeMap.get(folderIdToUpdate).subfolders,
      folderTreeMap: moveObservable.FolderTreeMap,
      parentFolderId: newParentFolderId
        ? newParentFolderId
        : moveObservable.currentFolderId,
      parentFolderName: newParentFolderName
        ? newParentFolderName
        : moveObservable.currentFolderName,
      currentFolderId: folderIdToUpdate,
      currentFolderName: folderNameToUpdate,
    });
  } else {
    setMoveObservable({
      ...moveObservable,
      folderTreeMap: updatedMap,
    });
  }
};

export const moveObservableHelper = async () => {
  const tempResult = moveObservable$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const addMoveObservableHelper = async () => {
  const tempResult = addNewFolderMoveObservable$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const resetAddMoveObservable = () => {
  const obj = {
    status: false,
    folderName: '',
  };
  setAddNewFolderMoveObservable(obj);
};

const newFolderGenerator = (
  newObj: any,
  folderIdToUpdate: string,
  parentFolderName: string,
) => {
  const newResult = {
    name: newObj.folderName,
    folderId: newObj.folderId,
    subfolders: [],
    parent: { parentId: folderIdToUpdate, name: parentFolderName },
  };
  return newResult;
};

export const cameFromBack = async (
  folderIdToUpdate: string,
  folderNameToUpdate: string,
) => {
  let createdFolderId = '';
  try {
    const addMoveObservable = await addMoveObservableHelper();
    const moveObservable = await moveObservableHelper();
    if (addMoveObservable.folderName === '') {
      setMoveObservable({
        ...moveObservable,
        currentSubFolders:
          moveObservable.FolderTreeMap.get(folderIdToUpdate).subfolders,
        parentFolderId:
          moveObservable.FolderTreeMap.get(folderIdToUpdate).parent.parentId,
        parentFolderName:
          moveObservable.FolderTreeMap.get(folderIdToUpdate).parent.name,
        currentFolderId: folderIdToUpdate,
        currentFolderName: folderNameToUpdate,
      });
      setMoveLoader(false);
      return;
    }

    if (folderIdToUpdate !== '' && addMoveObservable.folderName !== '') {
      const query = {
        action: {
          parentFolderId: folderIdToUpdate,
          name: addMoveObservable.folderName,
        },
      };

      const creatingFolder = await API.createFolder(query);
      if (creatingFolder.data) {
        createdFolderId = creatingFolder?.data?.folderId;
        const newFolderAdded = {
          folderName: addMoveObservable.folderName,
          folderId: createdFolderId,
        };
        const newParentFolderId =
          moveObservable.FolderTreeMap.get(folderIdToUpdate).parent.parentId;
        const newParentFolderName =
          moveObservable.FolderTreeMap.get(folderIdToUpdate).parent.name;
        updateMoveObservable(
          newFolderAdded,
          folderIdToUpdate,
          folderNameToUpdate,
          newParentFolderId,
          newParentFolderName,
        );

        moveUpdateTable(newFolderAdded);
      } else {
        console.log('error creating folder');
      }
      resetAddMoveObservable();
    }
    setMoveLoader(false);
  } catch (e) {
    console.log(e);
  }
};
