import React from 'react';
import Search from 'wix-ui-icons-common/Search';
import { TextField, TextFieldTheme } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import { getBreadCrumbs } from '../../../../Services/BreadCrumbs.service';
import {
  setSearchSubject,
  getSearchSubject,
  setDisableButtonsStatus,
} from '../../../../Services/Search.service';
import { st, classes } from './SearchTextField.st.css';
import { getSelectedFolder } from '../../../../Services/SelectedFolder.service';
import { getAppSettings } from '../../../../Services/AppSettings.service';

const SearchTextField = () => {
  const { t } = useTranslation();
  const [, breadCrumbs]: any = useObservable(getBreadCrumbs());
  const [, searchObj]: any = useObservable(getSearchSubject());
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';

  let currentFolderName = '';
  if (breadCrumbs?.length === 1) {
    currentFolderName = t('root.folder.label');
  } else if (breadCrumbs?.length > 1) {
    currentFolderName = breadCrumbs[breadCrumbs.length - 1].value;
  }
  const placeholder = t('app.widget.header.search.placeholder', {
    0: currentFolderName,
  });
  const disabledTextField =
    selectedFolder !== undefined &&
    selectedFolder?.libraryItems?.length === 0 &&
    !(searchObj && searchObj?.value !== undefined && searchObj?.value !== '');
  const handleOnChange = (newValue: any, callApi: boolean) => {
    setSearchSubject({ callApi, value: newValue });
  };

  return (
    <div
      className={
        isMobile !== undefined && !isMobile ? classes.searchDesktopWidth : ''
      }
    >
      <TextField
        className={st(classes.searchTextField, {
          isMobile,
          isDisabled: disabledTextField,
        })}
        disabled={disabledTextField}
        onFocus={() => setDisableButtonsStatus(true)}
        onBlur={() => {
          if (searchObj && searchObj?.value?.length <= 0) {
            setDisableButtonsStatus(false);
          }
        }}
        value={searchObj && searchObj.value !== null ? searchObj.value : ''}
        prefix={<Search />}
        theme={TextFieldTheme.Line}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e.target.value, true)}
        withClearButton
        onClear={() => {
          handleOnChange(null, false);
        }}
        height="40px"
      />
    </div>
  );
};

export default SearchTextField;
