import React from 'react';
import TableHeaderSelected from './TableHeaderSelected/TableHeaderSelected';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import TableHeader from './TableHeader/TableHeader';
import styles from './TableHeaderWrapper.css';
import { getSelectedItems } from '../../../../Services/SelectedItems.service';
import { getAppSettings } from '../../../../Services/AppSettings.service';

const TableHeaderWrapper = () => {
  const [error1, selectedItems]: any = useObservable(getSelectedItems());
  const [error7, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  return (
    <div>
      {selectedItems && selectedItems.length === 0 ? (
        <div
          className={
            isMobile
              ? styles.TableMobileHeaderContainer
              : styles.TableHeaderContainer
          }
        >
          <TableHeader />
        </div>
      ) : (
        <>
          {!isMobile ? (
            <div className={styles.TableHeaderContainer}>
              <TableHeaderSelected />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default TableHeaderWrapper;
