export const BI_FILE_SHARE_PERMISSIONS_OPEN = 'BI_FILE_SHARE_PERMISSIONS_OPEN';
export const BI_FILE_SHARE_PERMISSIONS_CLOSE =
  'BI_FILE_SHARE_PERMISSIONS_CLOSE';
export const BI_FILE_SHARE_PERMISSIONS_CLICK_BM_ROLES_LINK =
  'BI_FILE_SHARE_PERMISSIONS_CLICK_BM_ROLES_LINK';
export const BI_FILE_SHARE_CLICK_MEMBERS_SIGN_UP_SETTINGS =
  'BI_FILE_SHARE_CLICK_MEMBERS_SIGN_UP_SETTINGS';
export const BI_FILE_SHARE_UPGRADE_CLICK_BUTTON =
  'BI_FILE_SHARE_UPGRADE_CLICK_BUTTON';
export const BI_FILE_SHARE_UPLOAD_BUTTON_ADDON_ADDED =
  'BI_FILE_SHARE_UPLOAD_BUTTON_ADDON_ADDED';
export const BI_FILE_SHARE_SHARED_FOLDER_ADDON_ADDED =
  'BI_FILE_SHARE_SHARED_FOLDER_ADDON_ADDED';
export const BI_FILE_SHARE_MANAGE_FILES_CLICK =
  'BI_FILE_SHARE_MANAGE_FILES_CLICK';
