import { FileSharingService } from '../Api/Api';
import {
  getSelectedFolder$,
  selectedFolderIdSubject$,
  setSelectedFolder$,
} from '../Observables/SelectedFolder.observable';
import { setIsServerError } from './ServerError.service';
import { lastValueFrom, take } from 'rxjs';
import {
  getSearchStatus$,
  setSearchStatus$,
  getSearchSubject$,
  setSearchSubject$,
  getDisableButtonsStatus$,
  setDisableButtonsStatus$,
} from '../Observables/Search.observable';
import { setIsLoading } from './Loading.service';
import {
  authorizeActions,
  getSelectedFolderId,
  setSelectedFolder,
  setSelectedFolderId,
} from './SelectedFolder.service';
import { getSort } from './Sort.service';
import biService from '../BiEvents/BiService';
import { getBreadCrumbs$ } from '../Observables/BreadCrumbs.observable';
import { BI_FILE_SHARE_WIDGET_SEARCH } from '../BiEvents/Constants/BiConstants';
import { setBreadCrumbs } from './BreadCrumbs.service';

const API = new FileSharingService();

export const setSearchSubject = (searchSubject: any) => {
  setSearchSubject$(searchSubject);
};

export const getSearchSubject = () => {
  return getSearchSubject$();
};
export const setSearchStatus = (searchStatus: any) => {
  setSearchStatus$(searchStatus);
};

export const getSearchStatus = () => {
  return getSearchStatus$();
};

export const setDisableButtonsStatus = (disableButtonsStatus: boolean) => {
  setDisableButtonsStatus$(disableButtonsStatus);
};

export const getDisableButtonsStatus = () => {
  return getDisableButtonsStatus$();
};

export const searchService = async () => {
  let results_count = 0;
  let folder_name = '';
  const selectedFolder = await getSelectedFolder();
  if (selectedFolder === undefined) {
    return;
  }
  setIsLoading(true);
  setSearchStatus(undefined);
  const selectedFolderId = await getCurrentSelectedId();
  const searchValue = await getSearchValue();
  const sortValue = await getSortValue();
  const breadCrumbs = await getBreadCrumbsValue();
  if (!searchValue.callApi) {
    if (searchValue.value === '') {
      setSearchStatus(false);
    } else {
      setSearchStatus(true);
    }
    setIsLoading(false);
    return;
  }
  try {
    const query: any = {
      sort: {
        sortBy: sortValue.sortBy,
        orientation: sortValue.orientation,
      },
    };

    if (searchValue && searchValue.value !== '') {
      query.filter = {
        descendantsOfFolderId: selectedFolderId,
        search: searchValue.value,
      };
    } else {
      query.filter = {
        parentLibraryItemIds: [selectedFolderId],
      };
    }

    const res = await API.searchByValue(query);

    if (
      res?.data &&
      res?.data?.libraryItems &&
      res?.data?.libraryItems?.length > 0
    ) {
      results_count = res?.data?.libraryItems?.length;
      const authorizedFolders = await authorizeActions(
        res.data,
        res.data.libraryItems[0].parentFolderId,
      );
      if (authorizedFolders !== undefined && authorizedFolders?.libraryItems) {
        const data = {
          libraryItems: authorizedFolders.libraryItems,
          folderAuthorizedActions: authorizedFolders.folderAuthorizedActions,
          metaData: res.data.metaData,
        };
        setSelectedFolder$(data);
      }
      setIsLoading(false);
      if (searchValue && searchValue.value !== '') {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
    } else {
      const selectedFolderLastValue = await getSelectedFolder();
      setSelectedFolder$({
        ...selectedFolderLastValue,
        ...res.data,
      });
      if (searchValue && searchValue.value !== '') {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
      }
      setIsLoading(false);
    }
    folder_name =
      breadCrumbs && breadCrumbs?.length > 0
        ? breadCrumbs[breadCrumbs?.length - 1]?.value
        : breadCrumbs[0]?.value;
    biService(
      {
        origin: breadCrumbs?.length === 1 ? 'home' : 'folder',
        results_count,
        search_text: searchValue?.value,
        folder_name,
      },
      BI_FILE_SHARE_WIDGET_SEARCH,
    );
  } catch (e) {
    const error: any = e;
    setIsServerError({
      isError: true,
      message: error.message,
    });
    setIsLoading(false);
    return error.message;
  }
};

export const getCurrentSelectedId = async () => {
  const tempResult = selectedFolderIdSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const getSearchValue = async () => {
  const tempResult = getSearchSubject().asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

const getSortValue = async () => {
  const tempResult = getSort().asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
export const getSelectedFolder = async () => {
  const tempResult = getSelectedFolder$().asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
const getBreadCrumbsValue = async () => {
  const tempResult = getBreadCrumbs$().asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

export const resetSearch = async () => {
  setSearchStatus(false);
  setSearchSubject({ callApi: true, value: '' });
  setDisableButtonsStatus(false);
};

export const navigateToParentFolder = async (item: any) => {
  try {
    setIsLoading(true);
    const tempResult = getSelectedFolderId().asObservable();
    const lastTempResult = tempResult.pipe(take(1));
    const previousResult = await lastValueFrom(lastTempResult);
    const folderId =
      item.path.length > 0 ? item.path[item.path.length - 1].libraryItemId : '';

    const breadCrumbs = item.path.map(
      (folder: { name: string; libraryItemId: string }) => {
        return {
          value: folder.name,
          id: folder.libraryItemId,
        };
      },
    );
    setBreadCrumbs(breadCrumbs);
    setSearchSubject({ callApi: false, value: '' });
    setDisableButtonsStatus(false);
    if (folderId === previousResult) {
      setSelectedFolder(folderId);
    } else {
      setSelectedFolderId(folderId);
    }
  } catch (e) {}
};
