import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;

  _buttonColor: StyleParamType.Color;
  _btnBackgroundColor: StyleParamType.Color;
  _buttonFont: StyleParamType.Font;

  _fileIconColor2: StyleParamType.Color;
  _fileIconColor: StyleParamType.Color;
  _fileIconColorLabeled: StyleParamType.Color;
  _fileIconColor2Labeled: StyleParamType.Color;
  _fileIconColorLine: StyleParamType.Color;
  _folderFont: StyleParamType.Font;
  _textFont: StyleParamType.Font;
  _textFont1: StyleParamType.Font;
  _textColor: StyleParamType.Color;
  _hoverAndActionstableColor: StyleParamType.Color;
  _backgroundColor: StyleParamType.Color;
  _fileAndFolderColor: StyleParamType.Color;
  _buttonsAndActionsColor: StyleParamType.Color;
  _itemHoverColor: StyleParamType.Color;
  _titleFont: StyleParamType.Font;
  _pageDescriptionFont: StyleParamType.Font;
  _subTabsFont: StyleParamType.Font;
  _columnsAndItemsFont: StyleParamType.Font;
  _itemsNamesFont: StyleParamType.Font;
  _membersBgColor: StyleParamType.Color;
  _membersBorderColor: StyleParamType.Color;
  _membersBorderWidth: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  _buttonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  _btnBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  _buttonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  _fileIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  _fileIconColor2: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  _fileIconColorLabeled: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  _fileIconColor2Labeled: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  _fileIconColorLine: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  _folderFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  _textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  _textFont1: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  _textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  _hoverAndActionstableColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.02),
  },
  _backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  _fileAndFolderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  _buttonsAndActionsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  _itemHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.02),
  },
  _titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 26,
    }),
  },
  _pageDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  _subTabsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  _columnsAndItemsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  _itemsNamesFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  _membersBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  _membersBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  _membersBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
