export const PDF = ['PDF'];
export const IMAGEARR = [
  'JPG',
  'JPEG',
  'PNG',
  'GIF',
  'SVG',
  'PSD',
  'DXF',
  'AI',
  'SKETCH',
  'HEIC',
  'DWG',
];
export const MEDIAARR = [
  'MP3',
  'WAV',
  'FLAC',
  'M4A',
  'm4p',
  'MP4',
  'AVI',
  'MOV',
  'XVID',
  'MKV',
  'OGV',
  'VOB',
  '3GP',
  'MPG',
  'MPE',
  'MPEG',
  'divx',
];
export const ARCHIEVEARR = [
  'GPX',
  'XYZ',
  'EXE',
  'TTF',
  'OTF',
  'WEB',
  'WOFF2',
  'WOFF',
  'ZIP',
  'RAR',
  'GZIP',
  'TAR',
  'GZ',
];
export const XLSARR = ['XLS', 'XLSX', 'XLSM', 'CSV'];
export const DOCARR = ['DOC', 'DOCX', 'ODT', 'XPS', 'TXT'];
export const PPTARR = ['ODP', 'PPS', 'PPSX', 'PPT', 'PPTX'];

export const FILETYPE = {
  PDF: 'PDF',
  IMAGE: 'IMAGE',
  MEDIA: 'MEDIA',
  ARCHIEVE: 'ARCHIEVE',
  XLS: 'XLS',
  DOC: 'DOC',
  PPT: 'PPT',
};
