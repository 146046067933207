import React from "react";
import {
  UNWANTED_OR_SPAM,
  HARASSMENT_OR_BULLYING,
  HATE_SPEECH_OR_GRAPHIC_VIOLENCE,
  INAPPROPRIATE_CONTENT,
} from "./reportTypes";

export const reportDialogOptions = (t: any) => [
  {
    value: UNWANTED_OR_SPAM,
    label: t("app.widget.report.dialog.option.spam"),
  },
  {
    value: HARASSMENT_OR_BULLYING,
    label: t("app.widget.report.dialog.option.harassment"),
  },
  {
    value: INAPPROPRIATE_CONTENT,
    label: t("app.widget.report.dialog.option.inappropriate.content"),
  },
  {
    value: HATE_SPEECH_OR_GRAPHIC_VIOLENCE,
    label: t("app.widget.report.dialog.option.hate.speech.or.violence"),
  },
];
