import React from 'react';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { Status } from '../../../../Constants/Permissions';
import AccessModalsWrapper from '../../EmptyStates/AccessModalsWrapper/AccessModalsWrapper';
import { getMenuSelectedItem } from '../../../Services/MenuSelectedItem';

const PermissionsWrapper = (props: any) => {
  const [, currentItem]: any = useObservable(getMenuSelectedItem());
  const viewPermissions =
    currentItem &&
    currentItem.authorizeActions?.find(
      (itemData: any) => itemData.action === 'VIEW',
    );

  return (
    <>
      {currentItem?.availability &&
      viewPermissions &&
      viewPermissions.status === Status.FORBIDDEN ? (
        <AccessModalsWrapper
          handleOnClose={props.handleOnClose}
          showInModal
          folderName={currentItem && currentItem?.name}
          viewPermissions={viewPermissions}
          availability={currentItem?.availability}
        />
      ) : null}
    </>
  );
};

export default PermissionsWrapper;
