import React from 'react';
import { Text } from 'wix-ui-tpa';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';
import { st, classes } from '../../../../../../Shared/SharedText.st.css';
import { classes as itemNameClasses } from './ItemName.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getSearchStatus } from '../../../../../../Services/Search.service';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getRootFolder } from '../../../../../../Services/RootFolder.service';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';
import SearchSubtitle from './SearchSubtitle';
import formatBytes from '../../../../../../Shared/helper/FileSize';

const ItemNameSubTitle = (props: any) => {
  const { item } = props;
  const { t } = useTranslation();
  const [, searchStatus]: any = useObservable(getSearchStatus());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const isMembersArea = AppSettings?.isMembersArea ?? false;

  const Items: string = t('app.widget.actions.itemName.Items', {
    numberOfItems:
      ITEMTYPE.FOLDER in item ? item?.folderFields?.childrenCount : null,
  });
  const showSearchSubTitle =
    rootFolder !== undefined &&
    searchStatus !== undefined &&
    searchStatus === true;

  const getSubtitleText = () => {
    if (isMembersArea && isMobile) {
      return;
    }
    return ITEMTYPE.FILE in item
      ? isMobile
        ? t('app.widget.itemName.createdBy', { createdBy: item.createdByName })
        : formatBytes(item.fileFields.size, 2, t)
      : ITEMTYPE.FOLDER in item
      ? `${Items}`
      : null;
  };
  return (
    <div
      className={
        isMobile
          ? itemNameClasses.itemSubtitleMobile
          : itemNameClasses.itemSubtitle
      }
    >
      <Text className={st(classes._textColor, { isMobile, isMembersArea })}>
        {getSubtitleText()}
        {showSearchSubTitle && isMobile ? ',' : ''}
      </Text>

      {showSearchSubTitle ? (
        <SearchSubtitle item={item} isMobile={isMobile} />
      ) : null}
    </div>
  );
};

export default ItemNameSubTitle;
