import { selectedItemsSubject$ } from '../Observables/SelectedItems.observable';
import { lastValueFrom, take } from 'rxjs';
import { menuSelectedItem$ } from '../Observables/MenuSelectedItem';

export const getSelectedItemsHelper = async () => {
  const tempResult = selectedItemsSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};

export const getCurrentItemHelper = async () => {
  const tempResult = menuSelectedItem$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
