import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import EmptyStates from '../EmptyStates';

const ErrorState = () => {
  const { t } = useTranslation();

  const title = t('app.widget.emptyState.error.title');
  const subTitle = t('app.widget.emptyState.error.subTitle');

  return <EmptyStates title={title} subTitle={subTitle} />;
};

export default ErrorState;
