import { getBiEventsParams } from '../Services/BiEvents.service';
import { getSettingsBiEventsParams } from '../../Settings/Services/biEventsSettingsParams.service';
import { lastValueFrom, take } from 'rxjs';
import {
  BI_FILE_SHARE_WIDGET_APP_LOADED,
  BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER,
  BI_FILE_SHARE_WIDGET_DETAILS,
  BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE,
  BI_FILE_SHARE_WIDGET_FAVORITE_CLICK,
  BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK,
  BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK,
  BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK,
  BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED,
  BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK,
  BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED,
  BI_FILE_SHARE_WIDGET_RENAME_FILE,
  BI_FILE_SHARE_WIDGET_REPORT,
  BI_FILE_SHARE_WIDGET_SEARCH,
  BI_FILE_SHARE_WIDGET_SORT_BY,
  BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER,
  BI_FILE_SHARE_PERMISSIONS_SAVE_STEP1,
  BI_FILE_SHARE_PERMISSIONS_SAVE_STEP2,
  BI_FILE_SHARE_PERMISSIONS_SIGN_ADDED,
  BI_FILE_SHARE_PERMISSIONS_SIGN_REMOVED,
  BI_FILE_SHARE_PERMISSIONS_GET_ACCESS_CLICK,
  BI_FILE_SHARE_PERMISSIONS_ACCESS_WINDOW_OPENED,
} from './Constants/BiConstants';
import { biFileShareWidgetAppLoaded } from './BiFunctions/biFileShareWidgetAppLoaded';
import { biFileShareWidgetNewFileClick } from './BiFunctions/biFileShareWidgetNewFileClick';
import { biFileShareWidgetNewFolderClick } from './BiFunctions/biFileShareWidgetNewFolderClick';
import { biFileShareWidgetNewFileCreated } from './BiFunctions/biFileShareWidgetNewFileCreated';
import { biFileShareWidgetNewFolderCreated } from './BiFunctions/biFileShareWidgetNewFolderCreated';
import { biFileShareWidgetFileMenuClick } from './BiFunctions/biFileShareWidgetFileMenuClick';
import { biFileShareWidgetDeleteFileOrFolder } from './BiFunctions/biFileShareWidgetDeleteFileOrFolder';
import { biFileShareWidgetMoveFileClick } from './BiFunctions/biFileShareWidgetMoveFileClick';
import { biFileShareWidgetRenameFile } from './BiFunctions/biFileShareWidgetRenameFile';
import { biFileShareWidgetSortBy } from './BiFunctions/biFileShareWidgetSortBy';
import { biFileShareWidgetSearch } from './BiFunctions/biFileShareWidgetSearch';
import { biFileShareWidgetViewFileOrOpenFolder } from './BiFunctions/biFileShareWidgetViewFileOrOpenFolder';
import { biFileShareWidgetFavoriteClick } from './BiFunctions/biFileShareWidgetFavoriteClick';
import { biFileShareWidgetDownloadFile } from './BiFunctions/biFileShareWidgetDownloadFile';
import { biFileShareWidgetReport } from './BiFunctions/biFileShareWidgetReport';
import { biFileShareWidgetDetails } from './BiFunctions/biFileShareWidgetDetails';
import { biFileSharePermissionsSaveStep1 } from './BiFunctions/biFileSharePermissionsSaveStep1';
import { biFileSharePermissionsSaveStep2 } from './BiFunctions/biFileSharePermissionsSaveStep2';
import { biFileSharePermissionsSignRemoved } from './BiFunctions/biFileSharePermissionsSignRemoved';
import { biFileSharePermissionsSignAdded } from './BiFunctions/biFileSharePermissionsSignAdded';
import { biFileSharePermissionsGetAccessClick } from './BiFunctions/biFileSharePermissionsGetAccessClick';
import { biFileSharePermissionsAccessWindowOpened } from './BiFunctions/biFileSharePermissionsAccessWindowOpened';
import {
  BI_FILE_SHARE_CLICK_MEMBERS_SIGN_UP_SETTINGS,
  BI_FILE_SHARE_MANAGE_FILES_CLICK,
  BI_FILE_SHARE_PERMISSIONS_CLICK_BM_ROLES_LINK,
  BI_FILE_SHARE_PERMISSIONS_CLOSE,
  BI_FILE_SHARE_PERMISSIONS_OPEN,
  BI_FILE_SHARE_SHARED_FOLDER_ADDON_ADDED,
  BI_FILE_SHARE_UPGRADE_CLICK_BUTTON,
  BI_FILE_SHARE_UPLOAD_BUTTON_ADDON_ADDED,
} from './Constants/biSettingsConstants';
import { biFileSharePermissionsOpen } from './BiFunctionsSettings/biFileSharePermissionsOpen';
import { biFileSharePermissionsClose } from './BiFunctionsSettings/biFileSharePermissionsClose';
import { biFileSharePermissionsClickBmRolesLink } from './BiFunctionsSettings/biFileSharePermissionsClickBmRolesLink';
import { biFileShareMembersSignupSettingsClickLink } from './BiFunctionsSettings/biFileShareMembersSignupSettingsClickLink';
import { biFileShareUpgradeClickButton } from './BiFunctionsSettings/biFileShareUpgradeClickButton';
import { biFileShareUploadButtonAddOnAdded } from './BiFunctionsSettings/biFileShareUploadButtonAddOnAdded';
import { biSharedFolderAddOnsAdded } from './BiFunctionsSettings/biSharedFolderAddOnsAdded';
import { biFileShareManageFilesClick } from './BiFunctionsSettings/biFileShareManageFilesClick';

const biService = async (params: any, biType: string, isSettings = false) => {
  let biEventParams = null;

  if (biEventParams === null) {
    if (isSettings) {
      const tempObservable$ = getSettingsBiEventsParams().pipe(take(1));
      biEventParams = await lastValueFrom(tempObservable$);
    } else {
      const tempObservable$ = getBiEventsParams().pipe(take(1));
      biEventParams = await lastValueFrom(tempObservable$);
    }
  }
  const newParams = { ...biEventParams, ...params };
  switch (biType) {
    case BI_FILE_SHARE_WIDGET_APP_LOADED:
      return biFileShareWidgetAppLoaded(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK:
      return biFileShareWidgetNewFileClick(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK:
      return biFileShareWidgetNewFolderClick(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED:
      return biFileShareWidgetNewFileCreated(newParams);
    case BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED:
      return biFileShareWidgetNewFolderCreated(newParams);
    case BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK:
      return biFileShareWidgetFileMenuClick(newParams);
    case BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER:
      return biFileShareWidgetDeleteFileOrFolder(newParams);
    case BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK:
      return biFileShareWidgetMoveFileClick(newParams);
    case BI_FILE_SHARE_WIDGET_RENAME_FILE:
      return biFileShareWidgetRenameFile(newParams);
    case BI_FILE_SHARE_WIDGET_SORT_BY:
      return biFileShareWidgetSortBy(newParams);
    case BI_FILE_SHARE_WIDGET_SEARCH:
      return biFileShareWidgetSearch(newParams);
    case BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER:
      return biFileShareWidgetViewFileOrOpenFolder(newParams);
    case BI_FILE_SHARE_WIDGET_FAVORITE_CLICK:
      return biFileShareWidgetFavoriteClick(newParams);
    case BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE:
      return biFileShareWidgetDownloadFile(newParams);
    case BI_FILE_SHARE_WIDGET_REPORT:
      return biFileShareWidgetReport(newParams);
    case BI_FILE_SHARE_WIDGET_DETAILS:
      return biFileShareWidgetDetails(newParams);
    case BI_FILE_SHARE_PERMISSIONS_SAVE_STEP1:
      return biFileSharePermissionsSaveStep1(newParams);
    case BI_FILE_SHARE_PERMISSIONS_SAVE_STEP2:
      return biFileSharePermissionsSaveStep2(newParams);
    case BI_FILE_SHARE_PERMISSIONS_SIGN_ADDED:
      return biFileSharePermissionsSignAdded(newParams);
    case BI_FILE_SHARE_PERMISSIONS_SIGN_REMOVED:
      return biFileSharePermissionsSignRemoved(newParams);
    case BI_FILE_SHARE_PERMISSIONS_ACCESS_WINDOW_OPENED:
      return biFileSharePermissionsAccessWindowOpened(newParams);
    case BI_FILE_SHARE_PERMISSIONS_GET_ACCESS_CLICK:
      return biFileSharePermissionsGetAccessClick(newParams);
    case BI_FILE_SHARE_PERMISSIONS_OPEN:
      return biFileSharePermissionsOpen(newParams);
    case BI_FILE_SHARE_PERMISSIONS_CLOSE:
      return biFileSharePermissionsClose(newParams);
    case BI_FILE_SHARE_PERMISSIONS_CLICK_BM_ROLES_LINK:
      return biFileSharePermissionsClickBmRolesLink(newParams);
    case BI_FILE_SHARE_CLICK_MEMBERS_SIGN_UP_SETTINGS:
      return biFileShareMembersSignupSettingsClickLink(newParams);
    case BI_FILE_SHARE_UPGRADE_CLICK_BUTTON:
      return biFileShareUpgradeClickButton(newParams);
    case BI_FILE_SHARE_UPLOAD_BUTTON_ADDON_ADDED:
      return biFileShareUploadButtonAddOnAdded(newParams);
    case BI_FILE_SHARE_SHARED_FOLDER_ADDON_ADDED:
      return biSharedFolderAddOnsAdded(newParams);
    case BI_FILE_SHARE_MANAGE_FILES_CLICK:
      return biFileShareManageFilesClick(newParams);
    default:
  }
};
export default biService;
