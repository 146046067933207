import { BehaviorSubject, map, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectedFolderSubject$ } from './SelectedFolder.observable';

export const uploadingFileStatus$ = new BehaviorSubject({
  status: 'CLOSED',
  libraryItems: [],
});

export const getUploadingFileStatus$ = () => uploadingFileStatus$;
export const setUploadingFileStatus$ = (newUploadingStatus: any) =>
  uploadingFileStatus$.next(newUploadingStatus);

export const addFilesToSelectedFolder$ = async (newLibraryItems: any) => {
  const tempResult = selectedFolderSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = { ...previousResult };
  newResult.libraryItems = [...newLibraryItems, ...newResult.libraryItems];
  selectedFolderSubject$.next(newResult);
};
