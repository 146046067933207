export const DESKTOPFORMFACTOR = 'Desktop';
export const MOBILEFORMFACTOR = 'Mobile';
export const SITEVIEW = 'Site';
export const EDITORVIEW = 'Editor';

export const APP_DEF_ID_FILE_SHARE_PROD =
  '1537b24e-29d1-6d8f-b8e1-d6860f2f70b9';
export const PATH_TO_DASHBOARD = `app/${APP_DEF_ID_FILE_SHARE_PROD}`;

export const SETTINGS_UPDATE_ACTION = 'settingsData';
