export const checkConnection = () => {
  return new Promise((resolve, reject) => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      fetch('https://www.google.com/', {
        mode: 'no-cors',
      })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          const error = new Error('No Good Connection');
          reject(error);
        });
    } else {
      const error = new Error('OFFLINE');
      reject(error);
    }
  });
};
