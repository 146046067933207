import React, { useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import SkeletonItem from './SkeletonItem/SkeletonItem';
import { SkeletonLoader } from './SkeletonLoader/SkeletonLoader';
import { AddFolderStatus } from '../../../../../Observables/AddNewFolder.observable';

export const SkeletonItemWrapper = ({ status }: any) => {
  const { t } = useTranslation();
  const statusInProgress = status === AddFolderStatus.IN_PROGRESS;
  const statusIsOpened = status === AddFolderStatus.OPENED;
  const [folderName, setFolderName] = useState<string>(
    t('app.widget.action.create.new.folder'),
  );

  return (
    <>
      {statusInProgress && <SkeletonLoader folderName={folderName} />}
      {statusIsOpened && (
        <SkeletonItem folderName={folderName} setFolderName={setFolderName} />
      )}
    </>
  );
};
