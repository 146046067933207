import { lastValueFrom, take } from 'rxjs';
import { FileSharingService } from '../Api/Api';
import {
  getMoveFolderTree$,
  setMoveFolderTree$,
  getMoveLoader$,
  setMoveLoader$,
} from '../Observables/ItemsMove.observable';
import { selectedFolderSubject$ } from '../Observables/SelectedFolder.observable';
import {
  getSelectedItemsHelper,
  getCurrentItemHelper,
} from '../Shared/ItemsHelpers';
import { setIsLoading } from './Loading.service';

export const setMoveFolderTree = (moveData: any) => {
  setMoveFolderTree$(moveData);
};

export const getMoveFolderTree = () => {
  return getMoveFolderTree$();
};
export const setMoveLoader = (moveLoader: boolean) => {
  setMoveLoader$(moveLoader);
};

export const getMoveLoader = () => {
  return getMoveLoader$();
};

export const getFolderTree = async (rootLibraryItemId: any) => {
  try {
    setMoveLoader$(true);
    let API: any = null;
    API = new FileSharingService();
    const query = {
      rootLibraryItemId,
      fieldSet: ['PERMISSIONS'],
    };
    const res = await API.getFolderTree(query);
    setMoveFolderTree$(res?.data?.folderTree);
    setMoveLoader$(false);
  } catch (e) {
    setMoveLoader$(false);
    return e;
  }
};

