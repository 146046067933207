import {
  getIsLoading$,
  setIsLoading$,
} from '../Observables/Loading.observable';

export const setIsLoading = (value: boolean) => {
  setIsLoading$(value);
};

export const getIsLoading = () => {
  return getIsLoading$();
};
