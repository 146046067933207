import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import {
  setSelectedFolder,
  setSelectedFolderId,
} from '../../../Services/SelectedFolder.service';
import EmptyStates from '../EmptyStates';
import { getRootFolder } from '../../../Services/RootFolder.service';
import { setDisableButtonsStatus } from '../../../Services/Search.service';

const ItemNotFound = () => {
  const { t } = useTranslation();
  const [, rootFolder]: any = useObservable(getRootFolder());
  const uploadFilePermissions = false;
  const isUploadFileDisabled = true;
  const showFileButton = false;
  const text1 = t('app.widget.emptyState.item.not.found.title');
  const text2 = t('app.widget.emptyState.item.not.found.subTitle');

  setDisableButtonsStatus(true);
  return (
    <EmptyStates
      title={text1}
      subTitle={text2}
      showFileButton={showFileButton}
      uploadFilePermissions={uploadFilePermissions}
      isUploadFileDisabled={isUploadFileDisabled}
      secondaryText={t('widget.permissions.modal.button.back')}
      secondaryButtonOnClick={async () => {
        setSelectedFolderId(rootFolder.id);
        await setSelectedFolder(rootFolder.id);
        setDisableButtonsStatus(false);
      }}
    />
  );
};

export default ItemNotFound;
