import { FILETYPE, PDF, IMAGEARR, MEDIAARR, ARCHIEVEARR, XLSARR, DOCARR, PPTARR } from "../../../Constants/FileIconConstants";

export const returnFileType = (fileType: string) => {
  if (PDF.includes(fileType)) {
    return FILETYPE.PDF;
  }
  else if (IMAGEARR.includes(fileType)) {
    return FILETYPE.IMAGE;
  }
  else if (MEDIAARR.includes(fileType)) {
    return FILETYPE.MEDIA;
  }
  else if (ARCHIEVEARR.includes(fileType)) {
    return FILETYPE.ARCHIEVE;
  }
  else if (XLSARR.includes(fileType)) {
    return FILETYPE.XLS;
  }
  else if (DOCARR.includes(fileType)) {
    return FILETYPE.DOC;
  }
  else if (PPTARR.includes(fileType)) {
    return FILETYPE.PPT;
  }
}
