import React, { useMemo } from 'react';
import { MemberTab } from '../../../Observables/MemberTab.observable';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getCurrentTab } from '../../../Services/MemberTab.service';
import EmptyStates from '../EmptyStates';
import { useTranslation } from '@wix/yoshi-flow-editor';
import withMemberAuthorization from '../../../../Shared/HOC/withMemberAuthorization';
import { getAppSettings } from '../../../Services/AppSettings.service';
import { getUser } from '../../../Services/User.service';

const MembersEmptyLibrary = ({
  navigateToLibrary,
}: {
  navigateToLibrary: () => void;
}) => {
  const { t } = useTranslation();
  const [, currentTab = MemberTab.Uploads] = useObservable(getCurrentTab());
  const [, user]: any = useObservable(getUser());
  const [, appSettings]: any = useObservable(getAppSettings());
  const viewedUserId = appSettings?.viewedUserId;
  const sameUser = useMemo(
    () => user?.id === viewedUserId,
    [user, viewedUserId],
  );
  let title = '';
  let subtitle = '';
  if (sameUser) {
    title =
      currentTab === MemberTab.Uploads
        ? t('widget.members.empty.uploads.title')
        : t('widget.members.empty.favorites.title');
    subtitle =
      currentTab === MemberTab.Uploads
        ? t('widget.members.empty.uploads.subtitle')
        : t('widget.members.empty.favorites.subtitle');
  } else {
    title = t('widget.members.empty.uploads.user.title');
    subtitle = t('widget.members.empty.uploads.user.subtitle');
  }

  return (
    <EmptyStates
      title={title}
      subTitle={subtitle}
      secondaryText={sameUser ? t('widget.members.empty.state.button') : ''}
      secondaryButtonOnClick={sameUser ? navigateToLibrary : () => {}}
    />
  );
};
export default withMemberAuthorization(MembersEmptyLibrary);
