import { TOAST } from '../../../Constants/Toast.Constants';
import { UPLOAD_OFFLINE } from '../../../Constants/UploadTosts.Constants';
import { setToast } from '../Toast.service';

export const UploadOfflineErrorNotifications = (
  t: any,
  type: string,
  filesAmount: number,
) => {
  let text = '';
  switch (type) {
    case UPLOAD_OFFLINE.FILEUPLOAD_SINGLE_OFFLINE:
      text = t('app.widget.notification.FILEUPLOAD_SINGLE_OFFLINE');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case UPLOAD_OFFLINE.FILEUPLOAD_MULTIPLE_OFFLINE:
      text = t('app.widget.notification.FILEUPLOAD_MULTIPLE_OFFLINE', {
        totalFilesAmount: filesAmount,
        totalFilesAmount2: filesAmount,
      });
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    case UPLOAD_OFFLINE.FILEUPLOAD_GENERAL_OFFLINE:
      text = t('app.widget.notification.FILEUPLOAD_GENERAL_OFFLINE');
      setToast({
        skin: TOAST.error,
        content: text,
      });
      break;
    default:
      return;
  }
};
