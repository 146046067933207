import {
  DisplaySettings,
  TextAlignment,
  LibraryItemSortBy,
  FileIcon,
  Orientation,
} from '@wix/ambassador-file-sharing-v1-settings/types';

import biService from '../BiService';
import {
  BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER,
  BI_FILE_SHARE_WIDGET_DETAILS,
  BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE,
  BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK,
  BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK,
} from '../Constants/BiConstants';

const menuClickBi = (actionName: string, item: any) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields?.size,
      file_type: item?.fileFields?.extension,
      button_description: actionName,
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      num_of_files_selected: 1,
      views_count: item?.uniqueViews,
    },
    BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK,
  );
};
const deleteFileOrFolderBi = (
  item: any,
  description: string,
  num_of_files_selected: number,
) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields
        ? item?.fileFields?.size
        : item?.folderFields?.childrenCount,
      file_type: item?.fileFields ? item?.fileFields?.extension : '',
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      num_of_files_selected,
      views_count: item?.uniqueViews,
      description,
    },
    BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER,
  );
};
const moveFileClickBi = (
  item: any,
  description: string,
  num_of_files_selected: number,
) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields
        ? item?.fileFields?.size
        : item?.folderFields?.childrenCount,
      file_type: item?.fileFields ? item?.fileFields?.extension : '',
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      num_of_files_selected,
      views_count: item?.uniqueViews,
      description,
    },
    BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK,
  );
};
const downloadFileBi = (item: any) => {
  biService(
    {
      file_id: item?.id,
      file_name: item?.name,
      file_owner: item?.createdByName,
      file_size: item?.fileFields
        ? item?.fileFields?.size
        : item?.folderFields?.childrenCount,
      file_type: item?.fileFields ? item?.fileFields?.extension : '',
      favorites_count: item?.timesFavorited,
      is_folder: !!item?.folderFields,
      views_count: item?.uniqueViews,
      file_owner_id: item?.createdByProfile?.id,
    },
    BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE,
  );
};

const getSortByLabelById = (
  sortBy: LibraryItemSortBy | undefined,
  orientation: Orientation | undefined,
) => {
  switch (sortBy) {
    case LibraryItemSortBy.UNIQUE_VIEWS:
      return 'most viewed';
    case LibraryItemSortBy.TIMES_FAVORITED:
      return 'most favorites';
    case LibraryItemSortBy.LAST_MODIFIED:
      return 'last updated';
    case LibraryItemSortBy.NAME:
      switch (orientation) {
        case Orientation.DESC:
          return 'Z-A';
        case Orientation.ASC:
        default:
          return 'A-Z';
      }
    case LibraryItemSortBy.TYPE:
      return 'item type';
    case LibraryItemSortBy.SIZE:
      return 'item size';
    default:
      return '';
  }
};

const widgetDetailsBi = (
  displaySettingsData: DisplaySettings,
  fileIconStyleSettings: FileIcon,
  folderId: any,
  folderName: any,
) => {
  const display_date =
    displaySettingsData.showLastModified ||
    displaySettingsData.showFileUploadDate;
  const display_favorites = displaySettingsData?.showFavoritesCounter;
  const display_favorites_counter = displaySettingsData?.showFavoritesCounter;
  const display_owner = displaySettingsData?.showFileOwnerName;
  const display_search_bar = true;
  const display_size =
    displaySettingsData?.showSizeOfFiles ||
    displaySettingsData.showNumberOfItems;
  const display_views = displaySettingsData?.showViewsCounter;

  biService(
    {
      display_date,
      display_favorites,
      display_favorites_counter,
      display_owner,
      display_search_bar,
      display_size,
      display_views,
      parent_folder_id: folderId,
      folder_name: folderName,
      icon: `${fileIconStyleSettings}`.toLowerCase(),
      layout_alignment:
        displaySettingsData.textAlignment === TextAlignment.LEFT,
      sort_by: getSortByLabelById(
        displaySettingsData?.defaultSort,
        displaySettingsData?.defaultOrientation,
      ),
    },
    BI_FILE_SHARE_WIDGET_DETAILS,
  );
};

export {
  deleteFileOrFolderBi,
  menuClickBi,
  moveFileClickBi,
  downloadFileBi,
  widgetDetailsBi,
};
