import { HttpClient } from '@wix/http-client';
import {
  queryLibraryItems,
  createFolder,
  authorizeActions,
  _delete,
  reportLibraryItem,
  share,
  download,
  rename,
  favorite,
  viewFolder,
  getFolderTree,
  move,
  viewFile,
  startFileUpload,
  completeFileUpload,
  queryLibraryFolderItems,
} from '@wix/ambassador-file-sharing-v1-library-item/http';
import {
  requestAccess,
  listRoles,
  setPermissionsLevel,
  listMembers,
} from '@wix/ambassador-file-sharing-v1-role/http';

import { getAppSettings } from '../Services/AppSettings.service';
import {
  getAppSettings as getAPISettings,
  updateAppSettings,
} from '@wix/ambassador-file-sharing-v1-settings/http';
import { AppSettings } from '@wix/ambassador-file-sharing-v1-settings/types';
import { getInstance } from '../Services/Instance.service';
import {
  SetPermissionsLevelRequest,
  ListMembersRequest,
} from '@wix/ambassador-file-sharing-v1-role/types';
import {
  LibraryItemSorting,
  LibraryItemType,
  QueryLibraryItemsRequest,
  QueryLibraryItemsResponse,
} from '@wix/ambassador-file-sharing-v1-library-item/types';
import { distinctUntilChanged } from 'rxjs';

export class FileSharingService {
  fileSharingService: any;
  instance: string = '';
  baseUrl: string = '';
  constructor() {
    getAppSettings()
      .pipe(distinctUntilChanged())
      .subscribe((data: any) => {
        const tempData: any = data;
        let isEditor_X = false;
        if (tempData && 'isEditor_X' in tempData) {
          isEditor_X = data.isEditor_X;
        }
        this.instance = data?.instance;
        if (data?.viewMode === 'Editor') {
          this.baseUrl = 'https://editor.wixapps.net';
        } else if (data?.viewMode === 'Preview') {
          this.baseUrl = isEditor_X
            ? 'https://create.editorx.com/'
            : 'https://editor.wix.com';
        } else {
          this.baseUrl = data?.baseUrl;
        }
        this.instance ??= getInstance().value;
        if (this.instance) {
          this.fileSharingService = new HttpClient({
            headers: { Authorization: this.instance },
          });
        }
      });
  }

  getListRoles = async (query: any) => {
    try {
      const result = await this.fileSharingService.request(listRoles(query));
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };

  updateRolesLevels = async (query: SetPermissionsLevelRequest) => {
    try {
      const result = await this.fileSharingService.request(
        setPermissionsLevel(query),
      );
      return result;
    } catch (e) {
      console.log('updateRolesLevels error', e);

      return e;
    }
  };

  getFolderById = async (
    folderId: string,
    sortParams: any,
    additionalFilterParams?: any,
  ) => {
    try {
      const { orientation, sortBy } = sortParams;
      const sort =
        orientation === '' || sortBy === ''
          ? undefined
          : {
              orientation,
              sortBy,
            };
      let filter = {
        parentLibraryItemIds: [folderId],
      };
      if (additionalFilterParams) {
        filter = Object.assign(filter, additionalFilterParams);
      }
      const result = await this.fileSharingService.request(
        queryLibraryItems({
          filter,
          sort,
        }),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };

  loadMoreItemsApi = async (folderId: string, cursor: string) => {
    try {
      const result = await this.fileSharingService.request(
        queryLibraryItems({
          filter: {
            parentLibraryItemIds: [folderId],
          },
          paging: {
            cursor,
          },
        }),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };

  getFolderInfoById = async (
    folderId: string,
  ): Promise<QueryLibraryItemsResponse> => {
    try {
      const response = await this.fileSharingService.request(
        queryLibraryItems({
          filter: {
            itemIds: [folderId],
          },
        }),
      );
      return response.data;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };
  searchByValue = async (query: any) => {
    try {
      return await this.fileSharingService.request(queryLibraryItems(query));
    } catch (e) {
      return e;
    }
  };
  createFolder = async (query: any) => {
    try {
      return await this.fileSharingService.request(createFolder(query));
    } catch (e) {
      return e;
    }
  };
  authorizeActions = async (query: any) => {
    try {
      return await this.fileSharingService.request(authorizeActions(query));
    } catch (e) {
      return e;
    }
  };
  delete = async (query: any) => {
    try {
      return await this.fileSharingService.request(_delete(query));
    } catch (e) {
      return e;
    }
  };
  report = async (query: any) => {
    try {
      return await this.fileSharingService.request(
        reportLibraryItem({ action: query }),
      );
    } catch (e) {
      return e;
    }
  };
  shareItem = async (query: any) => {
    try {
      return await this.fileSharingService.request(share(query));
    } catch (e) {
      return e;
    }
  };
  downloadItems = async (query: any) => {
    try {
      return await this.fileSharingService.request(download(query));
    } catch (e) {
      return e;
    }
  };

  renameItem = async (query: any) => {
    try {
      return await this.fileSharingService.request(rename(query));
    } catch (e) {
      return e;
    }
  };

  favoriteChangeAPI = async (query: any) => {
    try {
      return await this.fileSharingService.request(favorite(query));
    } catch (e) {
      return e;
    }
  };

  addViewFolderAPI = async (query: any) => {
    try {
      return await this.fileSharingService.request(viewFolder(query));
    } catch (e) {
      return e;
    }
  };
  getFolderTree = async (query: any) => {
    try {
      return await this.fileSharingService.request(getFolderTree(query));
    } catch (e) {
      // setMoveLoader(false);
      return e;
    }
  };
  moveItems = async (query: any) => {
    try {
      return await this.fileSharingService.request(move(query));
    } catch (e) {
      return e;
    }
  };
  viewFileApi = async (query: any) => {
    try {
      return await this.fileSharingService.request(viewFile(query));
    } catch (e) {
      return e;
    }
  };

  startFileUpload = async (query: any) => {
    try {
      return await this.fileSharingService.request(startFileUpload(query));
    } catch (e) {
      return e;
    }
  };

  completeFileUpload = async (query: any) => {
    try {
      return await this.fileSharingService.request(completeFileUpload(query));
    } catch (e) {
      return e;
    }
  };

  requestAccess = async (query: any) => {
    try {
      return await this.fileSharingService.request(requestAccess(query));
    } catch (e) {
      return e;
    }
  };
  getAllFolders = async () => {
    try {
      const result = await this.fileSharingService.request(
        queryLibraryFolderItems({}),
      );
      return result;
    } catch (e) {
      const error: any = e;
      return error;
    }
  };
  getSettings = async () => {
    try {
      const result = await this.fileSharingService.request(getAPISettings({}));
      return result;
    } catch (error) {
      console.log('error in getSettings', error);
      return error;
    }
  };

  updateSettings = async (settings: AppSettings | undefined) => {
    if (!settings) {
      return;
    }
    try {
      const result = await this.fileSharingService.request(
        updateAppSettings({ settings, fieldMask: [] }),
      );
      return result.data;
    } catch (error) {
      console.log('error in updateSettings', error);
      return error;
    }
  };

  getMembers = async (query: ListMembersRequest) => {
    try {
      const response = await this.fileSharingService.request(
        listMembers(query),
      );
      const { data } = response;
      if (!data?.metaData) {
        const members = data?.members || [];
        const length = members.length;
        data.metaData = {
          count: length,
          offset: 0,
          total: length,
        };
      }
      return data;
    } catch (e) {
      console.log('error in getMembers', e);
      return e;
    }
  };

  // getUserUploads = async (
  //   userId: string,
  //   sortOptions: LibraryItemSorting,
  //   nextCursor?: string,
  // ) => {
  //   const requestParams: QueryLibraryItemsRequest = {
  //     sort: sortOptions,
  //     filter: { createdBy: [userId]},
  //     paging: { cursor: nextCursor },
  //   };
  //   const response = await this.fileSharingService.request(
  //     queryLibraryItems(requestParams),
  //   );
  //   const { data } = response;
  //   return data;
  // };
}
