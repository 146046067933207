import { setBreadCrumbs } from '../../../../Services/BreadCrumbs.service';
import { setSelectedFolderId } from '../../../../Services/SelectedFolder.service';

const SetBreadcrumbsCorrectPath = (item: any, breadCrumbs: any) => {
  const tempBreadCrumbs = [...breadCrumbs];
  const index = breadCrumbs.findIndex((element: any) => item.id === element.id);
  tempBreadCrumbs.splice(index + 1);
  setBreadCrumbs(tempBreadCrumbs);
  setSelectedFolderId(item.id);
};

export default SetBreadcrumbsCorrectPath;
