import React, { useEffect, useState, useCallback } from 'react';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { classes, st } from './FileSkeletonItem.st.css';
import {
  st as stSharedDivider,
  classes as sharedDividerClasses,
} from '../../../../../../Shared/SharedDivider.st.css';
import {
  st as sharedTextSt,
  classes as sharedTextClasses,
} from '../../../../../../Shared/SharedText.st.css';
import {
  st as stIconToggle,
  classes as iconToggleClasses,
} from '../../Item/ItemFavorites/ItemFavorites.st.css';
import { classes as iconClasses } from '../../Item/ItemActions/ItemActions.st.css';
import More from 'wix-ui-icons-common/on-stage/More';
import {
  Avatar,
  AvatarSize,
  Divider,
  IconButton,
  IconToggle,
  Spinner,
  SpinnerTypes,
  Text,
} from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import Favorite from 'wix-ui-icons-common/on-stage/Favorite';
import { getTableSettings } from '../../../../../../Services/TableSettings.service';
import { TableColumns } from '../../../../../../../Constants/TableHeader.Constants';
import ItemName from '../../Item/ItemName/ItemName';
import {
  getAppSettings,
  getSettingsData,
} from '../../../../../../Services/AppSettings.service';
import { MobileSkeleton } from '../../MobileSkeleton';

const FileSkeletonItem = ({ item }: any) => {
  const [, tableDisplaySettings]: any = useObservable(getTableSettings());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const dividerWidth =
    settingsData?.settings?.designSettings?.dividerWidth ?? 1;
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const [displaySettings, setDisplaySettings] = useState({
    lastUpdated: false,
    viewCounter: false,
    favorites: false,
    favoritesCounter: false,
    contributors: false,
  });

  const { t } = useTranslation();

  const handleDisplaySettings = useCallback(() => {
    const showFavorites = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.FAVORITES,
    );
    const showFavoritesCounter = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.FAVORITES_COUNTER,
    );
    const showLastUpdated = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.LAST_UPDATED,
    );
    const showViewCounter = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.VIEWS_COUNTER,
    );
    const showContributors = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.CONTRIBUTORS,
    );

    setDisplaySettings({
      lastUpdated: showLastUpdated && showLastUpdated.value,
      viewCounter: showViewCounter && showViewCounter.value,
      favorites: showFavorites && showFavorites.value,
      favoritesCounter: showFavoritesCounter && showFavoritesCounter.value,
      contributors: showContributors && showContributors.value,
    });
  }, [tableDisplaySettings]);

  const handleChangeFolder = () => {};

  useEffect(() => {
    if (tableDisplaySettings && tableDisplaySettings.length > 0) {
      handleDisplaySettings();
    }
  }, [handleDisplaySettings, tableDisplaySettings]);

  return (
    <>
      {isMobile !== undefined && !isMobile && (
        <Divider
          className={stSharedDivider(
            sharedDividerClasses.dividerStyle,
            {
              isEditorX,
              isMobile,
            },
            sharedDividerClasses[`dividerStyle${dividerWidth}`],
          )}
        />
      )}
      <div className={st(classes.skeletonContainer, { isEditorX, isMobile })}>
        <div className={classes.checkBoxContainer} />
        <div
          className={
            classes.itemNameAndLoaderMainContainer +
            ' ' +
            (isMobile ? classes.itemNameAndLoaderMainContainerMobileView : '')
          }
        >
          <div className={classes.itemNameAndLoaderSubOuterContainer}>
            <div className={classes.itemNameAndLoaderSubInnerContainer}>
              <Spinner type={SpinnerTypes.slim} diameter={isMobile ? 25 : 40} />
              <div className={classes.nameContainer}>
                <ItemName item={item} handleChangeFolder={handleChangeFolder} />
              </div>
            </div>
            {isMobile && (
              <div className={classes.moreActionsContainer}>
                <IconButton
                  className={iconClasses.iconButton}
                  icon={<More />}
                />
              </div>
            )}
          </div>
          <div>
            {isMobile !== undefined && isMobile === true && (
              <MobileSkeleton t={t} isMobile={isMobile} />
            )}
          </div>
        </div>
        {displaySettings.lastUpdated && isMobile === false && (
          <div className={classes.lastUpdatedContainer}>
            <Text
              className={sharedTextSt(sharedTextClasses._textColor, {
                isMobile,
              })}
            >
              {t('app.widget.table.content.LastUpdated', {
                view: new Date(),
              })}
            </Text>
          </div>
        )}
        {displaySettings.viewCounter && isMobile === false && (
          <div className={classes.viewsContainer}>
            <Text
              className={sharedTextSt(sharedTextClasses._textColor, {
                isMobile,
              })}
            >
              {0}
            </Text>
          </div>
        )}
        {displaySettings.favorites && isMobile === false && (
          <div className={classes.favoritesContainer}>
            <IconToggle
              className={stIconToggle(iconToggleClasses.IconToggleStyles, {
                isMobile: false,
              })}
              icon={<Favorite />}
              label={displaySettings.favoritesCounter ? '0' : ''}
            />
          </div>
        )}
        {displaySettings.contributors && isMobile === false && (
          <div className={classes.contributorsContainer}>
            <div className={classes.contributorsText}>
              <Text
                className={sharedTextSt(sharedTextClasses._textColor, {
                  isMobile,
                })}
              />
            </div>
            <Avatar size={AvatarSize.small} />
          </div>
        )}
        {!isMobile && (
          <div className={classes.moreActionsContainer}>
            <IconButton className={iconClasses.iconButton} icon={<More />} />
          </div>
        )}
      </div>
      {isMobile !== undefined && isMobile && (
        <Divider
          className={stSharedDivider(
            sharedDividerClasses.dividerStyle,
            {
              isEditorX,
              isMobile,
            },
            sharedDividerClasses[`dividerStyle${dividerWidth}`],
          )}
        />
      )}
    </>
  );
};

export default FileSkeletonItem;
