import React from 'react';
import { FileIcon } from '@wix/ambassador-file-sharing-v1-settings/types';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';
import { getFileIcon } from '../../../../../../Shared/FileAndFolderIcon/getFileIcon';
import { getFolderIcon } from '../../../../../../Shared/FileAndFolderIcon/getFolderIcon';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import {
  getAppSettings,
  getSettingsData,
} from '../../../../../../Services/AppSettings.service';

const ItemNameIcon = (props: any) => {
  const [, AppSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const { item } = props;
  const fileIconStyle: FileIcon =
    settingsData?.settings?.designSettings?.fileIcon;
  const fileIconsColoredByType: boolean =
    settingsData?.settings?.designSettings?.fileIconsColoredByType ?? true;
  const isMembersArea = AppSettings?.isMembersArea ?? false;

  return (
    <>
      {ITEMTYPE.FILE in item
        ? getFileIcon(
            item?.fileFields?.extension?.toUpperCase(),
            fileIconStyle,
            isMobile,
            fileIconsColoredByType,
            isMembersArea,
          )
        : ITEMTYPE.FOLDER in item
        ? getFolderIcon(fileIconStyle, isMobile)
        : null}
    </>
  );
};

export default ItemNameIcon;
