import { fileShareNewFileClick } from '@wix/bi-logger-wixlabs-ugc/v2';

export const biFileShareWidgetNewFileClick = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    visitor_id,
    comp_id,
    is_first,
    owner,
  } = props;
  logger?.report(
    fileShareNewFileClick({
      instance_id,
      app_id,
      visitor_id,
      comp_id,
      is_first,
      owner,
    }),
  );
};
