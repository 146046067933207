import React from 'react';
import { Orientation } from '@wix/ambassador-file-sharing-v1-settings/types';
import { setSort } from '../../../../../../Services/Sort.service';
import SortDown from 'wix-ui-icons-common/on-stage/SortDown';
import SortUp from 'wix-ui-icons-common/on-stage/SortUp';
import { ActionsMenuLayout } from 'wix-ui-tpa';
import { setSelectedFolder } from '../../../../../../Services/SelectedFolder.service';
import { setIsLoading } from '../../../../../../Services/Loading.service';
import { changeSortDirection, getButtonDescriptionBi } from './helpers';
import biService from '../../../../../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_SORT_BY } from '../../../../../../BiEvents/Constants/BiConstants';
import { st, classes } from './ActionsMenuLayoutComponent.st.css';

const customBuildActionsMenuLayoutItem = ({
  id,
  content,
  sortBy,
  orientation,
  selectedFolderId,
  isMobile,
  setIsOpen,
}: any) => {
  const handleOnClick = ({ selectedId }: any) => {
    if (sortBy === selectedId) {
      const tempOrientation = changeSortDirection({ orientation });
      setSort({ sortBy: selectedId, orientation: tempOrientation });
    } else {
      setSort({ orientation, sortBy: selectedId });
    }
    setIsLoading(true);
    setSelectedFolder(selectedFolderId);
    if (isMobile) {
      setIsOpen && setIsOpen(false);
    }
  };
  const PrefixIcon: any =
    sortBy === id ? (
      orientation === Orientation.DESC ? (
        <SortDown className={classes.sortArrow} />
      ) : (
        <SortUp className={classes.sortArrow} />
      )
    ) : (
      <div></div>
    );
  return (
    <ActionsMenuLayout.Item
      key={id}
      onClick={() => {
        handleOnClick({
          selectedId: id,
        });
        biService(
          {
            button_description: getButtonDescriptionBi(id),
            is_first: false,
          },
          BI_FILE_SHARE_WIDGET_SORT_BY,
        );
      }}
      content={content}
      prefixIcon={PrefixIcon}
    />
  );
};
export default customBuildActionsMenuLayoutItem;
