import React from 'react';
import { FileIcon } from '@wix/ambassador-file-sharing-v1-settings/types';

import { classes } from './iconStyle.st.css';

export const FolderOutline = (props: any) => {
  const { folderIconStyle, isMobile } = props;
  const className: any =
    folderIconStyle === FileIcon.LINE
      ? classes._fileIconColorLine
      : classes._fileIconColor2Labeled;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 18.5C20 18.775 19.775 19 19.5 19H4.5C4.225 19 4 18.775 4 18.5V8H14.927H19.5C19.775 8 20 8.225 20 8.5V18.5ZM4 5.5C4 5.225 4.225 5 4.5 5H10.362C10.641 5 10.908 5.117 11.098 5.322L12.645 7H4V5.5ZM19.5 7H14.005L11.832 4.645C11.454 4.233 10.921 4 10.362 4H4.5C3.672 4 3 4.672 3 5.5V18.5C3 19.328 3.672 20 4.5 20H19.5C20.328 20 21 19.328 21 18.5V8.5C21 7.672 20.328 7 19.5 7Z"
        fill="black"
        className={className}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 35.5C36 35.776 35.775 36 35.5 36H4.5C4.225 36 4 35.776 4 35.5V14H23.912H35.5C35.775 14 36 14.225 36 14.5V35.5ZM4 9.5C4 9.224 4.225 9 4.5 9H17.299C17.717 9 18.119 9.177 18.402 9.485L21.635 13H4V9.5ZM35.5 13H22.992L19.139 8.808C18.666 8.293 17.998 8 17.299 8H4.5C3.672 8 3 8.671 3 9.5V13V14V35.5C3 36.329 3.672 37 4.5 37H35.5C36.328 37 37 36.329 37 35.5V14.5C37 13.672 36.328 13 35.5 13Z"
        fill="black"
        className={className}
      />
    </svg>
  );
};
