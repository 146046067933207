import { fileShareSearch } from '@wix/bi-logger-wixlabs-ugc/v2';

export const biFileShareWidgetSearch = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    visitor_id,
    comp_id,
    origin,
    results_count,
    search_text,
  } = props;
  logger?.report(
    fileShareSearch({
      instance_id,
      app_id,
      visitor_id,
      comp_id,
      origin,
      results_count,
      search_text,
    }),
  );
};
