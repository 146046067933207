import { BehaviorSubject, map, lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";


export const selectedFolderIdSubject$ = new BehaviorSubject(
  undefined as unknown as any
);
export const selectedFolderSubject$ = new BehaviorSubject(
  undefined as unknown as any
);


export const getSelectedFolderId$ = () => selectedFolderIdSubject$;
export const setSelectedFolderId$ = (FolderId: any) =>
  selectedFolderIdSubject$.next(FolderId);

export const getSelectedFolder$ = () => selectedFolderSubject$;
export const setSelectedFolder$ = (Folder: any) =>
  selectedFolderSubject$.next(Folder);


export const addMoreFolderItems$ = async (newItems: any) => {
  const tempResult = selectedFolderSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = { ...previousResult };
  newResult.libraryItems = [
    ...newResult.libraryItems,
    ...newItems.libraryItems,
  ];
  if (newItems.metaData) {
    newResult.metaData = newItems.metaData;
  } else {
    newResult.metaData = null;
  }
  selectedFolderSubject$.next(newResult);
};
export const removeItemsFromSelectedFolder$ = async (ids: any) => {
  const tempResult = selectedFolderSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = previousResult?.libraryItems?.filter(
    (item: any) => !ids.includes(item?.id)
  );
  selectedFolderSubject$.next({
    libraryItems: newResult,
    folderAuthorizedActions: previousResult?.folderAuthorizedActions,
    metaData: previousResult?.metaData,
  });
};
