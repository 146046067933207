import React from 'react';
import SortDown from 'wix-ui-icons-common/on-stage/SortDown';
import SortUp from 'wix-ui-icons-common/on-stage/SortUp';
import {
  LibraryItemSortBy,
  Orientation,
} from '@wix/ambassador-file-sharing-v1-settings/types';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { st, classes } from '../TableHeader.st.css';
import biService from '../../../../../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_SORT_BY } from '../../../../../../BiEvents/Constants/BiConstants';

const ViewsComponent = ({
  name,
  sortBy,
  orientation,
  handleTitleColumnOnClick,
  disabledTableHeader,
  isMembersArea,
}: any) => {
  const className = disabledTableHeader ? classes.disabledHeader : '';
  const suffixIcon: any =
    sortBy === LibraryItemSortBy.UNIQUE_VIEWS &&
    (orientation === Orientation.DESC ? (
      <SortDown className={className} />
    ) : (
      <SortUp className={className} />
    ));
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
      }}
    >
      <TextButton
        className={st(classes.tableHeaderColumn, { isMembersArea })}
        contentClassName={classes.contentEllipsis}
        suffixIcon={suffixIcon}
        priority={TextButtonPriority.secondary}
        onClick={() => {
          if (!disabledTableHeader) {
            handleTitleColumnOnClick({
              sortBy,
              selectedId: LibraryItemSortBy.UNIQUE_VIEWS,
            });
            biService(
              { button_description: 'views', is_first: false },
              BI_FILE_SHARE_WIDGET_SORT_BY,
            );
          }
        }}
        title={name}
      >
        {name}
      </TextButton>
    </div>
  );
};
export default ViewsComponent;
