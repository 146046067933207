import React from 'react';
import { classes, st } from './iconStyle.st.css';

export const XlsFill = (props: any) => {
  const { isMobile, fileIconsColoredByType, isMembersArea } = props;
  const className = fileIconsColoredByType
    ? ''
    : st(classes._fileIconColor, { isMembersArea });
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 23H5.5C4.672 23 4 22.328 4 21.5V2.5C4 1.672 4.672 1 5.5 1H13L15 6L20 8V21.5C20 22.328 19.328 23 18.5 23Z"
        fill="#24C69A"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="#24C69A"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 15H15V13H12.5V15ZM12.5 18H15V16H12.5V18ZM9 18H11.5V16H9V18ZM9 15H11.5V13H9V15ZM15 12H9C8.448 12 8 12.447 8 13V18C8 18.553 8.448 19 9 19H15C15.553 19 16 18.553 16 18V13C16 12.447 15.553 12 15 12Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.639 10.2372C27.234 10.0852 26.914 9.76525 26.762 9.36125L24.001 2.00025H6.501C5.672 1.99925 5 2.67125 5 3.50025V40.5002C5 41.3283 5.672 42.0002 6.5 42.0002H33.5C34.329 42.0002 35.0003 41.3282 35.0003 40.4992V12.9982L27.639 10.2372Z"
        fill="#24C69A"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="#24C69A"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5003 31.9736H20.5003V26.9996H27.0003V31.4736C27.0003 31.7486 26.7753 31.9736 26.5003 31.9736ZM19.5003 25.9996H13.0003V21.4736C13.0003 21.1986 13.2253 20.9736 13.5003 20.9736H19.5003V25.9996ZM19.5003 31.9736H13.5003C13.2253 31.9736 13.0003 31.7486 13.0003 31.4736V26.9996H19.5003V31.9736ZM20.5003 20.9736H26.5003C26.7753 20.9736 27.0003 21.1986 27.0003 21.4736V25.9996H20.5003V20.9736ZM26.5003 19.9736H13.5003C12.6743 19.9736 12.0003 20.6466 12.0003 21.4736V31.4736C12.0003 32.3006 12.6743 32.9736 13.5003 32.9736H26.5003C27.3283 32.9736 28.0003 32.3006 28.0003 31.4736V21.4736C28.0003 20.6466 27.3283 19.9736 26.5003 19.9736Z"
        fill="white"
      />
    </svg>
  );
};
