import React from 'react';
import ActionsToolBar from '../../../HeaderWrapper/ToolBarWrapper/ActionsToolBar/ActionsToolBar';
import TableHeaderSelected from '../../../TableWrapper/TableHeaderWrapper/TableHeaderSelected/TableHeaderSelected';
import { classes } from './MobileHeaderBottom.st.css';

const SelectedItemsAndActionsToolbar = () => {
  return (
    <div className={classes.selectedItemsAndActionsToolbarWrapper}>
      <div>
        <TableHeaderSelected />
      </div>
      <div>
        <ActionsToolBar />
      </div>
    </div>
  );
};

export default SelectedItemsAndActionsToolbar;
