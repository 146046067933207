import { BehaviorSubject, take, lastValueFrom } from 'rxjs';

export const SortSubject$ = new BehaviorSubject({
  sortBy: '',
  orientation: '',
});

export const getSort$ = () => SortSubject$;
export const setSort$ = (sortObj: any) => SortSubject$.next(sortObj);

export const getSortLastValue$ = async () => {
  const tempResult = SortSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  return previousResult;
};
