import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import EmptyStates from '../EmptyStates';

const NoResults = () => {
  const { t } = useTranslation();
  const title = t('app.widget.emptyState.noResults.title');
  const subTitle = t('app.widget.emptyState.noResults.subTitle');
  return <EmptyStates title={title} subTitle={subTitle} />;
};

export default NoResults;
