import React, { useCallback, useEffect, useState } from 'react';
import { Toast, ToastSkin, ToastPlacement } from 'wix-ui-tpa';
import { TOAST } from '../../../Constants/Toast.Constants';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../Services/AppSettings.service';
import { getToast, setToast } from '../../Services/Toast.service';
import { classes } from './ToastWrapper.st.css';

const ToastWrapper = () => {
  const [error1, toast]: any = useObservable(getToast());
  const [error2, appSettings]: any = useObservable(getAppSettings());
  const isMobile = getAppSettings !== undefined ? appSettings?.isMobile : '';
  const [state, setState] = useState({
    skin: ToastSkin.status,
    content: '',
  });

  const setSkinType = useCallback(() => {
    if (toast.skin === TOAST.error) {
      setState((prev) => ({
        ...prev,
        skin: ToastSkin.error,
        content: toast.content,
      }));
    } else if (toast.skin === TOAST.success) {
      setState((prev) => ({
        ...prev,
        skin: ToastSkin.success,
        content: toast.content,
      }));
    } else if (toast.skin === TOAST.status) {
      setState((prev) => ({
        ...prev,
        skin: ToastSkin.status,
        content: toast.content,
      }));
    } else if (toast.skin === TOAST.preview) {
      setState((prev) => ({
        ...prev,
        skin: ToastSkin.preview,
        content: toast.content,
      }));
    }
  }, [toast]);

  useEffect(() => {
    if (toast && toast.skin && toast?.skin.length > 0 && toast.content) {
      setSkinType();
    }
  }, [setSkinType, toast]);

  return (
    <>
      {toast && toast?.skin.length && toast?.content ? (
        <Toast
          className={
            classes.toastWrapper +
            ' ' +
            (isMobile ? classes.mobileWrapper : ' ')
          }
          skin={state.skin}
          shouldShowCloseButton
          onClose={() => {
            setToast({
              skin: TOAST.status,
              content: '',
            });
          }}
          placement={
            isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline
          }
        >
          {toast.content}
        </Toast>
      ) : null}
    </>
  );
};

export default ToastWrapper;
