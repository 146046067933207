const formatBytes = (a: any, b: any, t: any) => {
  if (isNaN(a)) {
    return a;
  }
  if (0 === a) {
    return t('app.widget.size', {
      size: 0,
      unit: t('app.widget.unit.bytes'),
    });
  }

  const c = 1024;
  const d = typeof b !== 'undefined' ? b : 2;
  const e = [
      t('app.widget.unit.bytes'),
      t('app.widget.unit.kb'),
      t('app.widget.unit.mb'),
      t('app.widget.unit.gb'),
      t('app.widget.unit.tb'),
      t('app.widget.unit.pb'),
      t('app.widget.unit.eb'),
      t('app.widget.unit.zb'),
      t('app.widget.unit.yb'),
    ],
    f = Math.floor(Math.log(a) / Math.log(c));
  return t('app.widget.size', {
    size: parseFloat((a / Math.pow(c, f)).toFixed(d)),
    unit: e[f],
  });
};

export default formatBytes;
