import {
  getBiEventsSettingsParams$,
  setBiEventsSettingsParams$,
} from '../Observables/biEventsSettings.observable';

export const getSettingsBiEventsParams = () => {
  return getBiEventsSettingsParams$();
};
export const setSettingsBiEventsParams = (biEventsParams: object) => {
  setBiEventsSettingsParams$(biEventsParams);
};
