import React, { useEffect, useState } from 'react';
import { st, classes } from '../../../../../../Shared/SharedText.st.css';
import { classes as styles } from './ItemContributors.st.css';
import {
  Text,
  Avatar,
  AvatarGroup,
  AvatarSize,
  AvatarGroupSize,
} from 'wix-ui-tpa';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';

const ItemContributors = (props: any) => {
  const { item, isMembersArea } = props;
  const [state, setState] = useState<any>({
    recentContributors: [],
  });

  useEffect(() => {

    if (item) {
      let tempElement: any = {};
      let tempRecentContributors: any = [];
      if (ITEMTYPE.FOLDER in item && item?.folderFields?.recentContributors.length > 0) {
        tempRecentContributors = [...item.folderFields.recentContributors];
        const checkIfCreatorInContributors = tempRecentContributors.filter((currentItem: any) => currentItem.id === item.createdByProfile.id);
        if (checkIfCreatorInContributors.length === 0) {
          tempElement.name = item.createdByProfile.name;
          tempElement.imageUrl = item.createdByProfile.imageUrl;
          tempElement.id = item.id;
          tempRecentContributors.push(tempElement);
        }
        tempRecentContributors.map((item: any) => {
          item.src = item.imageUrl;
        });

      } else if (ITEMTYPE.FILE in item || (ITEMTYPE.FOLDER in item &&
        item.folderFields.recentContributors.length === 0)) {
        tempElement.name = item.createdByProfile.name;
        tempElement.imageUrl = item.createdByProfile.imageUrl;
        tempElement.id = item.id;
        tempRecentContributors[0] = tempElement;
      }
      setState((prev: any) => ({
        ...prev,
        recentContributors: tempRecentContributors,
      }));
    }
  }, [item]);

  return (
    <div className={styles.contributorsContainer}>
      {state.recentContributors.length > 1 ? (
        <>
          <div className={styles.contributorsContainerAvatar}>
            <AvatarGroup
              items={state.recentContributors}
              size={AvatarGroupSize.small}
            />
          </div>
        </>
      ) : state.recentContributors.length === 1 ? (
        <>
          <div className={styles.contributorsContainerAvatar}>
            <Avatar
              src={state.recentContributors[0].imageUrl}
              size={AvatarSize.small}
            />
          </div>
          <div
            className={`${styles.contributorsContainerContent} ${classes.contentEllipsis}`}
            title={state.recentContributors[0].name}
          >
            <Text
              className={`${st(classes._textColor, {
                isMobile: false,
                isMembersArea,
              })} ${classes.contentEllipsis}`}
            >
              {state.recentContributors[0].name}
            </Text>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ItemContributors;
