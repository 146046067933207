import React from 'react';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';

interface TextButtonActionProps {
  prefixIcon: React.ReactElement;
  text: string;
  onClick: any;
  className: any;
  isEnabled: boolean;
}

export const TextButtonAction = ({
  prefixIcon,
  text,
  onClick,
  className,
  isEnabled,
}: TextButtonActionProps) => {
  return (
    <TextButton
      disabled={!isEnabled}
      priority={TextButtonPriority.secondary}
      prefixIcon={prefixIcon}
      onClick={onClick}
      className={className}
    >
      {text}
    </TextButton>
  );
};
