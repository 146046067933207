import { fileShareNewFolderCreated } from '@wix/bi-logger-wixlabs-ugc/v2';

export const biFileShareWidgetNewFolderCreated = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    visitor_id,
    comp_id,
    is_first,
    owner,
    folder_name,
    is_camera,
    level,
    origin,
    parent_folder_id,
  } = props;
  logger?.report(
    fileShareNewFolderCreated({
      instance_id,
      app_id,
      visitor_id,
      comp_id,
      owner,
      is_first,
      folder_name,
      is_camera,
      level,
      origin,
      parent_folder_id,
    }),
  );
};
