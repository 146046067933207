import { clickUpgrade } from '@wix/bi-logger-wixlabs-users/v2';
import { clickUpgradeParams } from '@wix/bi-logger-wixlabs-users/v2/types';
import { PermissionType } from '../biService.types';

export const biFileShareUpgradeClickButton = (
  props: PermissionType<clickUpgradeParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(clickUpgrade({ ...params }));
};
