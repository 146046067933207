import React, { useEffect, useRef, useState } from 'react';

const withClickOutsideListener = (WrappedComponent: any) => {
  return (props: any) => {
    const [open, setOpen] = useState(false);
    const ref: any = useRef();

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (event.type === 'mousedown') {
          if (!ref.current?.contains(event.target)) {
            setOpen(false);
          }
        }
        if (event.type === 'keydown') {
          if (event.key === 'Escape') {
            setOpen(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleClickOutside);
      };
    }, [ref]);

    return (
      <WrappedComponent {...props} ref={ref} open={open} setOpen={setOpen} />
    );
  };
};

export default withClickOutsideListener;
