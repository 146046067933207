import React from 'react';
import { st, classes } from './MoveLoadingWrapper.st.css';
import { Spinner } from 'wix-ui-tpa';

export const MoveLoadingWrapper = ({ isMobile }: any) => {
  return (
    <div className={st(classes.spinnerWrapper, { isMobile })}>
      <Spinner isCentered diameter={16} />
    </div>
  );
};
