import React from 'react';
import { classes } from './iconStyle.st.css';

export const XlsTag = (props: any) => {
  const { isMobile, fileIconsColoredByType } = props;
  const className = fileIconsColoredByType ? '' : classes._fileIconColorLabeled;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 22H4.5C4.225 22 4 21.775 4 21.5V2.5C4 2.225 4.225 2 4.5 2H12V6.5C12 7.327 12.673 8 13.5 8H18V21.5C18 21.775 17.775 22 17.5 22ZM13 2.706L17.294 7H13.5C13.225 7 13 6.775 13 6.5V2.706ZM18.561 6.853L13.147 1.439C12.868 1.16 12.481 1 12.087 1H4.5C3.673 1 3 1.673 3 2.5V21.5C3 22.327 3.673 23 4.5 23H17.5C18.327 23 19 22.327 19 21.5V7.913C19 7.513 18.844 7.136 18.561 6.853Z"
        fill="black"
        className={classes._fileIconColor2Labeled}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 19H21V11H7V19Z"
        fill="#24C69A"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58401 17.0186H8.47101L9.86601 14.9406L8.60101 12.9806H9.70801L10.459 14.2796L11.193 12.9806H12.288L11.046 14.9346L12.418 17.0186H11.317L10.447 15.5956L9.58401 17.0186ZM15.6426 17.0186H12.9776V12.9806H13.9146V16.2006H15.6426V17.0186ZM15.9766 16.3574L16.5806 15.7424C16.6556 15.8514 16.7556 15.9494 16.8826 16.0364C17.0086 16.1234 17.1476 16.1894 17.3006 16.2364C17.4536 16.2834 17.6066 16.3064 17.7606 16.3064C17.9716 16.3064 18.1366 16.2694 18.2576 16.1944C18.3776 16.1194 18.4386 16.0174 18.4386 15.8884C18.4386 15.7574 18.3756 15.6544 18.2516 15.5784C18.1276 15.5034 17.9226 15.4434 17.6366 15.3974C16.6466 15.2474 16.1516 14.8444 16.1516 14.1894C16.1516 13.9214 16.2166 13.6914 16.3486 13.4984C16.4806 13.3034 16.6636 13.1544 16.8996 13.0484C17.1346 12.9434 17.4056 12.8904 17.7096 12.8904C18.0526 12.8904 18.3716 12.9554 18.6666 13.0834C18.9626 13.2114 19.2026 13.3834 19.3866 13.6024L18.7996 14.2174C18.6716 14.0514 18.5136 13.9214 18.3256 13.8284C18.1366 13.7344 17.9336 13.6864 17.7156 13.6864C17.5316 13.6864 17.3806 13.7214 17.2666 13.7914C17.1516 13.8614 17.0936 13.9604 17.0936 14.0874C17.0936 14.2084 17.1526 14.3014 17.2696 14.3674C17.3856 14.4334 17.5926 14.4924 17.8906 14.5454C18.8876 14.7074 19.3866 15.1344 19.3866 15.8274C19.3866 16.0834 19.3196 16.3074 19.1836 16.5024C19.0476 16.6954 18.8576 16.8454 18.6106 16.9514C18.3646 17.0554 18.0736 17.1094 17.7386 17.1094C17.3766 17.1094 17.0416 17.0444 16.7356 16.9144C16.4286 16.7844 16.1756 16.5984 15.9766 16.3574Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 41H2.5C2.225 41 2 40.775 2 40.5V3.5C2 3.225 2.225 3 2.5 3H20V11.5C20 12.327 20.673 13 21.5 13H30V40.5C30 40.775 29.775 41 29.5 41ZM21 3.708L29.293 12H21.5C21.225 12 21 11.775 21 11.5V3.708ZM30.561 11.854L21.146 2.439C20.862 2.156 20.485 2 20.085 2H2.5C1.673 2 1 2.673 1 3.5V40.5C1 41.327 1.673 42 2.5 42H29.5C30.327 42 31 41.327 31 40.5V12.914C31 12.52 30.84 12.133 30.561 11.854Z"
        fill="black"
        className={classes._fileIconColor2Labeled}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 35H36V18H7V35Z"
        fill="#24C69A"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5635 30.4326H11.2435L13.9595 26.3346L11.4965 22.5676H12.8275L14.7185 25.6106L16.5785 22.5676H17.8655L15.4225 26.3576L18.1185 30.4326H16.7875L14.6745 27.0956L12.5635 30.4326ZM24.4316 30.4326H19.5916V22.5676H20.7246V29.4426H24.4316V30.4326ZM25.29 29.1572L26.07 28.3872C26.716 29.2012 27.537 29.6082 28.534 29.6082C29.1 29.6082 29.549 29.4852 29.882 29.2392C30.216 28.9942 30.383 28.6622 30.383 28.2442C30.383 27.8782 30.232 27.5912 29.932 27.3862C29.631 27.1802 29.129 27.0192 28.425 26.9022C27.428 26.7482 26.709 26.4992 26.269 26.1542C25.829 25.8092 25.608 25.3332 25.608 24.7232C25.608 24.2622 25.729 23.8562 25.972 23.5092C26.214 23.1602 26.549 22.8872 26.978 22.6882C27.407 22.4912 27.9 22.3912 28.458 22.3912C29.712 22.3912 30.73 22.8502 31.516 23.7662L30.768 24.5372C30.445 24.1482 30.097 23.8582 29.723 23.6682C29.349 23.4772 28.927 23.3822 28.458 23.3822C27.944 23.3822 27.533 23.4962 27.226 23.7232C26.918 23.9502 26.764 24.2622 26.764 24.6582C26.764 24.9812 26.904 25.2312 27.187 25.4112C27.47 25.5912 27.944 25.7362 28.611 25.8462C29.639 26.0072 30.383 26.2732 30.845 26.6432C31.307 27.0142 31.538 27.5322 31.538 28.2002C31.538 28.6772 31.411 29.0962 31.158 29.4602C30.905 29.8232 30.551 30.1042 30.097 30.3062C29.642 30.5092 29.117 30.6082 28.523 30.6082C27.204 30.6082 26.126 30.1252 25.29 29.1572Z"
        fill="white"
      />
    </svg>
  );
};
