export const demoFolder = {
  id: -1,
  name: '',
  uniqueViews: 0,
  timesFavorited: 0,
  createdByProfile: {
    imageUrl: '',
  },
  folderFields: {
    childrenCount: 0,
    recentContributors: [],
  },
  createdAt: new Date(),
  parentFolderId: '',
};
