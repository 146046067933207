import React from 'react';
import { classes } from './MoveEmptyState.st.css';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../../Services/AppSettings.service';

export const MoveEmptyState = () => {
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  return (
    <div className={classes.empty_state_wrapper}>
      <div
        className={
          classes.empty_state_content +
          ' ' +
          (isMobile ? classes.mobile_empty_state_content : ' ')
        }
      />
    </div>
  );
};
