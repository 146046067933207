export const SEARCH_BAR = 'Search bar';
export const FILE_SIZE_NUMBER_OF_ITEMS = 'File size / Number of items';
export const LAST_UPDATED = 'Last updated';
export const VIEWS_COUNTER = 'View counts';
export const FAVORITES = 'Favorites column';
export const FAVORITES_COUNTER = 'Favorite counts';
export const CONTRIBUTORS = 'Contributors';
export const FILE_SIZE_NUMBER_OF_ITEMS_KEY = 'showSizeOfFiles';
export const LAST_UPDATED_KEY = 'showLastModified';
export const VIEWS_COUNTER_KEY = 'showViewsCounter';
export const FAVORITES_COUNTER_KEY = 'showFavoritesCounter';
export const CONTRIBUTORS_KEY = 'showFileOwnerName';
export const SORT_BY_OPTIONS = {
  LAST_MODIFIED: 'LAST_MODIFIED',
  UNIQUE_VIEWS: 'UNIQUE_VIEWS',
  TIMES_FAVORITED: 'TIMES_FAVORITED',
  TYPE: 'TYPE',
  SIZE: 'SIZE',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
};

export const enum AppAlignment {
  RTL = 'rtl',
  LTR = 'ltr',
}
