import {
  getIsLoadingSubjects$,
  getIsSiteRolesRetrievedSubject$,
  getRolesSubject$,
  getSiteRolesSubject$,
  setIsLoadingSubjects$,
  setIsSiteRolesRetrievedSubject$,
  setRolesSubjects$,
  setSiteRolesSubjects$,
} from '../../Observables/advancedPermissions.observable';
import { ListMembersRequest } from '@wix/ambassador-file-sharing-v1-role/types';
import {
  Loading,
  ResponseStatus,
  SaveData,
} from '@wix/entity-advanced-permissions';
import { FileSharingService } from '../../Api/Api';
import { getSelectedFolder } from '../Search.service';
import { setSelectedFolder$ } from '../../Observables/SelectedFolder.observable';

let API = null;
export const setRoles = async (folderId: string) => {
  try {
    API ??= new FileSharingService();
    if (API) {
      const response = await API.getListRoles({
        libraryItemId: folderId,
        siteVisitors: true,
      });
      if (response?.data) {
        setRolesSubjects$(response.data);
      } else {
        resetRolesData();
      }
    }
  } catch (e) {
    console.log('error:', e);
  }
};

export const resetRolesData = () => {
  setRolesSubjects$({ roles: [], accessFlag: undefined });
  setSiteRolesSubjects$({});
  setIsSiteRolesRetrievedSubject$(false);
};

export const getRoles = () => {
  return getRolesSubject$();
};

export const setSiteRoles = async () => {
  try {
    API ??= new FileSharingService();
    if (API) {
      const response = await API.getListRoles({
        siteVisitors: true,
      });
      if (response?.data) {
        setSiteRolesSubjects$(response.data);
        setIsSiteRolesRetrievedSubject(true);
        setIsLoadingSubjects(Loading.Success);
        return response.data;
      } else {
        setIsLoadingSubjects(Loading.Error);
        resetRolesData();
      }
    }
  } catch (e) {
    console.log('error:', e);
  }
};

export const getSiteRoles = () => {
  return getSiteRolesSubject$();
};

export const setIsSiteRolesRetrievedSubject = (
  IsSiteRolesRetrieved: boolean,
) => {
  setIsSiteRolesRetrievedSubject$(IsSiteRolesRetrieved);
};

export const getIsSiteRolesRetrieved = () => {
  return getIsSiteRolesRetrievedSubject$();
};

export const getMembers = async ({ search, nextCursor, memberIds }: any) => {
  try {
    API ??= new FileSharingService();
    if (API) {
      const query: ListMembersRequest = {
        nicknamePart: encodeURIComponent(search || ''),
        memberIds: { ids: memberIds ? memberIds.split(',') : [] },
      };
      if (!(nextCursor === null || typeof nextCursor === 'undefined')) {
        try {
          query.paging = nextCursor;
        } catch (e) {}
      }
      return await API.getMembers(query);
    }
  } catch (e) {
    console.log('error:', e);
  }
};

export const setSavePermissionsLevel = async (savedData: SaveData) => {
  try {
    API ??= new FileSharingService();
    if (API) {
      if (savedData !== undefined) {
        const actions = savedData?.rolesChanged?.map(
          (role: { id: string; permissionId: string }) => {
            return {
              level: role.permissionId,
              roleId: role.id,
            };
          },
        );
        const query = {
          actions,
          libraryItemId: savedData.entityId,
          notifyUsers: savedData.notify,
          notifyMessage: savedData.notifyMessage,
          hide: savedData.isHidden,
        };

        const response = await API.updateRolesLevels(query);
        if (response?.data) {
          return {
            responseStatus: response.data?.availabilityStatus
              ? ResponseStatus.Success
              : ResponseStatus.Failed,
            availabilityStatus: response.data?.availabilityStatus,
          };
        }
      }
    }
    return { responseStatus: ResponseStatus.Failed };
  } catch (e) {
    console.log('error:', e);
    return { responseStatus: ResponseStatus.Failed };
  }
};

export const setIsLoadingSubjects = (loading: Loading) => {
  setIsLoadingSubjects$(loading);
};

export const getIsLoadingSubjects = () => {
  return getIsLoadingSubjects$();
};

export const updateAvailabilityAfterSavePermissions = async (
  id: string,
  availability: any,
) => {
  const selectedFolder: any = await getSelectedFolder();
  selectedFolder.libraryItems = selectedFolder?.libraryItems.map(
    (item: any) => {
      if (item.id === id) {
        if (item?.availability !== undefined) {
          item.availability = availability.availability;
        }
      }
      return item;
    },
  );
  setSelectedFolder$(JSON.parse(JSON.stringify(selectedFolder)));
};
