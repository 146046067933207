import React, { useEffect } from 'react';
import { Checkbox } from 'wix-ui-tpa';
import { st, classes } from './ItemCheckBox.st.css';
import { SELECTED_ITEMS_BEHAVIOR } from '../../../../../../../Constants/SelectedItems.Constants';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import {
  getSelectedItems,
  handleSelectedItemsChange,
} from '../../../../../../Services/SelectedItems.service';

const ItemCheckBox = (props: any) => {
  const [error1, selectedItems]: any = useObservable(getSelectedItems());
  const { item } = props;
  useEffect(() => {
    const checkBoxItem = document.getElementById('selectCheckbox');
    checkBoxItem
      ?.querySelector(':nth-child(1) > input')
      ?.setAttribute('tabIndex', '-1');
  }, [selectedItems]);
  const handleOnChange = () => {
    if (!selectedItems.includes(item.id)) {
      handleSelectedItemsChange(SELECTED_ITEMS_BEHAVIOR.ADD_ITEM, item.id);
    } else {
      handleSelectedItemsChange(SELECTED_ITEMS_BEHAVIOR.REMOVE_ITEM, item.id);
    }
  };
  const isSelected = selectedItems?.includes(item.id);
  const handleOnKeyDown = (event: any) => {
    const enterBarKey = 'Enter';
    if (event.key && event.key !== enterBarKey) {
      return;
    } else {
      handleOnChange();
    }
  };
  return (
    <div
      role="checkbox"
      id="selectCheckbox"
      aria-checked={isSelected || 'false'}
      tabIndex={0}
      aria-label={isSelected ? 'checkbox selected' : 'checkbox'}
      style={{ display: 'flex' }}
      onKeyDown={handleOnKeyDown}
    >
      {selectedItems ? (
        <Checkbox
          checked={isSelected}
          label=""
          onChange={handleOnChange}
          className={st(classes.CheckboxStyle)}
        />
      ) : null}
    </div>
  );
};

export default ItemCheckBox;
