import React, { useEffect, useState } from 'react';
import { TextField } from 'wix-ui-tpa';
import { setAddNewFolderMoveObservable } from '../../Services/Move/Move.service';
import { startMoving } from '../../Services/Move/MovingProccess';
import { st, classes } from './MoveWrapper.st.css';
import { setMoveLoader } from '../../Services/ItemsMove.service';
import { useTranslation } from '@wix/yoshi-flow-editor';

const MoveAddNewFolderOutSideClick = (props: any) => {
  const {
    moveAddFolderFolderName,
    moveAddFolderFolderStatus,
    handleIsRowClicked,
    isMobile,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <TextField
        className={st(classes.addMoveFolder, { isMobile })}
        autoFocus
        placeholder={t('app.widget.move.createNewFolder.input.placeHolder')}
        onBlur={() => {
          setTimeout(() => {
            if (
              moveAddFolderFolderName !== '' &&
              moveAddFolderFolderStatus === true
            ) {
              startMoving();
            }
          }, 4000);
          if (
            moveAddFolderFolderName !== '' &&
            moveAddFolderFolderStatus === true
          ) {
            setTimeout(() => {
              setMoveLoader(true);
            }, 500);
          }
        }}
        onChange={(e: any) => {
          setAddNewFolderMoveObservable({
            status: true,
            folderName: e.target.value,
          });
        }}
      />
    </>
  );
};

export default MoveAddNewFolderOutSideClick;
