import { FileSharingService } from '../Api/Api';

export const shareItem = async (itemId: any) => {
  let API: any = null;
  API = new FileSharingService();
  try {
    const { data } = await API.shareItem({ libraryItemId: itemId });
    return data?.url;
  } catch (error) {
    throw error;
  }
};
