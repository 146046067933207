import React from 'react';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import MobileHeaderBottomWrapper from './MobileHeaderBottomWrapper/MobileHeaderBottomWrapper';
import MobileHeaderButtons from './MobileHeaderButtons/MobileHeaderButtons';
import MobileHeaderTop from './MobileHeaderTop/MobileHeaderTop';
import { classes } from './MobileHeaderWrapper.st.css';
import { getSelectedItems } from '../../../../Services/SelectedItems.service';
import { TableColumns } from '../../../../../Constants/TableHeader.Constants';
import { getTableSettings } from '../../../../Services/TableSettings.service';

const MobileHeaderWrapper = () => {
  const [, selectedItems]: any = useObservable(getSelectedItems());
  const [, tableDisplaySettings]: any = useObservable(getTableSettings());

  const inSelectMode = selectedItems && selectedItems.length > 0;
  const displaySearch =
    tableDisplaySettings &&
    tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.SEARCH_BAR,
    );
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerTopWrapper}>
        <MobileHeaderTop />
      </div>

      {!inSelectMode ? (
        <div className={classes.mobileHeaderButtonsContainer}>
          <MobileHeaderButtons />
        </div>
      ) : (
        <div
          className={
            displaySearch && displaySearch?.value
              ? classes.buttonContainer
              : classes.buttonContainerNoSearch
          }
        ></div>
      )}
      <div>
        <MobileHeaderBottomWrapper />
      </div>
    </div>
  );
};

export default MobileHeaderWrapper;
