import { fileSharePermissionsAccessWindowOpened } from '@wix/bi-logger-wixlabs-ugc/v2';
import { fileSharePermissionsAccessWindowOpenedParams } from '@wix/bi-logger-wixlabs-ugc/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsAccessWindowOpened = (
  props: PermissionType<fileSharePermissionsAccessWindowOpenedParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsAccessWindowOpened({ ...params }));
};
