import React from 'react';
import { Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeft';
import { st, classes } from './SearchResultsText.st.css';
import { resetSearch } from '../../../../Services/Search.service';
import { getSelectedFolder } from '../../../../Services/SelectedFolder.service';
import { getAppSettings } from '../../../../Services/AppSettings.service';

const SearchResultsText = () => {
  const { t } = useTranslation();
  const [error, selectedFolder]: any = useObservable(getSelectedFolder());
  const [error2, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = getAppSettings !== undefined ? AppSettings?.isMobile : '';
  const resultsText =
    selectedFolder && selectedFolder.libraryItems
      ? t('app.widget.search.results.title', {
          resultsNum: selectedFolder.libraryItems.length,
        })
      : '';

  const handleOnClick = () => {
    resetSearch();
  };

  return (
    <div className={classes.searchResultsContainer}>
      <ChevronLeft className={classes.backIcon} onClick={handleOnClick} />
      <Text className={st(classes.resultsText, { isMobile })}>
        {resultsText}
      </Text>
    </div>
  );
};

export default SearchResultsText;
