import { fileSharePermissionsClickBmRolesLink } from '@wix/bi-logger-wixlabs-users/v2';
import { fileSharePermissionsClickBmRolesLinkParams } from '@wix/bi-logger-wixlabs-users/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsClickBmRolesLink = (
  props: PermissionType<fileSharePermissionsClickBmRolesLinkParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsClickBmRolesLink({ ...params }));
};
