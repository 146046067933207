import {
  getSort$,
  setSort$,
  getSortLastValue$,
} from '../Observables/Sort.observable';

export const getSort = () => {
  return getSort$();
};

export const setSort = (Toast: any) => {
  setSort$(Toast);
};

export const getSortLastValue = () => {
  return getSortLastValue$();
};
