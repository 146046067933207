import React, { forwardRef } from 'react';
import { MenuOptionMobile } from './MenuOptionMobile';
import { ITEM_MENU_OPTIONS } from './Constants';
import {
  getSelectedItems,
  handleSelectedItemsChange,
} from '../../../../../../Services/SelectedItems.service';
import { setOpenedModal } from '../../../../../../Services/Modals.service';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { downloadItems } from '../../../../../../Services/ItemsDownload.service';
import { setSelectedItems$ } from '../../../../../../Observables/SelectedItems.observable';
import {useTranslation} from "@wix/yoshi-flow-editor";

export const ItemActionsMenuMobile = forwardRef(
  ({ setOpen, handleOpenMove, ref }: any) => {
    const [error1, selectedItems]: any = useObservable(getSelectedItems());
    const {t} = useTranslation();
    const handleOpenModal = (type: string) => {
      setOpen && setOpen();
      setOpenedModal(type);
    };
    const handleOnClick = (type: string) => {
      switch (type) {
        case ITEM_MENU_OPTIONS.SELECT_ALL: {
          setOpen && setOpen();
          handleSelectedItemsChange(ITEM_MENU_OPTIONS.SELECT_ALL);
          break;
        }
        case ITEM_MENU_OPTIONS.DESELECT_ALL: {
          setOpen && setOpen();
          setSelectedItems$([]);
          break;
        }
        case ITEM_MENU_OPTIONS.DELETE: {
          handleOpenModal(ITEM_MENU_OPTIONS.DELETE);
          break;
        }
        case ITEM_MENU_OPTIONS.MOVE: {
          handleOpenMove && handleOpenMove();
          break;
        }
        case ITEM_MENU_OPTIONS.DOWNLOAD: {
          selectedItems && downloadItems(t);
          break;
        }
        default:
      }
    };
    return (
      <div ref={ref}>
        <MenuOptionMobile handleOnClick={handleOnClick} />
      </div>
    );
  },
);
