import { fileShareMembersSignupSettingsClickLink } from '@wix/bi-logger-wixlabs-users/v2';
import { fileShareMembersSignupSettingsClickLinkParams } from '@wix/bi-logger-wixlabs-users/v2/types';
import { PermissionType } from '../biService.types';

export const biFileShareMembersSignupSettingsClickLink = (
  props: PermissionType<fileShareMembersSignupSettingsClickLinkParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileShareMembersSignupSettingsClickLink({ ...params }));
};
