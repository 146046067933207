import { take, lastValueFrom } from 'rxjs';
import { SELECTED_ITEMS_BEHAVIOR } from '../../Constants/SelectedItems.Constants';
import { selectedFolderSubject$ } from '../Observables/SelectedFolder.observable';
import {
  addItemToSelectedItems$,
  getSelectedItems$,
  removeItemFromSelectedItems$,
  setSelectedItems$,
} from '../Observables/SelectedItems.observable';
import { isDownload } from './ItemsDownload.service';

export const getSelectedItems = () => {
  return getSelectedItems$();
};

export const setSelectedItems = (selectedItems: any) =>
  setSelectedItems$(selectedItems);

export const handleSelectedItemsChange = (Type: string, id?: string) => {
  switch (Type) {
    case SELECTED_ITEMS_BEHAVIOR.SELECT_ALL:
      selectAllItems();
      break;
    case SELECTED_ITEMS_BEHAVIOR.DESELECT_ALL:
      setSelectedItems$([]);
      break;
    case SELECTED_ITEMS_BEHAVIOR.ADD_ITEM:
      if (id) {
        addItemToSelectedItems$(id);
      }
      break;
    case SELECTED_ITEMS_BEHAVIOR.REMOVE_ITEM:
      if (id) {
        removeItemFromSelectedItems$(id);
      }
      break;
  }
};

const selectAllItems = async () => {
  const tempResult = selectedFolderSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = previousResult.libraryItems.map((item: any) => item.id);
  setSelectedItems$(newResult);
  await isDownload();
};
