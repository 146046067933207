import { fileSharePermissionsClose } from '@wix/bi-logger-wixlabs-users/v2';
import { fileSharePermissionsCloseParams } from '@wix/bi-logger-wixlabs-users/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsClose = (
  props: PermissionType<fileSharePermissionsCloseParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsClose({ ...params }));
};
