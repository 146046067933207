import { fileSharePermissionsOpen } from '@wix/bi-logger-wixlabs-users/v2';
import { fileSharePermissionsOpenParams } from '@wix/bi-logger-wixlabs-users/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsOpen = (
  props: PermissionType<fileSharePermissionsOpenParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsOpen({ ...params }));
};
