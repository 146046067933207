import React, { useCallback, useEffect, useState } from 'react';
import { ActionsMenuLayout } from 'wix-ui-tpa';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { getBreadCrumbs } from '../../../../../Services/BreadCrumbs.service';
import { setIsLoading } from '../../../../../Services/Loading.service';
import SetBreadcrumbsCorrectPath from '../SetBreadcrumbsCorrectPath';
import styles from './breadcrumbsMultiItems.css';

const BreadcrumbsMultiItems = (props: any) => {
  const [, breadCrumbs]: any = useObservable(getBreadCrumbs());
  const { handleIsMenuClicked } = props;
  const [state, setState] = useState({
    items: [],
  });

  const setCorrectItems = useCallback(() => {
    let tempArr: any;
    if (breadCrumbs && breadCrumbs.length > 3) {
      tempArr = JSON.parse(JSON.stringify(breadCrumbs));
      tempArr.shift();
      tempArr.splice(-1);
      setState((prev) => ({ ...prev, items: tempArr }));
    }
  }, [breadCrumbs]);

  const generateItems = () => {
    if (state.items && state.items.length > 0) {
      return state.items.map((item: any) => {
        return (
          <ActionsMenuLayout.Item
            onClick={() => {
              SetBreadcrumbsCorrectPath(item, breadCrumbs);
              handleIsMenuClicked(false);
              setIsLoading(true);
            }}
            content={item.value}
          />
        );
      });
    }
  };

  useEffect(() => {
    if (breadCrumbs && breadCrumbs.length > 3) {
      setCorrectItems();
    }
  }, [breadCrumbs, setCorrectItems]);

  return (
    <>
      {state.items && state.items.length > 0 ? (
        <ActionsMenuLayout className={styles.breadcrumbsMultiItemsContainer}>
          {generateItems()}
        </ActionsMenuLayout>
      ) : null}
    </>
  );
};

export default BreadcrumbsMultiItems;
