import React, { useEffect, useState } from 'react';
import { TextField } from 'wix-ui-tpa';
import { setAddNewFolderMoveObservable } from '../../Services/Move/Move.service';
import { startMoving } from '../../Services/Move/MovingProccess';
import { st, classes } from './MoveWrapper.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';

const MoveAddNewFolderRowClick = (props: any) => {
  const { movingToFolderId, movingToFolderName, handleIsRowClicked, isMobile } = props;
  const { t } = useTranslation();
  return (
    <>
      <TextField
        className={st(classes.addMoveFolder, {isMobile})}
        autoFocus
        placeholder={t('app.widget.move.createNewFolder.input.placeHolder')}
        onFocus={() => {
          startMoving(movingToFolderId, movingToFolderName);
        }}
        onChange={(e: any) => {
          setAddNewFolderMoveObservable({
            status: true,
            folderName: e.target.value,
          });
        }}
      />
    </>
  );
};

export default MoveAddNewFolderRowClick;
