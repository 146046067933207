import {
  getIsServerError$,
  setIsServerError$,
} from '../Observables/ServerError.observable';

export const setIsServerError = (error: any) => {
  setIsServerError$(error);
};

export const getIsServerError = () => {
  return getIsServerError$();
};
