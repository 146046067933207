import { BehaviorSubject } from 'rxjs';

export const moveFolderTree$ = new BehaviorSubject({});
export const moveLoader$ = new BehaviorSubject(false);

export const getMoveFolderTree$ = () => moveFolderTree$;

export const setMoveFolderTree$ = (moveData: any) =>
  moveFolderTree$.next(moveData);

export const getMoveLoader$ = () => moveLoader$;

export const setMoveLoader$ = (moveLoader: boolean) =>
  moveLoader$.next(moveLoader);
