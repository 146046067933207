import React, { FC } from 'react';
import {

  WidgetProps,
} from '@wix/yoshi-flow-editor';

import MySharedFileWidget from '../../FileShareOoi/Widget';
import { ControllerProps } from '../../FileShareOoi/Widget/ControllerProps';


export default MySharedFileWidget;
