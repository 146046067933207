import { FileSharingService } from '../Api/Api';
import { setOpenedModal } from './Modals.service';
import { MODAL_TYPES } from '../../Constants/Modals.Constants';
import { TOAST } from '../../Constants/Toast.Constants';
import { setToast } from './Toast.service';
import biService from '../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_REPORT } from '../BiEvents/Constants/BiConstants';

export const reportItems = async ({ item, reason }: any) => {
  let API: any = null;
  API = new FileSharingService();
  try {
    const response = await API.report({
      itemId: item?.id,
      reason,
    });
    setOpenedModal(MODAL_TYPES.REPORT_RESPONSE);
    biService(
      {
        file_id: item?.id,
        file_name: item?.name,
        file_owner: item?.createdByName,
        file_size: item?.fileFields
          ? item?.fileFields?.size
          : item?.folderFields?.childrenCount,
        file_type: item?.fileFields ? item?.fileFields?.extension : '',
        favorites_count: item?.timesFavorited,
        is_folder: !item?.fileFields,
        views_count: item?.uniqueViews,
        report_reason: reason,
      },
      BI_FILE_SHARE_WIDGET_REPORT,
    );
  } catch (e) {
    const error: any = e;
    setToast({
      skin: TOAST.error,
      content: error.message,
    });
    throw error;
  }
};
