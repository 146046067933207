import React from 'react';
import { classes } from './iconStyle.st.css';

export const VidTag = (props: any) => {
  const { isMobile, fileIconsColoredByType } = props;
  const className = fileIconsColoredByType ? '' : classes._fileIconColorLabeled;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 22H4.5C4.225 22 4 21.775 4 21.5V2.5C4 2.225 4.225 2 4.5 2H12V6.5C12 7.327 12.673 8 13.5 8H18V21.5C18 21.775 17.775 22 17.5 22ZM13 2.706L17.294 7H13.5C13.225 7 13 6.775 13 6.5V2.706ZM18.561 6.853L13.147 1.439C12.868 1.16 12.481 1 12.087 1H4.5C3.673 1 3 1.673 3 2.5V21.5C3 22.327 3.673 23 4.5 23H17.5C18.327 23 19 22.327 19 21.5V7.913C19 7.513 18.844 7.136 18.561 6.853Z"
        fill="black"
        className={classes._fileIconColor2Labeled}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 19H21V11H7V19Z"
        fill="#6544F9"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4219 17.0194H10.4959L8.98792 12.9814H10.0049L10.9869 15.8894L11.9529 12.9814H12.9299L11.4219 17.0194ZM13.534 17.0199H14.471V12.9819H13.534V17.0199ZM16.3008 16.2235H16.8038C17.1498 16.2235 17.4208 16.1135 17.6158 15.8925C17.8128 15.6725 17.9098 15.3735 17.9098 14.9975C17.9098 14.6245 17.8128 14.3285 17.6158 14.1085C17.4208 13.8885 17.1498 13.7785 16.8038 13.7785H16.3008V16.2235ZM15.3638 17.0195V12.9815H16.8038C17.2248 12.9815 17.5928 13.0675 17.9068 13.2395C18.2218 13.4105 18.4658 13.6475 18.6388 13.9505C18.8118 14.2535 18.8988 14.6025 18.8988 14.9975C18.8988 15.3935 18.8118 15.7425 18.6388 16.0455C18.4658 16.3485 18.2218 16.5865 17.9068 16.7595C17.5928 16.9325 17.2248 17.0195 16.8038 17.0195H15.3638Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 41H2.5C2.225 41 2 40.775 2 40.5V3.5C2 3.225 2.225 3 2.5 3H20V11.5C20 12.327 20.673 13 21.5 13H30V40.5C30 40.775 29.775 41 29.5 41ZM21 3.708L29.293 12H21.5C21.225 12 21 11.775 21 11.5V3.708ZM30.561 11.854L21.146 2.439C20.862 2.156 20.485 2 20.085 2H2.5C1.673 2 1 2.673 1 3.5V40.5C1 41.327 1.673 42 2.5 42H29.5C30.327 42 31 41.327 31 40.5V12.914C31 12.52 30.84 12.133 30.561 11.854Z"
        fill="black"
        className={classes._fileIconColor2Labeled}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 35H36V18H7V35Z"
        fill="#6544F9"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3037 30.4326H15.1377L12.2447 22.5676H13.4657L15.7417 29.1456L18.0197 22.5676H19.1857L16.3037 30.4326ZM20.703 30.433H21.837V22.568H20.703V30.433ZM25.1475 29.4424H26.6105C27.1745 29.4424 27.6615 29.3194 28.0675 29.0744C28.4745 28.8284 28.7885 28.4834 29.0085 28.0404C29.2285 27.5964 29.3385 27.0814 29.3385 26.4944C29.3385 25.9084 29.2285 25.3944 29.0085 24.9544C28.7885 24.5144 28.4745 24.1714 28.0675 23.9254C27.6615 23.6804 27.1745 23.5574 26.6105 23.5574H25.1475V29.4424ZM24.0145 30.4324V22.5674H26.6105C27.3875 22.5674 28.0695 22.7364 28.6565 23.0734C29.2435 23.4114 29.7015 23.8754 30.0315 24.4644C30.3615 25.0554 30.5265 25.7314 30.5265 26.4944C30.5265 27.2644 30.3615 27.9444 30.0315 28.5354C29.7015 29.1254 29.2435 29.5884 28.6565 29.9264C28.0695 30.2634 27.3875 30.4324 26.6105 30.4324H24.0145Z"
        fill="white"
      />
    </svg>
  );
};
