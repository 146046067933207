import React from 'react';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { st, classes } from './FooterWrapper.st.css';
import Plus from 'wix-ui-icons-common/on-stage/PlusSmall';
import { useTranslation } from '@wix/yoshi-flow-editor';

const FooterWrapper = ({
  handleMoveClick,
  disabled,
  isLoading,
  handleNewFolder,
  isMobile,
  showFolderButton,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footerButtons}>
        {!isLoading ? (
          <TextButton
            disabled={!showFolderButton}
            className={st(classes.textStyle, { isMobile })}
            prefixIcon={<Plus />}
            priority={TextButtonPriority.secondary}
            onClick={handleNewFolder}
          >
            {t('app.widget.move.button.new.folder')}
          </TextButton>
        ) : (
          <></>
        )}
        <TextButton
          className={
            st(classes.moveHereTextStyle, { isMobile }) + ' ' + classes.moveButton
          }
          priority={TextButtonPriority.secondary}
          disabled={isLoading ? isLoading : disabled}
          onClick={handleMoveClick}
        >
          {t('app.widget.move.button.move.here')}
        </TextButton>
      </div>
    </div>
  );
};

export default FooterWrapper;
