import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

export const rootFolderSubject$ = new BehaviorSubject(
  (undefined as unknown) as any,
);

export const getRootFolder$ = () => rootFolderSubject$;
export const setRootFolder$ = (FolderInfo: any) =>
  rootFolderSubject$.next(FolderInfo);
