import { UPLOAD_GLITCH } from '../../../Constants/UploadTosts.Constants';
import { UploadGlitchErrorNotifications } from './UploadGlitchErrorNotifications';

export const checkTotalAmount = (t: any, files: any) => {
  if (files.length > 100) {
    UploadGlitchErrorNotifications(
      t,
      UPLOAD_GLITCH.FILEUPLOAD_MULTIPLE_LOT,
      files.length,
    );
  }
};
