import {
  ITEM_MENU_OPTIONS,
  STATUS,
} from '../../../../../../../../../Constants/ItemMenu.Constant';
import { TFunction } from '@wix/yoshi-flow-editor';

export const getViewDownloadOptions = (
  t: TFunction,
  isMembersArea: boolean = false,
) => {
  const selectOption = t('app.widget.table.item.menu.select');
  const downloadOption = t('app.widget.table.item.menu.download');
  const shareOption = t('app.widget.table.item.menu.share');
  const firstOption = [
    isMembersArea
      ? {
          type: ITEM_MENU_OPTIONS.OPEN_IN_LIBRARY,
          content: t('app.widget.table.item.menu.openInLibrary'),
        }
      : {
          type: ITEM_MENU_OPTIONS.SELECT,
          content: selectOption,
        },
  ];
  const menuOptions = [
    ...firstOption,
    {
      type: ITEM_MENU_OPTIONS.DOWNLOAD,
      content: downloadOption,
    },
    { type: ITEM_MENU_OPTIONS.SHARE, content: shareOption },
  ];

  return menuOptions;
};

export const getManageOptions = (
  t: TFunction,
  showPermissions: boolean,
  isMembersArea: boolean = false,
) => {
  const renameOption = t('app.widget.table.item.menu.rename');
  const moveOption = t('app.widget.table.item.menu.move');
  const deleteOption = t('app.widget.table.item.menu.delete');
  const permissionsOption = t('app.widget.table.item.menu.permissions');
  const showPermissionOption =
    showPermissions && !isMembersArea
      ? [
          {
            type: ITEM_MENU_OPTIONS.MANAGE_PERMISSIONS,
            content: permissionsOption,
          },
        ]
      : [];
  const movePermissionOption = isMembersArea
    ? []
    : [
        {
          type: ITEM_MENU_OPTIONS.MOVE,
          content: moveOption,
        },
      ];
  return [
    { type: ITEM_MENU_OPTIONS.RENAME, content: renameOption },
    ...showPermissionOption,
    ...movePermissionOption,
    { type: ITEM_MENU_OPTIONS.DELETE, content: deleteOption },
  ];
};

export const getReportOption = (t: TFunction) => {
  const reportOption = t('app.widget.table.item.menu.report');

  return [
    {
      type: ITEM_MENU_OPTIONS.REPORT,
      content: reportOption,
    },
  ];
};

export const getActionAuthStatus = (item: any, type: string) =>
  item.authorizeActions?.find((itemData: any) => itemData.action === type)
    .status === STATUS.ALLOWED;
