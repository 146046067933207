import React, { useEffect, useState } from 'react';
import { Button, ButtonPriority, Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from './DeleteWrapper.st.css';
import { ITEMTYPE } from '../../../../Constants/ItemType.Constants';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../Services/SelectedItems.service';
import { getSelectedFolderId } from '../../../Services/SelectedFolder.service';
import { getLastSelectedFolderLibraryItems } from '../../DeleteNotificationHelper';
import { setIsLoading } from '../../../Services/Loading.service';
import { deleteItemsFromFolder } from '../../../Services/ItemsDelete.service';
import { deleteFileOrFolderBi } from '../../../BiEvents/SharedBiEventsCallBack/SharedBiEventsCallBack';
import { getSettingsData } from '../../../Services/AppSettings.service';

const DeleteWrapper = ({ currentItem, handleOnClose, isMobile }: any) => {
  const { t }: { t: any } = useTranslation();
  const [state, setState] = useState({
    selectedFolderLibraryItems: { libraryItems: [] },
  });
  const [, selectedItems]: any = useObservable(getSelectedItems());
  const [, selectedFolderId]: any = useObservable(getSelectedFolderId());
  const [, settingsData]: any = useObservable(getSettingsData());
  const borderRadius =
    settingsData?.settings?.designSettings?.buttonCornerRadius ?? 0;

  useEffect(() => {
    const getSelectedFolderLibraryItems = async () => {
      if (!currentItem) {
        const selectedFolderLibraryItems =
          await getLastSelectedFolderLibraryItems();
        setState({ selectedFolderLibraryItems });
      }
    };
    getSelectedFolderLibraryItems();
    actionMenuDeleteBi('click');
  }, [selectedItems]);
  const actionMenuDeleteBi = (description: string) => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedItemsData = getSelectedItemsData(
        state?.selectedFolderLibraryItems,
        selectedItems,
      );
      selectedItemsData?.map((item: any) => {
        deleteFileOrFolderBi(item, description, selectedItems.length);
      });
    } else if (currentItem && description !== 'click') {
      deleteFileOrFolderBi(currentItem, description, 1);
    }
  };
  const primaryButtonHandleOnClick = () => {
    setIsLoading(true);
    const libraryItemIds =
      selectedItems && selectedItems.length > 0
        ? selectedItems
        : [currentItem?.id];
    const parentFolderId =
      selectedItems && selectedItems.length > 0
        ? selectedFolderId
        : currentItem?.parentFolderId;
    deleteItemsFromFolder({
      libraryItemIds,
      parentFolderId,
      t,
      biCallBack: actionMenuDeleteBi,
    });
  };
  const secondaryButtonHandleOnClick = () => {
    handleOnClose && handleOnClose();
  };
  const getSelectedItemsData = (previousResult: any, libraryItemIds: any) => {
    const selectedItemsData = previousResult?.libraryItems.filter((item: any) =>
      libraryItemIds.includes(item.id),
    );
    return selectedItemsData;
  };

  const getContentForMultiSelect = (selectedItemsData: any[]) => {
    const foldersArray: any = [];
    const filesArray: any = [];
    let multiTitle = '';
    let multiSubTitle = '';
    selectedItemsData.forEach((item: any) => {
      if (ITEMTYPE.FILE in item) {
        filesArray.push(item);
      } else {
        foldersArray.push(item);
      }
    });
    const fileArrayLength = filesArray.length;
    const folderArrayLength = foldersArray.length;

    if (folderArrayLength === 1 && fileArrayLength === 1) {
      multiTitle = t('app.widget.delete.multiselect_folder_file_title');
      multiSubTitle = t(
        'app.widget.delete.multi_delete_folder_file_warning_message',
        {
          0: foldersArray[0].name,
        },
      );
    } else if (folderArrayLength > 0 && fileArrayLength === 0) {
      multiTitle = t('app.widget.delete.multiselect_folders_title', {
        0: folderArrayLength,
      });
      multiSubTitle = t(
        'app.widget.delete.multi_delete_folders_warning_message',
        { 0: folderArrayLength },
      );
    } else if (folderArrayLength === 0 && fileArrayLength > 0) {
      multiTitle = t('app.widget.delete.multiselect_files_title', {
        0: fileArrayLength,
      });
      multiSubTitle = t(
        'app.widget.delete.multi_delete_files_warning_message',
        { 0: fileArrayLength },
      );
    } else if (folderArrayLength > 0 && fileArrayLength === 1) {
      multiTitle = t('app.widget.delete.multiselect_items_title');
      multiSubTitle = t(
        'app.widget.delete.multi_delete_folders_file_warning_message',
        {
          0: folderArrayLength,
        },
      );
    } else if (folderArrayLength === 1 && fileArrayLength > 0) {
      multiTitle = t('app.widget.delete.multiselect_items_title');
      multiSubTitle = t(
        'app.widget.delete.multi_delete_folder_files_warning_message',
        { 0: foldersArray[0].name, 1: fileArrayLength },
      );
    } else if (folderArrayLength > 0 && fileArrayLength > 0) {
      multiTitle = t('app.widget.delete.multiselect_items_title');
      multiSubTitle = t(
        'app.widget.delete.multi_delete_items_warning_message',
        {
          0: folderArrayLength,
          1: fileArrayLength,
        },
      );
    }

    return { multiTitle, multiSubTitle };
  };
  const deleteModalData = () => {
    let title = '';
    let warningMessage = '';
    const { selectedFolderLibraryItems } = state;
    if (
      selectedItems === undefined &&
      selectedFolderLibraryItems &&
      selectedFolderLibraryItems?.libraryItems?.length >= 0
    ) {
      return {
        title,
        warningMessage,
      };
    }
    const selectedItemsData = getSelectedItemsData(
      selectedFolderLibraryItems,
      selectedItems,
    );
    if (selectedItemsData && selectedItemsData?.length > 0) {
      if (selectedItemsData && selectedItemsData?.length === 1) {
        title =
          ITEMTYPE.FILE in selectedItemsData[0]
            ? t('app.widget.delete.file.title')
            : t('app.widget.delete.folder.title');
        warningMessage =
          ITEMTYPE.FILE in selectedItemsData[0]
            ? t('app.widget.delete.file.warning.message')
            : t('app.widget.delete.folder.warning.message');
      } else {
        const { multiTitle, multiSubTitle } =
          getContentForMultiSelect(selectedItemsData);
        title = multiTitle;
        warningMessage = multiSubTitle;
      }
    } else {
      title =
        ITEMTYPE.FILE in currentItem
          ? t('app.widget.delete.file.title')
          : t('app.widget.delete.folder.title');
      warningMessage =
        ITEMTYPE.FILE in currentItem
          ? t('app.widget.delete.file.warning.message')
          : t('app.widget.delete.folder.warning.message');
    }
    return {
      warningMessage,
      title,
    };
  };
  const { title, warningMessage } = deleteModalData();
  const secondaryButtonText = t('app.widget.delete.folder.secondaryButton');
  const primaryButtonText = t('app.widget.delete.folder.primaryButton');
  return (
    <div className={classes.DeleteWrapperContainer}>
      <div className={classes.modalContent}>
        <Text className={st(classes.modalTitle, { isMobile })}>{title}</Text>
        <div className={classes.textContainer}>
          <Text className={st(classes.modalWarningContent, { isMobile })}>
            <div>{warningMessage}</div>
          </Text>
        </div>
        <div className={isMobile ? classes.mobileButtonsWrapper : ''}>
          <Button
            upgrade
            priority={ButtonPriority.basicSecondary}
            className={st(classes.secondaryButtonContainer, { isMobile })}
            style={{ borderRadius }}
            onClick={secondaryButtonHandleOnClick}
          >
            {secondaryButtonText}
          </Button>
          <Button
            upgrade
            className={st(classes.primaryButtonContainer, { isMobile })}
            style={{ borderRadius }}
            onClick={primaryButtonHandleOnClick}
          >
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteWrapper;
