import {
  getAddNewFolderMoveObservable$,
  getMoveObservable$,
  moveObservable$,
  setAddNewFolderMoveObservable$,
  setMoveObservable$,
} from '../../Observables/Move.observable';
import React from 'react';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { FileSharingService } from '../../Api/Api';
import { rootFolderSubject$ } from '../../Observables/RootFolder.observable';
import {
  selectedFolderIdSubject$,
  selectedFolderSubject$,
} from '../../Observables/SelectedFolder.observable';
import { setMoveLoader } from '../ItemsMove.service';
import { setMoveLoader$ } from '../../Observables/ItemsMove.observable';
import {
  getCurrentItemHelper,
  getSelectedItemsHelper,
} from '../../Shared/ItemsHelpers';
import { setIsLoading } from '../Loading.service';
import {
  setSelectedFolder,
  setSelectedFolderId,
} from '../SelectedFolder.service';
import {
  getCurrentSelectedId,
  setDisableButtonsStatus,
  setSearchSubject,
} from '../Search.service';
import { setToast } from '../Toast.service';
import { TOAST } from '../../../Constants/Toast.Constants';
import { Trans } from '@wix/yoshi-flow-editor';
import { TextButton } from 'wix-ui-tpa';
import { addToBreadCrumbs } from '../BreadCrumbs.service';
import { moveFileClickBi } from '../../BiEvents/SharedBiEventsCallBack/SharedBiEventsCallBack';

const mapData = new Map();

export const filterItems = async (selectedItemsIds: any) => {
  const tempResult = selectedFolderSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = JSON.parse(JSON.stringify(previousResult));
  newResult.libraryItems = newResult.libraryItems.filter((item: any) =>
    selectedItemsIds.includes(item.id),
  );
  return newResult.libraryItems;
};

export const moveItems = async ({ newParentFolderId, destFolder, t }: any) => {
  try {
    setIsLoading(true);
    const selectedItemsIds: any = await getSelectedItemsHelper();
    const selectedItems: any = await filterItems(selectedItemsIds);
    const currentItem: any = await getCurrentItemHelper();
    const isMultiMove = selectedItems.length > 0;

    const query: any = { actions: [] };
    if (!isMultiMove) {
      query.actions = [
        {
          libraryItemId: currentItem.id,
          newParentFolderId,
          currentParentFolderId: currentItem.parentFolderId,
        },
      ];
    } else {
      const filteredSelectedItems = selectedItems.map((item: any) => {
        return {
          libraryItemId: item.id,
          newParentFolderId,
          currentParentFolderId: item.parentFolderId,
        };
      });
      query.actions = [...filteredSelectedItems];
    }
    let API: any = null;
    API = new FileSharingService();
    const res = await API.moveItems(query);
    if (res?.data) {
      const idsToRemove =
        selectedItemsIds?.length > 0 ? selectedItemsIds : [currentItem?.id];
      setSelectedFolder(await getCurrentSelectedId(), idsToRemove);
      const isPlural = selectedItems.length > 1;
      const values = isPlural
        ? { 0: destFolder?.name }
        : {
            0: isMultiMove ? selectedItems[0]?.name : currentItem?.name,
            1: destFolder?.name,
          };
      const toastContent = moveFolderLinkedText({
        key: `app.widget.move.folder${
          isPlural ? 's' : ''
        }.success.notification`,
        values,
        destFolder,
      });
      setToast({
        skin: TOAST.success,
        content: toastContent,
      });
      if (selectedItems?.length > 0) {
        selectedItems?.map((item: any) => {
          moveFileClickBi(item, 'moved', selectedItems?.length);
        });
      } else {
        moveFileClickBi(currentItem, 'moved', 1);
      }
    } else {
      if (res?.message) {
        setToast({
          skin: TOAST.error,
          content: t('app.widget.notification.move.error'),
        });
        setIsLoading(false);
      }
    }
  } catch (e) {
    setIsLoading(false);
    const error: any = e;
    setToast({
      skin: TOAST.error,
      content: error.message,
    });
    throw error;
  }
};

const getParentIdOfSelectedFolder = async () => {
  const tempResult = selectedFolderIdSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const selectedFolderId = await lastValueFrom(lastTempResult);
  return {
    selectedFolderId,
    parentFolderId: mapData.get(selectedFolderId).parent?.parentId,
    parentFolderName: mapData.get(selectedFolderId).parent?.name,
    currentFolderName: mapData.get(selectedFolderId).name,
  };
};
export const moveService = async () => {
  setMoveLoader(true);
  let API: any = null;
  API = new FileSharingService();
  const tempResult = rootFolderSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const rootFolderId = await lastValueFrom(lastTempResult);

  const libraryItems = await API.getFolderTree({
    rootLibraryItemId: rootFolderId?.id,
  });
  mapFolderTree(libraryItems?.data?.folderTree, '', '');
  const {
    selectedFolderId,
    parentFolderId,
    parentFolderName,
    currentFolderName,
  } = await getParentIdOfSelectedFolder();
  setMoveObservable({
    FolderTreeMap: mapData,
    parentFolderId,
    parentFolderName,
    currentFolderId: selectedFolderId,
    currentFolderName,
    currentSubFolders: mapData.get(selectedFolderId).subfolders,
  });
  setMoveLoader(false);
};
const mapFolderTree = (data: any, parent: any, parentFolderName: any) => {
  if (!data || !data.subfolders) {
    return;
  } else {
    data.subfolders.forEach((childData: any) => {
      if (childData.subfolders) {
        mapFolderTree(childData, data.folderId, data.name);
      }
    });
    mapData.set(data.folderId, {
      ...data,
      parent: { parentId: parent, name: parentFolderName },
    });
  }
};

export const setMoveObservable = (moveObservable: any) => {
  setMoveObservable$(moveObservable);
};

export const getMoveObservable = () => {
  return getMoveObservable$();
};

export const setAddNewFolderMoveObservable = (moveObservable: any) => {
  setAddNewFolderMoveObservable$(moveObservable);
};

export const getAddNewFolderMoveObservable = () => {
  return getAddNewFolderMoveObservable$();
};
const moveFolderLinkedText = ({
  key,

  destFolder,
  values,
}: any) => (
  <Trans
    i18nKey={key}
    values={values}
    components={[
      <TextButton
        style={{ color: 'white' }}
        onClick={() => {
          handleChangeFolder(destFolder);
        }}
      ></TextButton>,
    ]}
  ></Trans>
);
const handleChangeFolder = (receivedItem: any) => {
  const obj = {
    value:
      receivedItem?.name?.length > 20
        ? `${receivedItem?.name.substr(0, 17)}...`
        : receivedItem?.name,
    id: receivedItem.id,
  };
  addToBreadCrumbs(obj);
  setSelectedFolderId(receivedItem?.id);
  setSearchSubject({ callApi: false, value: '' });
  setDisableButtonsStatus(false);
  setIsLoading(true);
};
