import { BehaviorSubject } from 'rxjs';

export const moveObservable$ = new BehaviorSubject({
  FolderTreeMap: new Map(),
  parentFolderId: '',
  parentFolderName: '',
  currentFolderId: '',
  currentFolderName: '',
  currentSubFolders: [],
});

export const addNewFolderMoveObservable$ = new BehaviorSubject({
  status: false,
  folderName: '',
});

export const getMoveObservable$ = () => moveObservable$;

export const setMoveObservable$ = (moveObservable: any) =>
  moveObservable$.next(moveObservable);

export const getAddNewFolderMoveObservable$ = () => addNewFolderMoveObservable$;

export const setAddNewFolderMoveObservable$ = (newMoveObservable: any) =>
  addNewFolderMoveObservable$.next(newMoveObservable);
