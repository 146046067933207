import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from '../../../../../../Shared/SharedText.st.css';
import { classes as lastUpdatedClasses } from './ItemLastUpdated.st.css';
import { Text } from 'wix-ui-tpa';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';

const ItemLastUpdated = (props: any) => {
  const { item } = props;
  const [error3, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const isMembersArea = AppSettings?.isMembersArea ?? false;

  const { t }: { t: any } = useTranslation();
  const lastUpdatedText = t('app.widget.table.content.LastUpdated', {
    view:
      ITEMTYPE.FOLDER in item && item.folderFields.lastModified
        ? new Date(item?.folderFields?.lastModified)
        : new Date(item?.createdAt),
  });

  return (
    <div
      className={
        isMobile === true
          ? lastUpdatedClasses.ItemLastUpdatedContainerMobile
          : lastUpdatedClasses.ItemLastUpdatedContainer
      }
      title={lastUpdatedText}
    >
      <Text
        className={`${st(classes._textColor, { isMobile, isMembersArea })} ${
          classes.contentEllipsis
        }`}
      >
        {lastUpdatedText}
      </Text>
    </div>
  );
};

export default ItemLastUpdated;
