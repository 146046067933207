import React, { useState } from 'react';
import { Popover, TextButton } from 'wix-ui-tpa';
import BreadcrumbsMultiItems from './BreadcrumbsMultiItems';
import More from 'wix-ui-icons-common/on-stage/More';

const BreadcumbsMulti = () => {
  const [state, setState] = useState({
    isMultiClicked: false,
  });

  const handleIsMenuClicked = (isMultiClicked: boolean) => {
    setState(prev => ({ ...prev, isMultiClicked }));
  };

  return (
    <div>
      <Popover
        placement="bottom"
        showArrow={false}
        shown={state.isMultiClicked}
        onClickOutside={() => handleIsMenuClicked(false)}
      >
        <Popover.Element>
          <TextButton
            prefixIcon={<More style={{ transform: `rotate(90deg)` }} />}
            onClick={() => {
              handleIsMenuClicked(!state.isMultiClicked);
            }}
          />
        </Popover.Element>
        <Popover.Content>
          <BreadcrumbsMultiItems handleIsMenuClicked={handleIsMenuClicked} />
        </Popover.Content>
      </Popover>
    </div>
  );
};

export default BreadcumbsMulti;
