import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Status } from '../../../../Constants/Permissions';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedFolder } from '../../../Services/SelectedFolder.service';
import EmptyStates from '../EmptyStates';
import {getRootFolder} from "../../../Services/RootFolder.service";

const EmptyLibrary = () => {
  const { t } = useTranslation();
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const uploadFilePermissions =
    selectedFolder?.folderAuthorizedActions?.[0] || {};
  const isUploadFileDisabled =
    rootFolder === undefined && selectedFolder === undefined;

  const showFileButton =
    selectedFolder !== undefined &&
    selectedFolder.folderAuthorizedActions?.[0]?.status === Status.ALLOWED;
  const text1 = showFileButton
    ? t('app.widget.emptyState.library.title')
    : t('app.widget.emptyState.library.noPermissions.title');
  const text2 = showFileButton
    ? t('app.widget.emptyState.library.subTitle')
    : t('app.widget.emptyState.library.noPermissions.subTitle');
  return (
    <EmptyStates
      title={text1}
      subTitle={text2}
      showFileButton={showFileButton}
      uploadFilePermissions={uploadFilePermissions}
      isUploadFileDisabled={isUploadFileDisabled}
    />
  );
};

export default EmptyLibrary;
