import React from "react";
import { FileIcon } from '@wix/ambassador-file-sharing-v1-settings/types';

import { FolderFill } from "../../icons/Folder_Fill";
import { FolderOutline } from "../../icons/Folder_Outline";

export const getFolderIcon = (folderIconStyle: FileIcon, isMobile: boolean) => {
  return folderIconStyle === FileIcon.CLASSIC ? (
    <FolderFill isMobile={isMobile} />
  ) : (
    <FolderOutline folderIconStyle={folderIconStyle} isMobile={isMobile} />
  );
};
