import React, { useEffect, useState, useCallback } from 'react';
import { FileIcon } from '@wix/ambassador-file-sharing-v1-settings/types';

import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedFolderId } from '../../../../../../Services/SelectedFolder.service';
import { classes, st } from './skeletonItem.st.css';
import {
  st as stSharedDivider,
  classes as sharedDividerClasses,
} from '../../../../../../Shared/SharedDivider.st.css';
import {
  st as stSharedText,
  classes as sharedTextClasses,
} from '../../../../../../Shared/SharedText.st.css';
import {
  st as stIconToggle,
  classes as iconToggleClasses,
} from '../../Item/ItemFavorites/ItemFavorites.st.css';
import { classes as iconClasses } from '../../Item/ItemActions/ItemActions.st.css';
import More from 'wix-ui-icons-common/on-stage/More';
import {
  Avatar,
  AvatarSize,
  Divider,
  IconButton,
  IconToggle,
  Text,
  TextField,
  TextFieldTheme,
} from 'wix-ui-tpa';
import { demoFolder } from './SkeletonItemDemo';
import { useTranslation } from '@wix/yoshi-flow-editor';
import Favorite from 'wix-ui-icons-common/on-stage/Favorite';
import { AddFolderStatus } from '../../../../../../Observables/AddNewFolder.observable';
import {
  addFolderService,
  setAddFolderStatus,
} from '../../../../../../Services/AddNewFolder.service';
import { getTableSettings } from '../../../../../../Services/TableSettings.service';
import { TableColumns } from '../../../../../../../Constants/TableHeader.Constants';
import {
  getAppSettings,
  getSettingsData,
} from '../../../../../../Services/AppSettings.service';
import { getFolderIcon } from '../../../../../../Shared/FileAndFolderIcon/getFolderIcon';
import { MobileSkeleton } from '../../MobileSkeleton';

const SkeletonItem = ({ folderName, setFolderName }: any) => {
  const [, selectedFolderId] = useObservable(getSelectedFolderId());
  const [, tableDisplaySettings]: any = useObservable(getTableSettings());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const dividerWidth =
    settingsData?.settings?.designSettings?.dividerWidth ?? 1;
  const isMobile: boolean =
    appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const [displaySettings, setDisplaySettings] = useState({
    lastUpdated: false,
    viewCounter: false,
    favorites: false,
    favoritesCounter: false,
    contributors: false,
  });

  const { t } = useTranslation();

  const cancelNewFolder = () => {
    changeAddFolderState(AddFolderStatus.CLOSED);
  };

  const handleDisplaySettings = useCallback(() => {
    const showFavorites = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.FAVORITES,
    );
    const showFavoritesCounter = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.FAVORITES_COUNTER,
    );
    const showLastUpdated = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.LAST_UPDATED,
    );
    const showViewCounter = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.VIEWS_COUNTER,
    );
    const showContributors = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.CONTRIBUTORS,
    );

    setDisplaySettings({
      lastUpdated: showLastUpdated && showLastUpdated.value,
      viewCounter: showViewCounter && showViewCounter.value,
      favorites: showFavorites && showFavorites.value,
      favoritesCounter: showFavoritesCounter && showFavoritesCounter.value,
      contributors: showContributors && showContributors.value,
    });
  }, [tableDisplaySettings]);

  useEffect(() => {
    if (tableDisplaySettings && tableDisplaySettings.length > 0) {
      handleDisplaySettings();
    }
  }, [handleDisplaySettings, tableDisplaySettings]);

  const saveNewFolder = () => {
    if (selectedFolderId && folderName) {
      const demoFolderTemp: any = JSON.parse(JSON.stringify(demoFolder));
      demoFolderTemp.name = folderName;
      demoFolderTemp.createdAt = new Date();
      demoFolderTemp.parentFolderId = selectedFolderId;
      addFolderService(selectedFolderId, demoFolderTemp, t);
    }
  };
  const onInputKeydown = (event: any) => {
    if (event?.keyCode === 27) {
      cancelNewFolder();
    }
    if (event?.keyCode === 13) {
      saveNewFolder();
    }
  };

  const onInputBlur = () => {
    saveNewFolder();
  };

  const changeAddFolderState = (folderStatus: AddFolderStatus) => {
    setAddFolderStatus(folderStatus);
  };

  const fileIconStyle: FileIcon =
    settingsData?.settings?.designSettings?.fileIcon;
  return (
    <>
      {!isMobile && (
        <Divider
          className={stSharedDivider(
            sharedDividerClasses.dividerStyle,
            {
              isEditorX,
              isMobile,
            },
            sharedDividerClasses[`dividerStyle${dividerWidth}`],
          )}
        />
      )}
      <div className={st(classes.skeletonContainer, { isEditorX, isMobile })}>
        {!isMobile && <div className={classes.checkBoxContainer} />}
        <div
          className={
            classes.nameContainer +
            ' ' +
            (isMobile ? classes.itemNameContainerMobileView : '')
          }
        >
          <div className={classes.folderAndTextFieldOuterContainer}>
            <div
              className={
                classes.folderAndTextFieldInnerContainer +
                ' ' +
                (isMobile
                  ? classes.folderAndTextFieldInnerContainerMobileView
                  : '')
              }
            >
              {getFolderIcon(fileIconStyle, isMobile)}
              <TextField
                maxLength={100}
                theme={TextFieldTheme.Line}
                inputClassName={
                  classes.inputClassName +
                  ' ' +
                  (isMobile ? classes.inputClassNameMobileView : '')
                }
                onBlur={onInputBlur}
                onKeyDown={onInputKeydown}
                className={st(classes.nameInput, { isMobile })}
                autoFocus
                value={folderName}
                onChange={(value) => {
                  setFolderName(value.target.value);
                }}
              />
            </div>
            {isMobile && (
              <div className={classes.moreActionsContainer}>
                <IconButton
                  className={iconClasses.iconButton}
                  icon={<More />}
                />
              </div>
            )}
          </div>
          <div>
            {isMobile !== undefined && isMobile === true && (
              <MobileSkeleton t={t} isMobile={isMobile} />
            )}
          </div>
        </div>
        {displaySettings.lastUpdated && isMobile === false && (
          <div className={classes.lastUpdatedContainer}>
            <Text
              className={stSharedText(sharedTextClasses._textColor, {
                isMobile: false,
              })}
            >
              {t('app.widget.table.content.LastUpdated', {
                view: new Date(),
              })}
            </Text>
          </div>
        )}
        {displaySettings.viewCounter && isMobile === false && (
          <div className={classes.viewsContainer}>
            <Text
              className={stSharedText(sharedTextClasses._textColor, {
                isMobile: false,
              })}
            >
              {0}
            </Text>
          </div>
        )}
        {displaySettings.favorites && isMobile === false && (
          <div className={classes.favoritesContainer}>
            <IconToggle
              className={stIconToggle(iconToggleClasses.IconToggleStyles, {
                isMobile: false,
              })}
              icon={<Favorite />}
              label={displaySettings.favoritesCounter ? '0' : ''}
            />
          </div>
        )}
        {displaySettings.contributors && isMobile === false && (
          <div className={classes.contributorsContainer}>
            <div className={classes.contributorsText}>
              <Text
                className={stSharedText(sharedTextClasses._textColor, {
                  isMobile: false,
                })}
              />
            </div>

            <Avatar size={AvatarSize.small} />
          </div>
        )}
        {!isMobile && (
          <div className={classes.moreActionsContainer}>
            <IconButton className={iconClasses.iconButton} icon={<More />} />
          </div>
        )}
      </div>
      {isMobile && (
        <Divider
          className={stSharedDivider(
            sharedDividerClasses.dividerStyle,
            {
              isEditorX,
              isMobile,
            },
            sharedDividerClasses[`dividerStyle${dividerWidth}`],
          )}
        />
      )}
    </>
  );
};

export default SkeletonItem;
