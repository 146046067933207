import { BehaviorSubject, map, lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";
import { selectedFolderSubject$ } from "./SelectedFolder.observable";

export enum AddFolderStatus {
  CLOSED,
  OPENED,
  IN_PROGRESS,
}
export const addFolderStatus$ = new BehaviorSubject(AddFolderStatus.CLOSED);

export const getAddFolderStatus$ = () => addFolderStatus$;
export const setAddFolderStatus$ = (status: AddFolderStatus) =>
  addFolderStatus$.next(status);

export const addFolder$ = async (newFolder: any) => {
  const tempResult = selectedFolderSubject$.asObservable();
  const lastTempResult = tempResult.pipe(take(1));
  const previousResult = await lastValueFrom(lastTempResult);
  const newResult = { ...previousResult };
  newResult.libraryItems = [newFolder, ...newResult.libraryItems];
  selectedFolderSubject$.next(newResult);
};
