import React from 'react';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import {
  Availability,
  BadgeType,
} from '../../../../../../../Constants/Permissions';
import HiddenFill from '../../../../../../../../../assets/icons/HiddenFill.svg';
import PlanFill from '../../../../../../../../../assets/icons/PlanFill.svg';
import LockFill from '../../../../../../../../../assets/icons/LockFill.svg';
import { BadgeWithTooltip } from './BadgeWithTooltip';
import { AccessBadgeType } from './ItemAvailabilityTypes';
import { classes } from './ItemAvailabilityBadges.st.css';

const getBadgesData = (t: TFunction) => ({
  [BadgeType.PRIVATE]: {
    label: t('widget.badge.private'),
    tooltip: t('widget.badge.private.tooltip'),
    prefixIcon: <LockFill />,
  },
  [BadgeType.PRICING_PLAN]: {
    label: t('widget.badge.pricingPlan'),
    tooltip: t('widget.badge.pricingPlan.tooltip'),
    prefixIcon: <PlanFill />,
  },
  [BadgeType.HIDDEN]: {
    label: t('widget.badge.hidden'),
    tooltip: t('widget.badge.hidden.tooltip'),
    prefixIcon: <HiddenFill />,
  },
});

const badgeTypeMapping = {
  [Availability.MEMBER]: [BadgeType.PRIVATE],
  [Availability.PAID]: [BadgeType.PRICING_PLAN],
  [Availability.PUBLIC_HIDDEN]: [BadgeType.HIDDEN],
  [Availability.PAID_HIDDEN]: [BadgeType.HIDDEN, BadgeType.PRICING_PLAN],
  [Availability.MEMBER_HIDDEN]: [BadgeType.HIDDEN, BadgeType.PRIVATE],
  [Availability.PUBLIC]: undefined,
  [Availability.UNKNOWN]: undefined,
};
export const ItemAvailabilityBadges = (props: AccessBadgeType) => {
  const { availability, path } = props;
  const { t } = useTranslation();
  if (path?.length === 1) {
    const BadgesData = getBadgesData(t);
    const badgeTypes = badgeTypeMapping[availability];
    if (badgeTypes) {
      return (
        <div className={classes.badgesContainer}>
          {badgeTypes.map((type) => (
            <BadgeWithTooltip key={type} {...BadgesData[type]} />
          ))}
        </div>
      );
    }
  }
  return <></>;
};
