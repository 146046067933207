export const BI_FILE_SHARE_WIDGET_APP_LOADED =
  'BI_FILE_SHARE_WIDGET_APP_LOADED';
export const BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK =
  'BI_FILE_SHARE_WIDGET_NEW_FILE_CLICK';
export const BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK =
  'BI_FILE_SHARE_WIDGET_NEW_FOLDER_CLICK';
export const BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED =
  'BI_FILE_SHARE_WIDGET_NEW_FILE_CREATED';
export const BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED =
  'BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED';
export const BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK =
  'BI_FILE_SHARE_WIDGET_FILE_MENU_CLICK';
export const BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER =
  'BI_FILE_SHARE_WIDGET_DELETE_FILE_OR_FOLDER';
export const BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK =
  'BI_FILE_SHARE_WIDGET_MOVE_FILE_CLICK';
export const BI_FILE_SHARE_WIDGET_RENAME_FILE =
  'BI_FILE_SHARE_WIDGET_RENAME_FILE';
export const BI_FILE_SHARE_WIDGET_SORT_BY = 'BI_FILE_SHARE_WIDGET_SORT_BY';
export const BI_FILE_SHARE_WIDGET_SEARCH = 'BI_FILE_SHARE_WIDGET_SEARCH';
export const BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER =
  'BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER';
export const BI_FILE_SHARE_WIDGET_FAVORITE_CLICK =
  'BI_FILE_SHARE_WIDGET_FAVORITE_CLICK';
export const BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE =
  'BI_FILE_SHARE_WIDGET_DOWNLOAD_FILE';
export const BI_FILE_SHARE_WIDGET_REPORT = 'BI_FILE_SHARE_WIDGET_REPORT';
export const BI_FILE_SHARE_WIDGET_DETAILS = 'BI_FILE_SHARE_WIDGET_DETAILS';
export const BI_FILE_SHARE_PERMISSIONS_SAVE_STEP1 =
  'BI_FILE_SHARE_PERMISSIONS_SAVE_STEP1';
export const BI_FILE_SHARE_PERMISSIONS_SAVE_STEP2 =
  'BI_FILE_SHARE_PERMISSIONS_SAVE_STEP2';
export const BI_FILE_SHARE_PERMISSIONS_SIGN_ADDED =
  'BI_FILE_SHARE_PERMISSIONS_SIGN_ADDED';
export const BI_FILE_SHARE_PERMISSIONS_SIGN_REMOVED =
  'BI_FILE_SHARE_PERMISSIONS_SIGN_REMOVED';
export const BI_FILE_SHARE_PERMISSIONS_ACCESS_WINDOW_OPENED =
  'BI_FILE_SHARE_PERMISSIONS_ACCESS_WINDOW_OPENED';
export const BI_FILE_SHARE_PERMISSIONS_GET_ACCESS_CLICK =
  'BI_FILE_SHARE_PERMISSIONS_GET_ACCESS_CLICK';
