import { FileSharingService } from '../Api/Api';
import { setToast } from './Toast.service';
import { TOAST } from '../../Constants/Toast.Constants';

export const requestAccessService = async (itemId: any, t: any) => {
  let API: any = null;
  API = new FileSharingService();
  try {
    const res = await API.requestAccess({ libraryItemId: itemId });
    if (res.data) {
      setToast({
        skin: TOAST.success,
        content: t('app.widget.requestAccess.send.success'),
      });
    }
  } catch (error) {
    setToast({
      skin: TOAST.error,
      content: t('app.widget.requestAccess.send.error'),
    });
    throw error;
  }
};
