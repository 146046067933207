export const TESTMODAL = 'TestModal';
export const MODAL_TYPES = {
  SHARE: 'SHARE',
  DELETE: 'DELETE',
  REPORT: 'REPORT',
  REPORT_RESPONSE: 'REPORT_RESPONSE',
  MANAGE_PERMISSIONS: 'MANAGE_PERMISSIONS',
  NO_PERMISSION: 'NO_PERMISSION',
  NOT_FOUND: 'NOT_FOUND'
};
export const ADMIN = 'Admin';
