import React from 'react';
import {
  ListRolesResponse,
  ListMembersResponse,
  RoleType,
  PermissionsLevelEnumPermissionsLevel,
} from '@wix/ambassador-file-sharing-v1-role/types';
import { TFunction } from '@wix/yoshi-flow-editor';
import {
  AdminsIcon,
  RoleIcon,
  PPIcon,
  SiteVisitorsIcon,
  BadgeIcon,
  SaveData,
} from '@wix/entity-advanced-permissions';
import { find, unionBy } from 'lodash';
import { GetPermissionBiStep1ParamsProps } from './advancedPermissions.types';

export const MEMBERS_PAGE_LIMIT = 20;
export const permissions = (t: TFunction) => [
  {
    id: PermissionsLevelEnumPermissionsLevel.VIEW,
    value: t('widget.permissions.option.view'),
    subtitle: t('widget.permissions.option.view.description'),
    isSelectable: true,
  },
  {
    id: PermissionsLevelEnumPermissionsLevel.CONTRIBUTE,
    value: t('widget.permissions.option.upload'),
    subtitle: t('widget.permissions.option.upload.description'),
    isSelectable: true,
  },
  {
    id: PermissionsLevelEnumPermissionsLevel.MANAGE,
    value: t('widget.permissions.option.manage'),
    subtitle: t('widget.permissions.option.manage.description'),
    isSelectable: true,
  },
  { divider: true },
  {
    id: PermissionsLevelEnumPermissionsLevel.NONE,
    value: t('widget.permissions.option.remove'),
    subtitle: '',
    isSelectable: true,
  },
];

export const siteVisitorPermissions = (t: TFunction) => [
  {
    id: PermissionsLevelEnumPermissionsLevel.VIEW,
    value: t('widget.permissions.option.view'),
    subtitle: t('widget.permissions.option.view.description'),
    isSelectable: true,
  },
  {
    divider: true,
  },
  {
    id: PermissionsLevelEnumPermissionsLevel.NONE,
    value: t('widget.permissions.option.remove'),
    subtitle: '',
    isSelectable: true,
  },
];

const getRole = (
  roleType: RoleType | undefined,
  name: string = '',
  t: TFunction,
) => {
  switch (roleType) {
    case RoleType.ADMINS:
      return t('widget.permissions.admins.title');
    case RoleType.ALL_MEMBERS:
      return t('widget.permissions.all_members.title');
    case RoleType.SITE_VISITORS:
      return t('widget.permissions.site_visitors.title');
    default:
      return name;
  }
};

const getDescription = (
  roleType: RoleType | undefined,
  visible: boolean | undefined | null,
  archived: boolean | undefined | null,
  t: TFunction,
) => {
  let description = '';
  if (roleType) {
    description = t(`widget.permissions.${roleType.toLowerCase()}.description`);
    if (RoleType.PLAN === roleType) {
      if (archived) {
        description = t('widget.permissions.archived_plan.description');
      } else if (!visible) {
        description = t('widget.permissions.hidden_plan.description');
      }
    }
  }
  return description ? description : '';
};

export const getMappedRoles = (roles: ListRolesResponse, t: TFunction) => {
  return roles?.roles
    ?.filter(({ roleType }) => roleType !== RoleType.ADMINS)
    .map(({ id, name, roleType, imgUrl, level, visible, archived }) => ({
      id: id ? id : '',
      role: getRole(roleType, name, t),
      description: getDescription(roleType, visible, archived, t),
      roleType,
      icon: imgUrl || getIcon(roleType),
      selectedPermission: level ? level : '',
      options:
        roleType === RoleType.SITE_VISITORS
          ? siteVisitorPermissions(t)
          : permissions(t),
      isDisabled: false,
    }));
};

export const getMappedMembers = (
  members: ListMembersResponse,
  t: TFunction,
) => {
  return members?.members?.map(
    ({ id, name, roleType, imgUrl, visible, archived }) => ({
      id,
      role: getRole(roleType, name, t),
      description: getDescription(roleType, visible, archived, t),
      icon: imgUrl || getIcon(roleType),
      showOnlyInSelectList: roleType === RoleType.SITE_VISITORS,
      roleType,
    }),
  );
};

const getIcon = (roleType: RoleType | undefined) => {
  switch (roleType) {
    case RoleType.SITE_VISITORS:
      return <SiteVisitorsIcon />;
    case RoleType.ADMINS:
      return <AdminsIcon />;
    case RoleType.ALL_MEMBERS:
    case RoleType.CUSTOM:
      return <RoleIcon />;
    case RoleType.MEMBER:
      return 'MEMBER';
    case RoleType.BADGE:
      return <BadgeIcon />;
    case RoleType.PLAN:
      return <PPIcon />;
    default:
      console.log('roleType unknown', roleType);
      return <div />;
  }
};
export const getMappedSiteRoles = (
  siteRoles: ListRolesResponse,
  lcSearch: string,
  t: TFunction,
) => {
  return siteRoles?.roles
    ?.map(({ id, name, roleType, visible, archived }) => ({
      id,
      role: getRole(roleType, name, t),
      description: getDescription(roleType, visible, archived, t),
      roleType,
      icon: getIcon(roleType),
      showOnlyInSelectList: roleType === RoleType.SITE_VISITORS,
    }))
    .filter((role) => {
      return (
        role.roleType !== RoleType.ADMINS &&
        (role.role.toLowerCase().indexOf(lcSearch) > -1 ||
          (role.roleType &&
            role.roleType.toLowerCase().indexOf(lcSearch) > -1) ||
          role.description.toLowerCase().indexOf(lcSearch) > -1)
      );
    });
};

const filterByPermissionId = (
  roles: { permissionId: string }[],
  selectedPermissions: string,
) => {
  return roles.filter(
    (role) => role.permissionId && role.permissionId === selectedPermissions,
  );
};
export const getPermissionBiStep1Params = ({
  folderId,
  siteRoles,
  roles,
  rolesChanged,
  isAdmin,
  modalAlertVisible,
  isHidden,
  availability,
}: GetPermissionBiStep1ParamsProps) => {
  try {
    const mappedRoles = roles?.map((role) => ({
      ...role,
      permissionId: role.selectedPermission,
    }));
    const integratedPermissions = unionBy(rolesChanged, mappedRoles, 'id');
    const rolesForBI = integratedPermissions.filter(
      (item: { permissionId: string }) =>
        item.permissionId &&
        item.permissionId !== PermissionsLevelEnumPermissionsLevel.NONE,
    );
    const countsAccumulator = {
      siteVisitors: 0,
      allMembers: 0,
      roles_count: 0,
      plans_count: 0,
      badges_count: 0,
      specific_members_count: 0,
    };
    rolesForBI.forEach((role) => {
      const roleId = role.id;
      const roleEntity = find(siteRoles, ['id', roleId]);
      if (roleEntity === undefined) {
        countsAccumulator.specific_members_count++;
      } else {
        const roleType = roleEntity.roleType;
        switch (roleType) {
          case RoleType.CUSTOM:
          case RoleType.ADMINS:
            countsAccumulator.roles_count++;
            break;
          case RoleType.SITE_VISITORS:
            countsAccumulator.siteVisitors++;
            break;
          case RoleType.ALL_MEMBERS:
            countsAccumulator.allMembers++;
            break;
          case RoleType.BADGE:
            countsAccumulator.badges_count++;
            break;
          case RoleType.PLAN:
            countsAccumulator.plans_count++;
            break;
          default:
            countsAccumulator.specific_members_count++;
        }
      }
    });
    return {
      file_id: folderId,
      is_folder: true,
      owner: isAdmin ? 'owner' : '',
      alert_notification: modalAlertVisible,
      total_permissions_count: rolesForBI.length,
      view_count: filterByPermissionId(
        rolesForBI,
        PermissionsLevelEnumPermissionsLevel.VIEW,
      ).length,
      upload_count: filterByPermissionId(
        rolesForBI,
        PermissionsLevelEnumPermissionsLevel.CONTRIBUTE,
      ).length,
      manage_count: filterByPermissionId(
        rolesForBI,
        PermissionsLevelEnumPermissionsLevel.MANAGE,
      ).length,
      site_visitors: countsAccumulator.siteVisitors,
      all_members: countsAccumulator.allMembers,
      roles_count: countsAccumulator.roles_count,
      plans_count: countsAccumulator.plans_count,
      badges_count: countsAccumulator.badges_count,
      specific_members_count: countsAccumulator.specific_members_count,
      isHidden:
        isHidden !== undefined ? isHidden : availability?.includes('HIDDEN'),
    };
  } catch (e) {
    console.log('error in permissions biEventStep1 ', e);
    return {};
  }
};

const getPermissionType = (rolesChanged: { permissionId: string }[]) => {
  return rolesChanged[0].permissionId ===
    PermissionsLevelEnumPermissionsLevel.CONTRIBUTE
    ? 'upload'
    : `${rolesChanged[0].permissionId}`.toLowerCase();
};
export const getPermissionBiStep2Params = (
  savedData: SaveData,
  folderId: string,
) => {
  try {
    const { rolesChanged, notify, notifyMessage } = savedData;
    const filteredRolesChanged = rolesChanged.filter(
      (item) =>
        item.permissionId &&
        item.permissionId !== PermissionsLevelEnumPermissionsLevel.NONE,
    );
    if (!filteredRolesChanged.length) {
      return '';
    }
    return {
      file_id: folderId,
      is_folder: true,
      owner: 'owner',
      notification: notify
        ? notifyMessage
          ? 'notify_with_massage'
          : 'notify_without_massage'
        : 'didnt_notify',
      permission_type: getPermissionType(filteredRolesChanged),
      tags_count: filteredRolesChanged.length,
    };
  } catch (e) {
    console.log('error in permissions biEventStep1 ', e);
    return {};
  }
};
