import {
  getOpenedModal$,
  setOpenedModal$,
} from '../Observables/Modals.observable';

export const setOpenedModal = (openedModal: string) => {
  setOpenedModal$(openedModal);
};

export const getOpenedModal = () => {
  return getOpenedModal$();
};
