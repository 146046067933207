import { TOAST } from '../../Constants/Toast.Constants';
import { getToast$, setToast$ } from '../Observables/Toast.observable';

export const getToast = () => {
  return getToast$();
};

export const setToast = (Toast: any) => {
  setToast$(Toast);
  if (Toast.skin === TOAST.success || Toast.skin === TOAST.error) {
    toastTimeOut();
  }
};

export const toastTimeOut = () => {
  setTimeout(() => {
    setToast({
      skin: TOAST.status,
      content: '',
    });
  }, 5000);
};
