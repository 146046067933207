import React from 'react';
import { classes, st } from './iconStyle.st.css';

export const ImgFill = (props: any) => {
  const { isMobile, fileIconsColoredByType, isMembersArea } = props;
  const className = fileIconsColoredByType
    ? ''
    : st(classes._fileIconColor, { isMembersArea });
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 23H5.5C4.672 23 4 22.328 4 21.5V2.5C4 1.672 4.672 1 5.5 1H13L15 6L20 8V21.5C20 22.328 19.328 23 18.5 23Z"
        fill="#FFCD00"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="#FFCD00"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3896 18.1924L14.4496 15.7004C14.3586 15.5844 14.2216 15.5134 14.0746 15.5074C13.9266 15.5244 13.7856 15.5614 13.6856 15.6704L12.9896 16.4314L11.3946 14.5834C11.2986 14.4734 11.1606 14.4104 11.0156 14.4104H11.0116C10.8656 14.4114 10.7266 14.4764 10.6326 14.5884L7.62059 18.1784C7.49559 18.3274 7.46859 18.5354 7.55059 18.7114C7.63259 18.8874 7.80959 19.0004 8.00359 19.0004H15.9946C16.1866 19.0004 16.3606 18.8914 16.4446 18.7194C16.5286 18.5474 16.5066 18.3434 16.3896 18.1924ZM15 14C15.552 14 16 13.553 16 13C16 12.447 15.552 12 15 12C14.447 12 14 12.447 14 13C14 13.553 14.447 14 15 14Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.639 10.2372C27.234 10.0852 26.914 9.76525 26.762 9.36125L24.001 2.00025H6.501C5.672 1.99925 5 2.67125 5 3.50025V40.5002C5 41.3283 5.672 42.0002 6.5 42.0002H33.5C34.329 42.0002 35 41.3282 35 40.4992V12.9982L27.639 10.2372Z"
        fill="#FFCD00"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="#FFCD00"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4691 32H27.4681H12.5331L18.2901 25.14L21.8661 29.284C21.9621 29.395 22.1001 29.458 22.2451 29.458C22.3911 29.458 22.5291 29.395 22.6241 29.285L24.0671 27.612L27.4681 32L27.8641 31.693L27.4691 32ZM28.2591 31.387L24.8571 26.999C24.6721 26.762 24.3941 26.62 24.0931 26.613C23.7801 26.562 23.5061 26.731 23.3101 26.959L22.2451 28.192L19.0471 24.486C18.8571 24.266 18.5811 24.14 18.2901 24.14C18.2881 24.14 18.2851 24.14 18.2831 24.141C17.9901 24.143 17.7141 24.271 17.5251 24.497L11.7681 31.357C11.5151 31.658 11.4621 32.066 11.6281 32.423C11.7941 32.779 12.1411 33 12.5331 33H27.4691C27.8531 33 28.1981 32.785 28.3661 32.44C28.5351 32.095 28.4941 31.691 28.2591 31.387ZM24.5003 24C25.3283 24 26.0003 23.328 26.0003 22.5C26.0003 21.671 25.3283 21 24.5003 21C23.6723 21 23.0003 21.671 23.0003 22.5C23.0003 23.328 23.6723 24 24.5003 24Z"
        fill="white"
      />
    </svg>
  );
};
