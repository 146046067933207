import React from 'react';
import { classes, st } from './iconStyle.st.css';

export const VidFill = (props: any) => {
  const { isMobile, fileIconsColoredByType, isMembersArea } = props;
  const className = fileIconsColoredByType
    ? ''
    : st(classes._fileIconColor, { isMembersArea });
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 23H5.5C4.672 23 4 22.328 4 21.5V2.5C4 1.672 4.672 1 5.5 1H13L15 6L20 8V21.5C20 22.328 19.328 23 18.5 23Z"
        fill="#6544F9"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="#6544F9"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7646 14.5762L10.7646 12.0762C10.7606 12.0742 10.7546 12.0752 10.7506 12.0722C10.6706 12.0252 10.5856 12.0012 10.5026 12.0002H10.5006C10.4226 12.0002 10.3496 12.0232 10.2816 12.0572C10.2616 12.0672 10.2456 12.0802 10.2276 12.0922C10.1796 12.1242 10.1396 12.1622 10.1046 12.2092C10.0906 12.2282 10.0776 12.2442 10.0656 12.2642C10.0276 12.3342 9.99957 12.4122 9.99957 12.5012V17.4992C9.99957 17.5882 10.0276 17.6662 10.0656 17.7352C10.0776 17.7562 10.0906 17.7712 10.1046 17.7912C10.1396 17.8382 10.1796 17.8762 10.2276 17.9082C10.2456 17.9202 10.2616 17.9322 10.2816 17.9422C10.3496 17.9762 10.4226 18.0002 10.5006 18.0002H10.5026C10.5806 17.9992 10.6606 17.9722 10.7366 17.9312C10.7456 17.9272 10.7556 17.9292 10.7646 17.9242L14.7646 15.4242C14.8026 15.4002 14.8216 15.3632 14.8506 15.3342C15.0596 15.1122 15.0396 14.7482 14.7646 14.5762Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.639 10.2372C27.234 10.0852 26.914 9.76525 26.762 9.36125L24.001 2.00025H6.501C5.672 1.99925 5 2.67125 5 3.50025V40.5002C5 41.3283 5.672 42.0002 6.5 42.0002H33.5C34.329 42.0002 35 41.3282 35 40.4992V12.9982L27.639 10.2372Z"
        fill="#6544F9"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2V11.499C24.0003 12.327 24.6723 12.999 25.5003 12.999L35.0003 13L24.0003 2Z"
        fill="#6544F9"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0023 21C16.4813 21 16.0003 21.416 16.0003 22.001V31.999C16.0003 32.584 16.4813 33 17.0023 33C17.1803 33 17.3613 32.952 17.5303 32.847L25.5303 27.848C26.1563 27.457 26.1563 26.543 25.5303 26.152L17.5303 21.154C17.3613 21.048 17.1803 21 17.0023 21ZM17.0043 22.004L25.0003 27L17.0003 31.999L17.0043 22.004Z"
        fill="white"
      />
    </svg>
  );
};
