import {
  MemberTab,
  getCurrentTab$,
  setCurrentTab$,
} from '../Observables/MemberTab.observable';

export const setCurrentTab = (value: MemberTab) => {
  setCurrentTab$(value);
};

export const getCurrentTab = () => {
  return getCurrentTab$();
};
