import React from 'react';
import { st, classes } from './iconStyle.st.css';

export const Favorite = (props: any) => {
  return (
    <svg
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props?.handleOnChange && props.handleOnChange();
        }
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.89994 18.5002C7.69994 18.5002 7.49994 18.4002 7.39994 18.3002C7.09994 18.1002 6.99994 17.8002 6.99994 17.4002L7.69994 13.6002L4.89994 10.8002C4.59994 10.6002 4.59994 10.2002 4.69994 9.9002C4.79994 9.60019 5.09994 9.3002 5.39994 9.3002L9.39994 8.7002L11.0999 5.2002C11.2999 4.9002 11.5999 4.7002 11.8999 4.7002C12.1999 4.7002 12.5999 4.9002 12.6999 5.2002L14.4999 8.8002L18.3999 9.3002C18.6999 9.3002 18.9999 9.60019 19.0999 9.9002C19.1999 10.2002 19.0999 10.6002 18.8999 10.8002L15.9999 13.6002L16.6999 17.5002C16.7999 17.8002 16.5999 18.2002 16.2999 18.4002C15.9999 18.6002 15.6999 18.6002 15.3999 18.5002L11.7999 16.6002L8.29994 18.5002C8.19994 18.5002 8.09994 18.5002 7.89994 18.5002ZM11.8999 15.6002C11.9999 15.6002 12.1999 15.6002 12.2999 15.7002L15.6999 17.5002L14.9999 13.7002C14.9999 13.4002 14.9999 13.1002 15.2999 12.9002L17.9999 10.2002L14.1999 9.7002C13.8999 9.7002 13.6999 9.5002 13.4999 9.2002L11.8999 5.8002L10.1999 9.2002C10.0999 9.5002 9.79994 9.6002 9.49994 9.7002L5.69994 10.2002L8.39994 12.9002C8.59994 13.1002 8.69994 13.4002 8.69994 13.7002L8.09994 17.5002L11.4999 15.7002C11.5999 15.6002 11.6999 15.6002 11.8999 15.6002Z"
        fill="black"
        className={st(classes.favoriteIcon, {
          isMembersArea: props.isMembersArea,
        })}
      />
    </svg>
  );
};
