import { BehaviorSubject } from 'rxjs';

export const isServerError$ = new BehaviorSubject({
  isError: false,
  message: '',
});

export const getIsServerError$ = () => isServerError$;

export const setIsServerError$ = (error: any) => isServerError$.next(error);
