export const DELETE_TYPE = {
  SINGLE_FILE: 'SINGLE_FILE',
  MULTIPLE_FILES: 'MULTIPLE_FILES',
  SINGLE_FOLDER: 'SINGLE_FOLDER',
  MULTIPLE_FOLDERS: 'MULTIPLE_FOLDERS',
  SINGLE_FILE_MULTIPLE_FOLDERS: 'SINGLE_FILE_MULTIPLE_FOLDERS',
  SINGLE_FOLDER_MULTIPLE_FILES: 'SINGLE_FOLDER_MULTIPLE_FILES',
  SINGLE_FILE_SINGLE_FOLDER: 'SINGLE_FILE_SINGLE_FOLDER',
  MULTIPLE_FOLDERS_MULTIPLE_FILES: 'MULTIPLE_FOLDER_MULTIPLE_FILES',
};

export const DELETE_GLITCH = {
  SINGLE_FOLDER_GLITCH: 'SINGLE_FOLDER_GLITCH',
  SINGLE_FILE_GLITCH: 'SINGLE_FILE_GLITCH',
  MULTIPLE_FOLDERS_GLITCH: 'MULTIPLE_FOLDERS_GLITCH',
  MULTIPLE_FILES_GLITCH: 'MULTIPLE_FILES_GLITCH',
  ITEMS_GLITCH: 'ITEMS_GLITCH',
  MULTI_GLITCH: 'MULTI_GLITCH',
};
