import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Divider, Spinner, Text } from 'wix-ui-tpa';
import { st as loadingSt, classes } from './LoadingWrapper.st.css';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import {
  getAppSettings,
  getSettingsData,
} from '../../Services/AppSettings.service';
import { st, classes as dividerClasses } from '../SharedDivider.st.css';

const LoadingWrapper = () => {
  const { t } = useTranslation();
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const dividerWidth =
    settingsData?.settings?.designSettings?.dividerWidth ?? 1;
  const membersDividerWidth =
    settingsData?.settings?.designSettings?.memberProfileDividerWidth ?? 1;
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const isMembersArea = appSettings?.isMembersArea ?? false;
  const text = t('app.widget.spinner.text');

  return (
    <>
      {!isMobile && !isMembersArea && (
        <Divider
          className={st(
            dividerClasses.dividerStyle,
            { isEditorX, isMobile },
            dividerClasses[
              `dividerStyle${
                isMembersArea ? membersDividerWidth : dividerWidth
              }`
            ],
          )}
        />
      )}
      <div
        className={
          classes.loadingContainer +
          ' ' +
          (isMobile ? classes.mobileContainer : '')
        }
      >
        <div>
          <Spinner className={classes.spinnerWrapper} diameter={50} />
        </div>
        <div>
          <Text
            className={loadingSt(classes.loadingText, {
              isMobile,
              isMembersArea,
            })}
          >
            {' '}
            {text}
          </Text>
        </div>
      </div>
    </>
  );
};

export default LoadingWrapper;
