import { TOAST } from '../../Constants/Toast.Constants';
import { FileSharingService } from '../Api/Api';
import {
  addFolder$,
  getAddFolderStatus$,
  setAddFolderStatus$,
} from '../Observables/AddNewFolder.observable';
import { setIsLoading } from './Loading.service';
import { setIsServerError } from './ServerError.service';
import { setToast } from './Toast.service';
import biService from '../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED } from '../BiEvents/Constants/BiConstants';

export enum AddFolderStatus {
  CLOSED,
  OPENED,
  IN_PROGRESS,
}

export const getAddFolderStatus = () => {
  return getAddFolderStatus$();
};
export const setAddFolderStatus = (status: AddFolderStatus) => {
  setAddFolderStatus$(status);
};

export const addFolderService = async (
  parentSelectedFolderId: string,
  demoFolderToAdd: any,
  t: any,
) => {
  try {
    setAddFolderStatus(AddFolderStatus.IN_PROGRESS);
    let API: any = null;
    API = new FileSharingService();
    const query = {
      action: {
        name: demoFolderToAdd.name,
        parentFolderId: parentSelectedFolderId,
      },
    };
    const res = await API.createFolder(query);

    if (res?.data) {
      setToast({
        skin: TOAST.success,
        content: t('app.widget.notification.addFolder_Success'),
      });
      demoFolderToAdd.id = res.data.folderId;
      const authActions = await authorizeAddedFolder(
        res.data.folderId,
        parentSelectedFolderId,
      );
      demoFolderToAdd.authorizeActions = authActions.authorizedActions.filter(
        (data: any) => {
          return data.itemId === res.data.folderId;
        },
      );
      addFolder$(demoFolderToAdd);
      biService(
        {
          is_first: false,
          folder_name: demoFolderToAdd.name,
          is_camera: false,
          level: 1,
          origin: 'home',
          parent_folder_id: parentSelectedFolderId,
        },
        BI_FILE_SHARE_WIDGET_NEW_FOLDER_CREATED,
      );
      setAddFolderStatus(AddFolderStatus.CLOSED);
    } else {
      setToast({
        skin: TOAST.error,
        content: t('app.widget.notification.addFolder_Glitch'),
      });
      setAddFolderStatus(AddFolderStatus.CLOSED);
    }
    setIsLoading(false);
  } catch (e) {
    const serverError: any = e;
    setAddFolderStatus(AddFolderStatus.CLOSED);
    setToast({
      skin: TOAST.error,
      content: t('app.widget.notification.addFolder_Glitch'),
    });
    setIsServerError({
      isError: true,
      message: serverError.message,
    });
    setIsLoading(false);
    const error: any = e;
    return error.message;
  }
};

export const authorizeAddedFolder = async (
  folderId: string,
  parentFolderId: string,
) => {
  try {
    let API: any = null;
    API = new FileSharingService();
    const query = { libraryItemIds: [folderId], parentFolderId };
    const res = await API.authorizeActions(query);
    if (res && res.data) {
      return res.data;
    }
  } catch (e) {
    return e;
  }
};
