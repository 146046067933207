import React from 'react';
import { FileIcon } from '@wix/ambassador-file-sharing-v1-settings/types';

import { PdfFill } from '../../icons/PDF_Fill';
import { PdfTag } from '../../icons/PDF_Tag';
import { FileOutline } from '../../icons/File_Outline';
import { ImgFill } from '../../icons/IMG_Fill';
import { ImgTag } from '../../icons/IMG_Tag';
import { VidFill } from '../../icons/VID_Fill';
import { VidTag } from '../../icons/VID_Tag';
import { MiscFill } from '../../icons/MISC_Fill';
import { MiscTag } from '../../icons/MISC_Tag';
import { XlsFill } from '../../icons/XLS_Fill';
import { XlsTag } from '../../icons/XLS_Tag';
import { DocFill } from '../../icons/DOC_Fill';
import { DocTag } from '../../icons/DOC_Tag';
import { PptFill } from '../../icons/PPT_Fill';
import { PptTag } from '../../icons/PPT_Tag';
import { returnFileType } from './FileTypeHelper';
import { FILETYPE } from '../../../Constants/FileIconConstants';

export const getFileIcon = (
  fileType: string,
  fileIconStyle: FileIcon,
  isMobile: boolean,
  fileIconsColoredByType: boolean,
  isMembersArea: boolean,
) => {
  const receivedType = returnFileType(fileType);
  switch (receivedType) {
    case FILETYPE.PDF:
      if (fileIconStyle === FileIcon.CLASSIC) {
        return (
          <PdfFill
            isMobile={isMobile}
            isMembersArea={isMembersArea}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else if (fileIconStyle === FileIcon.EXTENSION) {
        return (
          <PdfTag
            isMobile={isMobile}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.IMAGE:
      if (fileIconStyle === FileIcon.CLASSIC) {
        return (
          <ImgFill
            isMobile={isMobile}
            isMembersArea={isMembersArea}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else if (fileIconStyle === FileIcon.EXTENSION) {
        return (
          <ImgTag
            isMobile={isMobile}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else {
        return <FileOutline isMobile={isMobile} />;
      }
    case FILETYPE.MEDIA:
      if (fileIconStyle === FileIcon.CLASSIC) {
        return (
          <VidFill
            isMobile={isMobile}
            isMembersArea={isMembersArea}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else if (fileIconStyle === FileIcon.EXTENSION) {
        return (
          <VidTag
            isMobile={isMobile}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else {
        return <FileOutline isMobile={isMobile} />;
      }
    case FILETYPE.ARCHIEVE:
      if (fileIconStyle === FileIcon.CLASSIC) {
        return (
          <MiscFill
            isMobile={isMobile}
            isMembersArea={isMembersArea}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else if (fileIconStyle === FileIcon.EXTENSION) {
        return (
          <MiscTag
            isMobile={isMobile}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.XLS:
      if (fileIconStyle === FileIcon.CLASSIC) {
        return (
          <XlsFill
            isMobile={isMobile}
            isMembersArea={isMembersArea}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else if (fileIconStyle === FileIcon.EXTENSION) {
        return (
          <XlsTag
            isMobile={isMobile}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.DOC:
      if (fileIconStyle === FileIcon.CLASSIC) {
        return (
          <DocFill
            isMobile={isMobile}
            isMembersArea={isMembersArea}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else if (fileIconStyle === FileIcon.EXTENSION) {
        return (
          <DocTag
            isMobile={isMobile}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    case FILETYPE.PPT:
      if (fileIconStyle === FileIcon.CLASSIC) {
        return (
          <PptFill
            isMobile={isMobile}
            isMembersArea={isMembersArea}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else if (fileIconStyle === FileIcon.EXTENSION) {
        return (
          <PptTag
            isMobile={isMobile}
            fileIconsColoredByType={fileIconsColoredByType}
          />
        );
      } else {
        return <FileOutline isMobile={isMobile} />;
      }

    default:
      return;
  }
};
