import {
  getBiEventsParams$,
  setBiEventsParams$,
} from '../Observables/BiEvents.observable';

export const getBiEventsParams = () => {
  return getBiEventsParams$();
};
export const setBiEventsParams = (biEventsParams: object) => {
  setBiEventsParams$(biEventsParams);
};
