import { fileShareWidgetDetails } from '@wix/bi-logger-wixlabs-users/v2';

export const biFileShareWidgetDetails = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    biToken,
    parent_folder_id,
    folder_name,
    display_date,
    display_favorites,
    display_favorites_counter,
    display_owner,
    display_search_bar,
    display_size,
    display_views,
    icon,
    layout_alignment,
    sort_by,
  } = props;
  logger?.report(
    fileShareWidgetDetails({
      biToken,
      app_id,
      app_site_id: instance_id,
      display_date,
      display_favorites,
      display_favorites_counter,
      display_owner,
      display_search_bar,
      display_size,
      display_views,
      parent_folder_id,
      folder_name,
      icon,
      instance_id,
      layout_alignment,
      sort_by,
    }),
  );
};
