export const UPLOAD_SUCCESS = {
  FILEUPLOAD_SINGLE_SUCCESS: 'FILEUPLOAD_SINGLE_SUCCESS',
  FILEUPLOAD_MULTIPLE_SUCCESS: 'FILEUPLOAD_MULTIPLE_SUCCESS',
};

export const UPLOAD_GLITCH = {
  FILEUPLOAD_SINGLE_GLITCH: 'FILEUPLOAD_SINGLE_GLITCH',
  FILEUPLOAD_MULTIPLE_GLITCH: 'FILEUPLOAD_MULTIPLE_GLITCH',
  FILEUPLOAD_GENERAL_GLITCH: 'FILEUPLOAD_GENERAL_GLITCH',
  FILEUPLOAD_SINGLE_EXCEED: 'FILEUPLOAD_SINGLE_EXCEED',
  FILEUPLOAD_MULTIPLE_EXCEED: 'FILEUPLOAD_MULTIPLE_EXCEED',
  FILEUPLOAD_MULTIPLE_LOT: 'FILEUPLOAD_MULTIPLE_LOT',
};

export const UPLOAD_OFFLINE = {
  FILEUPLOAD_SINGLE_OFFLINE: 'FILEUPLOAD_SINGLE_OFFLINE',
  FILEUPLOAD_MULTIPLE_OFFLINE: 'FILEUPLOAD_MULTIPLE_OFFLINE',
  FILEUPLOAD_GENERAL_OFFLINE: 'FILEUPLOAD_GENERAL_OFFLINE',
};
