import React from 'react';
import { st, classes } from './iconStyle.st.css';

export const DocFill = (props: any) => {
  const { isMobile, fileIconsColoredByType, isMembersArea } = props;
  const className = fileIconsColoredByType
    ? ''
    : st(classes._fileIconColor, { isMembersArea });
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 23C4.672 23 4 22.328 4 21.5V2.5C4 1.672 4.672 1 5.5 1H13L15 6L20 8V21.5C20 22.328 19.328 23 18.5 23H5.5Z"
        fill="#166AEA"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8H20L13 1V6.5C13 7.328 13.672 8 14.5 8Z"
        fill="#166AEA"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9996 13.5H15.9996V12.5H7.9996V13.5ZM7.9996 16H15.9996V15H7.9996V16ZM7.9996 18.5H12.9996V17.5H7.9996V18.5Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.001 2H6.501C5.672 2 5 2.671 5 3.5V40.501C5 41.329 5.672 42 6.5 42H33.5C34.329 42 35.0003 41.328 35.0003 40.5V12.998L27.639 10.237C27.234 10.085 26.914 9.766 26.762 9.361L24.001 2Z"
        fill="#166AEA"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2.00024V11.4992C24.0003 12.3272 24.6723 12.9992 25.5003 12.9992L35.0003 13.0002L24.0003 2.00024Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0003 2.00024V11.4992C24.0003 12.3272 24.6723 12.9992 25.5003 12.9992L35.0003 13.0002L24.0003 2.00024Z"
        fill="#166AEA"
        fillOpacity="0.35"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0013 22.0062H26.0013V21.0062H14.0013V22.0062ZM14.0013 27.0062H26.0013V26.0062H14.0013V27.0062ZM14.0013 32.0062H21.0013V31.0062H14.0013V32.0062Z"
        fill="white"
      />
    </svg>
  );
};
