import React, { forwardRef, useState } from 'react';
import More from 'wix-ui-icons-common/on-stage/More';
import { IconButton } from 'wix-ui-tpa';
import ItemActionsMenu from './ItemActionsMenu/ItemActionsMenu';
import { setMenuSelectedItem } from '../../../../../../Services/MenuSelectedItem';
import { classes } from './ItemActions.st.css';
import withClickOutsideListener from '../../../../../../../Shared/HOC/withClickOutsideListener';
import MoveWrapper from '../../../../../../Shared/MoveWrapper/MoveWrapper';
import { setMoveLoader } from '../../../../../../Services/ItemsMove.service';
import { setAddNewFolderMoveObservable } from '../../../../../../Services/Move/Move.service';

const ItemActions = forwardRef((props: any, ref: any) => {
  const { item, open, setOpen } = props;
  const [showMenu, setShowMenu] = useState({
    showMenu: false,
    showMove: false,
  });
  const handleOnClick = async () => {
    setOpen(!open);
    setShowMenu((prevState: any) => ({
      ...prevState,
      showMenu: !open,
      showMove: false,
    }));
    await setMenuSelectedItem(item);
  };
  const handleOpenMove = () => {
    // setMoveLoader(true);
    setShowMenu((prevState: any) => ({
      ...prevState,
      showMenu: false,
      showMove: true,
    }));
  };
  const handleOnCloseMove = () => {
    setShowMenu((prevState: any) => ({
      ...prevState,
      showMenu: false,
      showMove: false,
    }));
  };
  return (
    <div ref={ref}>
      <IconButton
        aria-label="more actions"
        type="button"
        aria-haspopup="true"
        aria-expanded={open ? open && showMenu.showMenu : false}
        className={classes.iconButton}
        icon={<More />}
        onClick={handleOnClick}
      />

      {open && (
        <div>
          {showMenu.showMenu && (
            <ItemActionsMenu
              item={item}
              setOpen={setOpen}
              handleOpenMove={handleOpenMove}
            />
          )}
          {showMenu.showMove && (
            <MoveWrapper handleOnCloseMove={handleOnCloseMove} />
          )}
        </div>
      )}
    </div>
  );
});

export default withClickOutsideListener(ItemActions);
