import { UPLOAD_GLITCH } from '../../../Constants/UploadTosts.Constants';
import { UploadGlitchErrorNotifications } from './UploadGlitchErrorNotifications';

export const checkFilesSizes = (t: any, files: any) => {
  let exceedAmount = 0;
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < files.length; i++) {
    if (files[i].size > 500000000) {
      exceedAmount++;
    }
  }
  if (exceedAmount === 1) {
    UploadGlitchErrorNotifications(
      t,
      UPLOAD_GLITCH.FILEUPLOAD_SINGLE_EXCEED,
      files.length,
      exceedAmount,
    );
  } else if (exceedAmount > 1) {
    UploadGlitchErrorNotifications(
      t,
      UPLOAD_GLITCH.FILEUPLOAD_MULTIPLE_EXCEED,
      files.length,
      exceedAmount,
    );
  }
  return exceedAmount;
};
