import {
  setBreadCrumbs$,
  addToBreadCrumbs$,
  getBreadCrumbs$,
} from '../Observables/BreadCrumbs.observable';

export const getBreadCrumbs = () => {
  return getBreadCrumbs$();
};

export const setBreadCrumbs = (breadCrumb: any) => {
  setBreadCrumbs$(breadCrumb);
};

export const addToBreadCrumbs = async (breadCrumb: any) => {
  return addToBreadCrumbs$(breadCrumb);
};
