export const uploadFile = async (
  file: any,
  response: any,
  parentFolderId: string,
) => {
  const requestParameters = response.requestParameters;
  const uploadUrl = response.url;
  const formData = new FormData();
  Object.keys(requestParameters).forEach((key) =>
    formData.append(key, requestParameters[key]),
  );
  formData.append('file', file, file.name);

  try {
    const response2 = await fetch(uploadUrl, {
      method: 'POST',
      body: formData,
    });
    const uploadResponse = await response2.text();
    const tempResponseObj = {
      parentFolderId,
      uploadResponse,
      actionId: response.actionId,
    };
    return tempResponseObj;
  } catch (e) {
    console.log(e);
    return e;
  }
};
