import { fileShareManageFilesClick } from '@wix/bi-logger-wixlabs-users/v2';
import { fileShareManageFilesClickParams } from '@wix/bi-logger-wixlabs-users/v2/types';
import { PermissionType } from '../biService.types';

export const biFileShareManageFilesClick = (
  props: PermissionType<fileShareManageFilesClickParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileShareManageFilesClick({ ...params }));
};
