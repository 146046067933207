import { fileSharePermissionsSavedStep2 } from '@wix/bi-logger-wixlabs-ugc/v2';
import { fileSharePermissionsSavedStep2Params } from '@wix/bi-logger-wixlabs-ugc/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsSaveStep2 = (
  props: PermissionType<fileSharePermissionsSavedStep2Params>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsSavedStep2(params));
};
