import React from 'react';
import { ActionsMenuLayout, ActionsMenuLayoutItemAlignment } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getSortLabels } from './helpers';
import customBuildActionsMenuLayoutItem from './customBuildActionsMenuLayoutItem';
import { getSelectedFolderId } from '../../../../../../Services/SelectedFolder.service';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { st, classes } from './ActionsMenuLayoutComponent.st.css';
import { LibraryItemSortBy } from '@wix/ambassador-file-sharing-v1-settings/types';

const ActionsMenuLayoutComponent = ({
  sortBy,
  orientation,
  isMobile,
  setIsOpen,
  isMembersArea,
}: any) => {
  const { t }: { t: any } = useTranslation();
  const sortLabels = getSortLabels(t);
  const [, selectedFolderId]: any = useObservable(getSelectedFolderId());

  return (
    <ActionsMenuLayout
      className={st(classes.sortMenuWrapper, { isMobile, isMembersArea })}
    >
      {Object.keys(sortLabels).map((key) => {
        return customBuildActionsMenuLayoutItem({
          id: key,
          content: sortLabels[key as LibraryItemSortBy],
          orientation,
          sortBy,
          selectedFolderId,
          isMobile,
          setIsOpen,
        });
      })}
    </ActionsMenuLayout>
  );
};
export default ActionsMenuLayoutComponent;
