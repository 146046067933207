import { BehaviorSubject } from 'rxjs';

export enum MemberTab {
  Uploads,
  Favorites,
}
export const currentTab$ = new BehaviorSubject(MemberTab.Uploads);

export const getCurrentTab$ = () => currentTab$;

export const setCurrentTab$ = (value: MemberTab) => currentTab$.next(value);
