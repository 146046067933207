import {
  LibraryItemSortBy,
  Orientation,
} from '@wix/ambassador-file-sharing-v1-settings/types';
import { TFunction } from '@wix/yoshi-flow-editor';

const getSortLabels: (
  t: TFunction,
) => Partial<Record<LibraryItemSortBy, string>> = (t: TFunction) => ({
  [LibraryItemSortBy.NAME]: t('app.widget.sort.name'),
  [LibraryItemSortBy.LAST_MODIFIED]: t('app.widget.sort.last_modified'),
  [LibraryItemSortBy.UNIQUE_VIEWS]: t('app.widget.sort.views_count'),
  [LibraryItemSortBy.TIMES_FAVORITED]: t('app.widget.sort.favorites'),
  [LibraryItemSortBy.TYPE]: t('app.widget.sort.type'),
  [LibraryItemSortBy.SIZE]: t('app.widget.sort.size'),
});

const changeSortDirection = ({ orientation }: any) => {
  if (orientation === Orientation.DESC) {
    return Orientation.ASC;
  } else if (orientation === Orientation.ASC) {
    return Orientation.DESC;
  }
};
const getButtonDescriptionBi = (selectedId: string) => {
  switch (selectedId) {
    case LibraryItemSortBy.NAME:
      return 'name';
    case LibraryItemSortBy.TYPE:
      return 'type';
    case LibraryItemSortBy.TIMES_FAVORITED:
      return 'stars';
    case LibraryItemSortBy.UNIQUE_VIEWS:
      return 'views';
    case LibraryItemSortBy.CREATED_AT:
      return 'date';
    default:
  }
};
export { getSortLabels, changeSortDirection, getButtonDescriptionBi };
