import { fileShareNewFileCreated } from '@wix/bi-logger-wixlabs-ugc/v2';

export const biFileShareWidgetNewFileCreated = (props: any) => {
  const {
    instance_id,
    app_id,
    logger,
    visitor_id,
    comp_id,
    is_first,
    owner,
    description,
    file_id,
    file_name,
    file_owner,
    file_size,
    file_type,
    is_camera,
    level,
    parent_folder_id,
  } = props;
  logger?.report(
    fileShareNewFileCreated({
      instance_id,
      app_id,
      visitor_id,
      comp_id,
      owner,
      is_first,
      description,
      file_id,
      file_name,
      file_owner,
      file_size,
      file_type,
      is_camera,
      level,
      parent_folder_id,
    }),
  );
};
