import { fileSharePermissionsSignRemoved } from '@wix/bi-logger-wixlabs-ugc/v2';
import { fileSharePermissionsSignRemovedParams } from '@wix/bi-logger-wixlabs-ugc/v2/types';
import { PermissionType } from '../biService.types';

export const biFileSharePermissionsSignRemoved = (
  props: PermissionType<fileSharePermissionsSignRemovedParams>,
) => {
  const { logger, ...params } = props;
  logger?.report(fileSharePermissionsSignRemoved(params));
};
