import { BehaviorSubject } from 'rxjs';
import { Loading } from '@wix/entity-advanced-permissions';
import { ListRolesResponse } from '@wix/ambassador-file-sharing-v1-role/types';

export const rolesSubject$ = new BehaviorSubject(
  undefined as unknown as ListRolesResponse,
);
export const isSiteRolesRetrievedSubject$ = new BehaviorSubject(false);

export const siteRolesSubject$ = new BehaviorSubject(
  undefined as unknown as ListRolesResponse,
);

export const isLoadingSubject$ = new BehaviorSubject(Loading.InProgress);

export const getRolesSubject$ = () => rolesSubject$;
export const setRolesSubjects$ = (roles: ListRolesResponse) =>
  rolesSubject$.next(roles);

export const getSiteRolesSubject$ = () => siteRolesSubject$;
export const setSiteRolesSubjects$ = (siteRoles: ListRolesResponse) =>
  siteRolesSubject$.next(siteRoles);

export const getIsSiteRolesRetrievedSubject$ = () =>
  isSiteRolesRetrievedSubject$;
export const setIsSiteRolesRetrievedSubject$ = (
  isSiteRolesRetrievedSubject: boolean,
) => isSiteRolesRetrievedSubject$.next(isSiteRolesRetrievedSubject);

export const getIsLoadingSubjects$ = () => isLoadingSubject$;
export const setIsLoadingSubjects$ = (loading: Loading) =>
  isLoadingSubject$.next(loading);
