import { BehaviorSubject, map, lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";

export const itemRenameStatusSubject$ = new BehaviorSubject({
  folderId: "",
  renamingStatus: false,
  parentFolderId : '',
  newName : ''
});

export const getItemRenameStatus$ = () => itemRenameStatusSubject$;
export const setItemRenameStatus$ = (itemRenameStatus: any) =>
itemRenameStatusSubject$.next(itemRenameStatus);

  export const setRenamingItem$ = async (newName : string) => {
    const tempResult = itemRenameStatusSubject$.asObservable();
      const lastTempResult = tempResult.pipe(take(1));
      const previousResult = await lastValueFrom(lastTempResult);
      const newResult = {...previousResult};
      newResult.newName = newName;
      itemRenameStatusSubject$.next(newResult);
  };
