import React from 'react';
import { classes } from './iconStyle.st.css';

export const FileOutline = (props: any) => {
  const { isMobile } = props;
  return isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 22H5.5C5.225 22 5 21.775 5 21.5V2.5C5 2.225 5.225 2 5.5 2H13V6.5C13 7.327 13.673 8 14.5 8H19V21.5C19 21.775 18.775 22 18.5 22ZM14 2.706L18.294 7H14.5C14.225 7 14 6.775 14 6.5V2.706ZM19.561 6.853L14.147 1.439C13.868 1.16 13.481 1 13.087 1H5.5C4.673 1 4 1.673 4 2.5V21.5C4 22.327 4.673 23 5.5 23H18.5C19.327 23 20 22.327 20 21.5V7.913C20 7.513 19.844 7.136 19.561 6.853Z"
        fill="black"
        className={classes._fileIconColorLine}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5 41H6.5C6.225 41 6 40.775 6 40.5V3.5C6 3.225 6.225 3 6.5 3H24V11.5C24 12.327 24.673 13 25.5 13H34V40.5C34 40.775 33.775 41 33.5 41ZM25 3.707L33.294 12H25.5C25.225 12 25 11.775 25 11.5V3.707ZM34.562 11.854L25.146 2.439C24.863 2.156 24.486 2 24.086 2H6.5C5.673 2 5 2.673 5 3.5V40.5C5 41.327 5.673 42 6.5 42H33.5C34.327 42 35 41.327 35 40.5V12.914C35 12.519 34.84 12.132 34.562 11.854Z"
        fill="black"
        className={classes._fileIconColorLine}
      />
    </svg>
  );
};
