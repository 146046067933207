import React from 'react';
import ItemLastUpdated from '../ItemLastUpdated/ItemLastUpdated';
import ItemViews from '../ItemViews/ItemViews';
import ItemFavorites from '../ItemFavorites/ItemFavorites';
import { classes } from './ItemInfoMobileView.st.css';
import {
  st,
  classes as sharedClasses,
} from '../../../../../../Shared/SharedText.st.css';
import { Text, TextPriority } from 'wix-ui-tpa';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getTableSettings } from '../../../../../../Services/TableSettings.service';
import { TableColumns } from '../../../../../../../Constants/TableHeader.Constants';

export const ItemInfoMobileView = ({ item, isMembersArea }: any) => {
  const [, tableDisplaySettings]: any = useObservable(getTableSettings());

  const getDisplaySettings = (tableColumn: string) => {
    return (
      tableDisplaySettings &&
      tableDisplaySettings.find((data: any) => data.id === tableColumn)
    );
  };

  const displaySettings = {
    displayLastUpdated:
      getDisplaySettings(TableColumns.LAST_UPDATED)?.value || false,
    displayViews:
      getDisplaySettings(TableColumns.VIEWS_COUNTER)?.value || false,
    displayFavorites:
      getDisplaySettings(TableColumns.FAVORITES)?.value || false,
  };
  return (
    <div className={classes.itemInfoMobileViewContainer}>
      <div className={classes.itemInfoMobileViewSubContainer}>
        {displaySettings.displayLastUpdated && <ItemLastUpdated item={item} />}
        {displaySettings.displayLastUpdated && displaySettings.displayViews && (
          <div className={classes.dotContainer}>
            <Text
              className={st(sharedClasses._textColor, {
                isMobile: true,
                isMembersArea,
              })}
              priority={TextPriority.secondary}
            >
              ·
            </Text>
          </div>
        )}
        <div>{displaySettings.displayViews && <ItemViews item={item} />}</div>
      </div>
      <div>
        {displaySettings.displayFavorites && <ItemFavorites item={item} />}
      </div>
    </div>
  );
};
